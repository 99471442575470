import { Helmet } from 'react-helmet';

import React, { useState, useEffect } from 'react';

import { // Components.
    Backend,
    Feed
} from '../../components/components';

import { // Functions.
    getReviewsBackend
} from '../../functions/functions';


function Reviews(props: any) {
    const [windowPathname] = useState<string>(props.windowPathname);

    // Api data.
    const [reviews, setReviews] = useState<any>(undefined);
    const [influencerMetaDataStatus, setInfluencerMetaDataStatus] = useState<boolean>(false);

    useEffect(() => {
        if (windowPathname) { 
            getReviewsBackend({ setReviews: setReviews });
        } 
    }, [windowPathname]);

    useEffect(() => { 
        if (props.influencer) {
            setInfluencerMetaDataStatus(props.influencer.meta_data && props.influencer.meta_data.status === 2 ? true : false);
        }
    }, [props.influencer]);

    
    return (
        <React.StrictMode>
            <Helmet>
                <title>
                    Fahrzeug-Bewertungen | electricmagazin.com
                </title>
                <meta
                    name="description"
                    content={"Erstellen und bearbeiten Sie Fahrzeug-Bewertungen."} />
            </Helmet>

            <Backend 
                windowPathname={windowPathname} setNotification={props.setNotification}
                influencer={props.influencer} refreshtoken={props.refreshtoken}>
                    <div>
                        <div className="mb-2">
                            <h3>Fahrzeug-Bewertungen</h3>
                            <p className="op-50">Erstellen und bearbeiten Sie Fahrzeug-Bewertungen.</p>
                        </div>

                        <a href="/dashboard/reviews/create">
                            <div className="w-100 h-5em flex flex-jc c-prime border border-dashed border-radius">
                                <svg className="w-1-5em h-1-5em mr-0-5 c-prime flex-noshrink" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.4 194.4"><path d="M97.2,0a97.2,97.2,0,1,0,97.2,97.2A97.246,97.246,0,0,0,97.2,0Zm0,16.663A80.537,80.537,0,1,1,16.663,97.2,80.575,80.575,0,0,1,97.2,16.663ZM92.534,138.724a3.718,3.718,0,0,1-3.732-3.733V104.665H59.1a3.718,3.718,0,0,1-3.733-3.733v-8.4A3.718,3.718,0,0,1,59.1,88.8H88.8V59.409a3.534,3.534,0,0,1,1.089-2.722,3.742,3.742,0,0,1,2.643-1.011h9.332a3.484,3.484,0,0,1,3.732,3.733V88.8h29.7a3.718,3.718,0,0,1,3.733,3.732v8.4a3.743,3.743,0,0,1-1.011,2.644,3.534,3.534,0,0,1-2.722,1.089H105.6v30.326a3.745,3.745,0,0,1-1.011,2.644,3.533,3.533,0,0,1-2.721,1.089Z" fill="currentColor" /></svg>
                                <h5 className="hide-sm">Neue Bewertung erstellen</h5>
                                <h5 className="hide display-sm">Neue Bewertung</h5>
                            </div>
                        </a>
                    </div>


                    <div className="mb-5 hide-lg" id="bewertung"></div>
                    <div className="hide display-lg" id="bewertung"><div className="mb-3"></div></div>


                    {reviews && reviews.offline && reviews.offline.count && reviews.offline.count > 0 ? 
                        <div>
                            <div className="mb-2">
                                <h3 className="mb-0-25">
                                    ({reviews.offline.count}) 
                                    Offline Bewertung{reviews.offline.count > 1 ? "en" : ""}
                                </h3>
                                {props.influencer && !influencerMetaDataStatus ? 
                                    <p className="c-second">
                                        Damit Sie Ihre Reviews online stellen können müssen Sie zuerst Ihre <a href="/dashboard/settings/influencer"><u className="c-second">Unternehmens-</u></a> und <a href="/dashboard/settings"><u className="c-second">Produkt Einstellungen</u></a> vervollständigen.
                                    </p>
                                    : null}
                            </div>
                            
                            <Feed // order by date created!!
                                type="review" localhost={props.localhost}
                                qs={reviews.offline.reviews ? reviews.offline.reviews : undefined} />

                            <div className="mb-5 hide-lg" id="bewertung"></div>
                            <div className="hide display-lg" id="bewertung"><div className="mb-3"></div></div>
                        </div>
                        : null}


                    {reviews && reviews.online && reviews.online.count && reviews.online.count > 0 ? 
                        <div>
                            <div className="mb-2">
                                <h3>
                                    ({reviews.online.count}) 
                                    Online Bewertung{reviews.online.count > 1 ? "en" : ""}
                                </h3>
                            </div>
                            
                            <Feed // order by date created!!
                                type="review" localhost={props.localhost}
                                qs={reviews.online.reviews ? reviews.online.reviews : undefined} />

                            <div className="mb-5 hide-lg" id="bewertung"></div>
                            <div className="hide display-lg" id="bewertung"><div className="mb-3"></div></div>
                        </div>
                        : null}
            </Backend>
        </React.StrictMode >
    );
}

export default Reviews;