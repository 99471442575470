import { Helmet } from 'react-helmet';

import React from 'react';

import { // Components.
    Wrapper 
} from '../components/components';

import { // Functions.
    countryUrl 
} from '../functions/functions';


function PrivacyPolicy(props: any) {
    // 🕸 Site wide vars 🕸
    const loc = props.loc;


    // 🤝 Partner Logic. 🤝
    const inf = props.inf;
    const influencer = props.influencer;


    return (
        <React.StrictMode>
            <Helmet>
                <title>
                    {influencer && influencer.slug ? 
                        "Datenschutzrichtlinien | electricmagazin.com/" + influencer.slug
                        : "Datenschutzrichtlinien | electricmagazin.com"}
                </title>
                <meta
                    name="description"
                    content={"Diese Datenschutzrichtlinien geben Ihnen einen Überblick über die Verarbeitung Ihrer Daten bei " + (influencer ? influencer.name : "Electric Magazin") + ". Sie gilt für alle Websites, Apps und weiteren Dienste und Leistungen, die von " + (influencer ? influencer.name : "Electric Magazin") + " angeboten werden."} />
            </Helmet>

            <Wrapper>
                <div className="mb-2 c-transparent">.</div>

                <h1 className="mb-1 center capitalize">Datenschutzrichtlinien</h1>

                <p className="mb-3">
                    Diese Datenschutzrichtlinien geben Ihnen einen Überblick über die Verarbeitung Ihrer Daten bei {influencer ? influencer.name : "Electric Magazin"}. Sie gilt für alle Websites, Apps und weiteren Dienste und Leistungen, die von {influencer ? influencer.name : "Electric Magazin"} angeboten werden.
                    <br /><br />
                    Wir verarbeiten ihre Daten unter Einhaltung aller anwendbaren Datenschutzgesetze. Ihre Daten werden nur zu den in diesen Datenschutzrichtlinien aufgelisteten Zwecken verarbeitet. Nicht in diesen Datenschutzrichtlinien aufgelistete Datenerhebungen werden dir im Rahmen der jeweiligen Erhebung erläutert.
                </p>

                <h2 className="mb-1">1. Verarbeitung von entstehenden Daten</h2>

                <p className="mb-3">
                    Falls Sie uns eine Einwilligung in die Verarbeitung ihrer personenbezogenen Daten erteilt haben, ist in erster Linie diese Einwilligung Grundlage unserer Datenverarbeitung. Welche ihrer Daten wir aufgrund ihrer Einwilligung verarbeiten, hängt vom Zweck ihrer Einwilligung ab. Sie können eine zuvor erteilte Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen.
                    <br /><br />
                    Alle Daten werden im jeweils erforderlichen Umfang zur Vertragserfüllung sowie für weitere angefragte Services von Ihnen, im Rahmen der vereinbarten Verträge (einschließlich unserer Nutzungsbedingungen, welche Sie unter <a href={countryUrl({ loc: loc, inf: inf }) + "nutzungsbedingungen"}><u>https://electricmagazin.com/{influencer ? influencer.slug + '/' : ''}{loc ? loc + '/' : ''}nutzungsbedingungen</u></a> abrufen können), verarbeitet.
                    <br /><br /><br />

                    <ul>
                        <li>
                            <h3 className="mb-1">1.1. Newsletter-Daten</h3>

                            Für den Versand unserer anmeldepflichtigen Newsletter werden wir Ihnen erst dann einen Newsletter zusenden, wenn Sie zuvor ausdrücklich eingewilligt haben, dass wir den Newsletter-Service aktivieren sollen.	 Wenn Sie sich für einen Newsletter anmelden, speichern wir automatisch Ihre E-Mail-Adresse, den Zeitpunkt der Anmeldung sowie Ihre Bestätigung zur Anmeldung. Sollten Sie später keine Newsletter mehr von uns erhalten wollen, können Sie dem Erhalt jederzeit widersprechen, ohne dass hierfür andere als die Übermittlungskosten nach den Basistarifen entstehen.
                            <br /><br />

                            <strong>Ihre Kontakt- und Newsletter-Daten verarbeiten wir aus folgenden Gründen:</strong>
                            <br /><br />

                            <ul className="list-circle mb-3">
                                <li>
                                    Zur Bereitstellung von Nachrichten, Meldungen, Newslettern und anderer Direktkommunikation.
                                </li>
                            </ul>

                            <h3 className="mb-1">1.2. Mitteilungs- und Gesprächsdaten</h3>

                            Wenn Sie mit uns in irgendeiner Art kommunizieren, erfassen wir dabei die Inhalte Ihrer Mitteilung.
                            <br /><br />
                            Falls notwendig leiten wir Ihre Mitteilung an die für Ihr Anliegen zuständige Stelle weiter. Sie haben die Möglichkeit, uns mitzuteilen, dass Ihre Daten nur von {influencer ? influencer.name : "Electric Magazin"} verwendet werden sollen. Falls Ihr Anliegen in diesem Fall bearbeitet werden kann, leiten wir die Mitteilung nicht oder ohne Ihre persönlichen Angaben an die zuständige Stelle weiter.
                            <br /><br />

                            <strong>Ihre Mitteilungs- und Gesprachsdaten verarbeiten wir aus folgenden Gründen:</strong>
                            <br /><br />

                            <ul className="list-circle mb-3">
                                <li>
                                    Zur Bereitstellung unseres Kundenservices.
                                </li>
                            </ul>

                            <h3 className="mb-1">1.3. Angaben bei Befragungen</h3>

                            Befragungen werden immer anonym also ohne Daten zu Ihrer Person verarbeitet.

                            <strong>Ihre Angaben verarbeiten wir aus folgenden Gründen:</strong>
                            <br /><br />

                            <ul className="list-circle mb-3">
                                <li>
                                    Zur Teilnahme an Befragungen und Marktforschungsstudien.
                                </li>
                            </ul>

                            <h3 className="mb-1">1.4. Geräte- und Zugriffsdaten</h3>

                            Bei der Nutzung unserer Services fallen technische Daten Ihres Gerätes an. Diese können zum einen Angaben zum Gerätetyp, zur Version des Betriebssystems, Konfigurationseinstellungen, Informationen zur Internetverbindung und zur verwendeten App oder Identifizierungs-Daten (IDs), etwa Session-IDs, Cookie-IDs, eindeutige Geräte-Kennnummern, Konto-IDs von Drittanbietern sowie weitere gängige Internettechnologien, um Ihren Webbrowser, Ihr Gerät oder eine bestimmte App-Installation wiedererkennen zu können.
                            <br /><br />
                            Auch entstehen Zugriffsdaten auf Webserver und Datenbanken von Apps und Webbrowsern. Dabei handelt es sich um standardisierte Informationen zu den angeforderten Inhalten sowie weitere Informationen zum Serverzugriff, oder Ihrem Gerät.

                            <strong>Ihre Geräte- und Zugriffsdaten verarbeiten wir aus folgenden Gründen:</strong>
                            <br /><br />

                            <ul className="list-circle mb-3">
                                <li>
                                    Zur Bereitstellung, Personalisierung und bedarfsgerechte Gestaltung unserer Services.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </p>

                <h2 className="mb-1">2. Forschung und Entwicklung</h2>

                <p className="mb-3">
                    Wir verarbeiten gesammelte Daten für wissenschaftliche Forschung in den Forschungsbereichen maschinelles Lernen, künstliche Intelligenz, Verarbeitung natürlicher Sprache und Deep Learning. Diese Forschung soll der Verbesserung und der Weiterentwicklung des bestehenden Service-Angebots dienen und umfasst unter anderem die nachfolgenden Zwecke:
                    <br /><br />

                    <ul className="list-circle">
                        <li>
                            Entwicklung von maschinellen Lernverfahren, die Schätzwerte, Prognosen und Analysen zu den Bedürfnissen und Interessen von Nutzer ermöglichen.
                        </li>
                        <li>Entwicklung von technischen Lösungen für Kundenprobleme.</li>
                        <li>Optimierung von Geschäfts- und Logistikprozessen.</li>
                        <li>Entwicklung von technischen Lösungen, mit denen wir die Personalisierung verbessern können, sowie das Betrugsrisiko senken können.</li>
                        <li>Ausbau unseres Geschäftsmodells in neue Bereiche.</li>
                        <li>Marktforschung um Nutzungsgewohnheiten, Interessen sowie Konsumverhalten und Produktwünsche unserer Nutzer in verschiedene Ziel- und Nebengruppen einzuteilen. Sowie die anschließende Vermarktung dieser Erkenntnisse an unsere Partner.</li>
                        <li>Frühzeitigen Erkennung von Trends.</li>
                        <li>Planung, Durchführung und Erfolgskontrolle von  zielgerichteter Werbung jeglicher Art.</li>
                    </ul>
                    <br /><br />

                    Dabei beachten wir selbstverständlich die anerkannten wissenschaftlichen Datenschutzstandards. Das heißt, dass wir Ihre Daten nur in zusammengefasster, anonymisierter oder pseudonymisierter Form für Forschungszwecke verarbeiten
                </p>

                <h2 className="mb-1">3. Cookies</h2>

                <p className="mb-3">
                    Wir verwenden Cookies. Das Akzeptieren von Cookies ist keine Voraussetzung zum Besuch unserer Websites, jedoch haben unsere Websites nur eingeschränkte Funktionalitäten, falls Sie Cookies nicht akzeptieren sollten. Einstellungen über das Akzeptieren oder nicht akzeptieren von Cookies können Sie in Ihrem Webbrowser treffen.
                    <br /><br />
                    Die Cookies auf unseren Seiten können von {influencer ? influencer.name : "Electric Magazin"} selbst, oder von unseren Partnern stammen. Wenn Sie nur die {influencer ? influencer.name : "Electric Magazin"} Cookies, nicht aber die Cookies unserer Partner akzeptieren wollen, können Sie entsprechende Einstellung in Ihrem Browser (z.B. „Cookies von Drittanbietern blockieren“) wählen.
                    <br /><br />
                    Für unsere Services kommen drei Kategorien von Cookies zum Einsatz:
                    <br /><br />

                    <ul className="list-circle">
                        <li>
                            <strong>Notwendige Cookies:</strong> Diese Cookies sind für die optimale Navigation und Bedienung der Website erforderlich. Die notwendigen Cookies dienen auch der Speicherung bestimmter Eingaben und Einstellungen, die Sie getätigt haben, sowie dazu, Inhalte von {influencer ? influencer.name : "Electric Magazin"} auf Ihre individuellen Interessen abzustimmen. Ohne notwendige Cookies können unsere Websites nicht oder nur eingeschränkt genutzt werden.
                        </li>
                        <li>
                            <strong>Statistik Cookies:</strong> Diese Cookies erfassen Geräte- und Zugriffsdaten, um die Benutzung unserer Websites zu analysieren, etwa welche Bereiche der Website wie genutzt werden, wie schnell Inhalte geladen werden und ob Fehler auftreten. Diese Cookies werden verwendet, um unsere Website zu verbessern. Wir verwenden dazu unter anderem Dienste wie Google Analytics der Google Inc.
                        </li>
                        <li>
                            <strong>Marketing Cookies:</strong> Diese Cookies und ähnliche Tracking-Technologien enthalten Identifikatoren und erfassen Geräte- und Zugriffsdaten, um personalisierte Werbung auf individuellen Interessen abzustimmen. Auch einige unserer Partner, erfassen auf unseren Websites Geräte- und Zugriffsdaten. So können auch auf anderen Websites und Apps von anderen Anbietern personalisierte Werbung anzeigen.
                        </li>
                    </ul>
                </p>

                <h2 className="mb-1">4. Werbung</h2>

                <p className="mb-3">
                    Um Ihnen relevante Empfehlungen und Inhalte zu präsentieren nutzen wir und unsere   Werbepartner deine Daten für personalisierte Werbung auf folgenden Werbekanälen:
                    <br /><br />

                    <ul className="list-circle">
                        <li>
                            Facebook Ads
                        </li>
                        <li>
                            Google Ads
                        </li>
                    </ul>
                    <br /><br />

                    Dabei berücksichtigen wir insbesondere aggregierte, pseudonymisierte oder anonymisierte Einkaufsdaten, Suchverläufe, Interessendaten und demographische Profildaten sowie Geräte- und Zugriffsdaten. Wenn wir Werbung über soziale Netzwerke durchführen geben wir bestimmte Merkmale wie Altersgruppe, Region und Interessen an diese in verschlüsselter Form weiter.
                </p>

                <h2 className="mb-1">5. Übertragung deiner Daten an andere</h2>

                <p className="mb-3">
                    Wir arbeiten mit einigen Parteien besonders eng zusammen. Diese Parteien dürfen Ihre Daten grundsätzlich nur unter besonderen Bedingungen in unserem Auftrag und nur in dem Umfang und für den Zeitraum wie dies für die Erbringung der jeweiligen Leistung erforderlich ist verarbeiten.
                    <br /><br /><br />

                    <ul>
                        <li>
                            <h3 className="mb-1">5.1. Technische Dienstleister</h3>

                            Wir arbeiten mit technischen Dienstleistern zusammen, damit wir Ihnen unsere Services erbringen können. Zu diesen Dienstleistern gehört unter anderem die Zoho Corporation Pvt. Ltd. und die DigitalOcean, LLC.. Sofern diese Ihre Daten außerhalb der Europäischen Union verarbeiten, stellen wir sicher, dass die betreffenden Dienstleister vertraglich oder auf andere Weise ein gleichwertiges Datenschutzniveau garantieren.
                            <br /><br /><br />

                            <h3 className="mb-1">5.2. Social Media Netzwerke</h3>

                            Im Rahmen von Werbekampagnen geben wir im Rahmen des Datenschutzrechts Daten an Anbieter von sozialen Netzwerken weiter.
                            <br /><br /><br />

                            <h3 className="mb-1">5.3. Behörden und sonstige Dritte</h3>

                            Soweit wir durch eine Behörden- oder Gerichtsentscheidung verpflichtet sind oder zur Rechts- oder Strafverfolgung geben wir Ihre Daten erforderlichenfalls an Strafverfolgungsbehörden oder sonstige Dritte weiter.
                        </li>
                    </ul>
                </p>

                <h2 className="mb-1">6. Betrugsprävention</h2>

                <p className="mb-3">
                    Alle gesammelten Daten werden verschlüsselt mit dem Codierungssystem SSL übertragen. Um uns zusätzlich vor externen Angriffen zu schützen, greifen wir auf spezielle Sicherheitstechnologien zurück. So sind unsere Systeme durch technische und organisatorische Maßnahmen gegen Verlust, Zerstörung, unerlaubten Zugriff, Veränderung oder Verbreitung von Kundendaten durch unbefugte Personen abgesichert. Trotzdem können wir keinen absoluten Schutz Ihrer persönlichen Daten garantieren.
                </p>

                <h2 className="mb-1">7. Ihre Datenschutzrechte</h2>

                <p className="mb-3">
                    Sie haben unter den jeweiligen gesetzlichen Voraussetzungen die folgenden gesetzlichen Datenschutzrechte:
                    <br /><br />

                    <ul className="list-circle">
                        <li>
                            Recht auf Auskunft
                        </li>
                        <li>
                            Recht auf Löschung
                        </li>
                        <li>
                            Recht auf Berichtigung
                        </li>
                        <li>
                            Recht auf Einschränkung der Verarbeitung
                        </li>
                        <li>
                            Recht auf Datenübertragbarkeit
                        </li>
                        <li>
                            Beschwerderecht bei einer Datenschutzaufsichtsbehörde
                        </li>
                        <li>
                            Recht auf Widerruf von Einwilligungen
                        </li>
                        <li>
                            Recht auf Widerspruch gegen bestimmte Datenverarbeitungsmaßnahmen.
                        </li>
                    </ul>
                </p>

                <h2 className="mb-1">8. Löschung von Daten</h2>

                <p className="mb-3">
                    Wir speichern Ihre personenbezogenen Daten solange, wie es für die in dieser Datenschutzerklärung genannten Zwecke notwendig ist, insbesondere zur Erfüllung unserer vertraglichen und gesetzlichen Verpflichtungen. Darüber hinaus können wir Ihre Daten noch länger speichern, wenn uns das Gesetz die weitere Speicherung gestattet.
                    <br /><br />
                    Von der Löschung kann in den gesetzlich erlaubten Fällen abgesehen werden, soweit es sich um anonyme oder pseudonymisierte Daten handelt und die Löschung die Verarbeitung für wissenschaftliche Forschungszwecke oder für statistische Zwecke unmöglich macht oder ernsthaft beeinträchtigen würde.
                </p>

                <h2 className="mb-1">9. Änderungen dieser Datenschutzrichtlinien</h2>

                <p className="mb-5">
                    Im Zuge der Weiterentwicklung unserer Websites und Apps und der Implementierung neuer Technologien, um unseren Service für Sie zu verbessern, können Änderungen dieser Datenschutzhinweise erforderlich werden.
                    <br /><br />
                    Falls Sie Fragen zum Datenschutz bei {influencer ? influencer.name : "Electric Magazin"} haben, können Sie sich jederzeit an <a className="c-primary" href={"mailto:" + (influencer ? influencer.email : "hallo@electricmagazin.com")}><u>{influencer ? influencer.email : "hallo@electricmagazin.com"}</u></a> wenden.
                </p>
            </Wrapper>
        </React.StrictMode >
    );
}

export default PrivacyPolicy;