import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import React, { useState, useEffect } from 'react';

import { // Components.
    Backend,
    Select
} from '../../components/components';

import { // Functions.
    getCountries,
    putInfluencer
} from '../../functions/functions';


function SettingsInfluencer(props: any) {
    const setNotification = props.setNotification;
    const [windowPathname] = useState<string>(props.windowPathname);

    // Api data.
    const [influencerLegalName, setInfluencerLegalName] = useState<any>(props.influencer && props.influencer.name ? props.influencer.name : '');
    const [influencerLogo, setInfluencerLogo] = useState<any>(props.influencer && props.influencer.logo ? props.influencer.logo : '');
    const [influencerLine1, setInfluencerLine1] = useState<any>(props.influencer && props.influencer.line_1 ? props.influencer.line_1 : '');
    const [influencerLine2, setInfluencerLine2] = useState<any>(props.influencer && props.influencer.line_2 ? props.influencer.line_2 : '');
    const [influencerCity, setInfluencerCity] = useState<any>(props.influencer && props.influencer.city ? props.influencer.city : '');
    const [influencerPostalCode, setInfluencerPostalCode] = useState<any>(props.influencer && props.influencer.postal_code ? props.influencer.postal_code : '');
    const [influencerCountry, setInfluencerCountry] = useState<any>(props.influencer && props.influencer.country ? props.influencer.country : '');
    const [influencerEmail, setInfluencerEmail] = useState<any>(props.influencer && props.influencer.email ? props.influencer.email : '');
    const [influencerPhone, setInfluencerPhone] = useState<any>(props.influencer && props.influencer.phone ? props.influencer.phone : '');
    const [countries, setCountries] = useState<any>(undefined);

    useEffect(() => {
        if (props.influencer) {
            if (props.influencer.name) { setInfluencerLegalName(props.influencer.name); }
            if (props.influencer.logo) { setInfluencerLogo(props.influencer.logo); }
            if (props.influencer.line_1) { setInfluencerLine1(props.influencer.line_1); }
            if (props.influencer.line_2) { setInfluencerLine2(props.influencer.line_2); }
            if (props.influencer.city) { setInfluencerCity(props.influencer.city); }
            if (props.influencer.postal_code) { setInfluencerPostalCode(props.influencer.postal_code); }
            if (props.influencer.country) { setInfluencerCountry(props.influencer.country); }
            if (props.influencer.email) { setInfluencerEmail(props.influencer.email); }
            if (props.influencer.phone) { setInfluencerPhone(props.influencer.phone); }
        }
    }, [props.influencer]);

    useEffect(() => {
        getCountries({ setCountries: setCountries });
    }, [windowPathname]);

    // Auto save.
    const [autoSaveTimer, setAutoSaveTimer] = useState<any>(undefined);

    function autoSave() {
        setAutoSaveTimer(setTimeout(function () {
            putInfluencer({ influencer: props.influencer, setInfluencer: props.setInfluencer, setNotification: setNotification });
        }, 3000));
    }

    function clearAutoSave() {
        setAutoSaveTimer(clearTimeout(autoSaveTimer));
    }


    return (
        <React.StrictMode>
            <Helmet>
                <title>
                    Einstellungen Geschäftsdetails | electricmagazin.com
                </title>
                <meta
                    name="description"
                    content={"Einstellungen Geschäftsdetails."} />
            </Helmet>

            <Backend
                windowPathname={windowPathname} setNotification={props.setNotification}
                influencer={props.influencer} refreshtoken={props.refreshtoken}>
                <div className="flex flex-wrap mb-2">
                    <a href="/dashboard/settings"><p className="mr-0-5">Einstellungen</p></a>
                    <p className="mr-0-5">{'>'}</p>
                    <h5 className="c-prime">Geschäftsdetails</h5>
                </div>

                <div className="mb-5">
                    <div className="mb-2">
                        <h3>Geschäftsdetails</h3>
                        <p className="op-50">Bearbeiten Sie alle rechlichen Informationen rund um Ihr Unternehmen.</p>
                    </div>

                    <div>
                        <input
                            id="influencerMetaDataId" type="hidden"
                            value={props.influencer && props.influencer.meta_data ? props.influencer.meta_data.id : 0} />

                        {/* Legal name. */}
                        <div className="mb-1-5">
                            <div className="mb-0-5">
                                <div className="flex mb-0-5">
                                    <h5 className="mr-0-5">Firmenname*</h5>
                                    <button className="btn-info btn-info-placeholder" id="influencerLegalNameNotify"></button>
                                </div>
                                <p className="op-50">Falls Sie keinen Firmennamen haben können Sie Ihren persönlichen-, oder Markennamen verwenden.</p>
                            </div>
                            <input
                                className="w-100" type="text"
                                id="influencerLegalName" name="influencerLegalName" placeholder="Rechtsgültiger Firmenname*"
                                value={influencerLegalName}
                                onChange={(e) => {
                                    setInfluencerLegalName(e.target.value);
                                    clearAutoSave();
                                    autoSave();
                                    setNotification("Änderungen werden gespeichert...");
                                }} />
                        </div>

                        {/* Logo. */}
                        <div>
                            <div className="flex mb-0-5">
                                <h5 className="mr-0-5">Logo</h5>
                                {!influencerLogo ?
                                    <button className="btn-info">Optional</button>
                                    : null}
                            </div>
                            <div className="flex">
                                <div className="circle bg-dark h-3em w-3em mr-0-5">
                                    {influencerLogo ?
                                        <LazyLoadImage
                                            effect="opacity"
                                            className="w-100 h-100"
                                            src={influencerLogo}
                                            alt={(influencerLegalName ? influencerLegalName + " " : "") + "Influencer Logo"} />
                                        : null}
                                </div>
                                <input id="influencerLogo" className="file hide-sm" type="file" name="influencerLogo" aria-label="File browser example" onChange={() => {
                                    clearAutoSave();
                                    autoSave();
                                    setNotification("Änderungen werden gespeichert...");
                                }} />
                            </div>
                            <div className="hide display-sm">
                                <input id="influencerLogo" className="file mt-0-5" type="file" name="influencerLogo" aria-label="File browser example" onChange={() => {
                                    clearAutoSave();
                                    autoSave();
                                    setNotification("Änderungen werden gespeichert...");
                                }} />
                            </div>
                        </div>


                        <div className="mb-5 hide-lg"></div>
                        <div className="hide display-lg"><div className="mb-3"></div></div>


                        {/* Address. */}
                        <div>
                            <div className="mb-0-5">
                                <div className="flex mb-0-5">
                                    <h5 className="mr-0-5">Geschäftsadresse*</h5>
                                    <button className="btn-info btn-info-placeholder" id="influencerAddressNotify"></button>
                                </div>
                                <p className="op-50">Falls Sie keine Geschäftsadresse haben können Sie Ihre persönliche Adresse verwenden.</p>
                            </div>
                            <input
                                className="w-100 mb-0-5" type="text"
                                id="influencerLine1" name="influencerLine1" placeholder="Straße und Hausnummer*"
                                value={influencerLine1}
                                onChange={(e) => {
                                    setInfluencerLine1(e.target.value);
                                    clearAutoSave();
                                    autoSave();
                                    setNotification("Änderungen werden gespeichert...");
                                }} />
                            <input
                                className="w-100 mb-0-5" type="text"
                                id="influencerLine2" name="influencerLine2" placeholder="Zusätzliche Angaben (optional)"
                                value={influencerLine2}
                                onChange={(e) => {
                                    setInfluencerLine2(e.target.value);
                                    clearAutoSave();
                                    autoSave();
                                    setNotification("Änderungen werden gespeichert...");
                                }} />
                            <input
                                className="w-100 mb-0-5" type="text"
                                id="influencerCity" name="influencerCity" placeholder="Stadt*"
                                value={influencerCity}
                                onChange={(e) => {
                                    setInfluencerCity(e.target.value);
                                    clearAutoSave();
                                    autoSave();
                                    setNotification("Änderungen werden gespeichert...");
                                }} />
                            <input
                                className="w-100 mb-0-5" type="text"
                                id="influencerPostalCode" name="influencerPostalCode" placeholder="Postleitzahl*"
                                value={influencerPostalCode}
                                onChange={(e) => {
                                    setInfluencerPostalCode(e.target.value);
                                    clearAutoSave();
                                    autoSave();
                                    setNotification("Änderungen werden gespeichert...");
                                }} />
                            <Select
                                className="w-12em Select-w-100 Select-sm"
                                name="influencerCountry"
                                placeholder="Land*"
                                arrow={true}
                                options={countries ? countries : undefined}
                                selected={influencerCountry ?
                                    influencerCountry.name ?
                                        (influencerCountry.emoji ? influencerCountry.emoji + ' ' : '') + influencerCountry.name
                                        : influencerCountry
                                    : undefined}
                                defaultOption={influencerCountry ?
                                    influencerCountry.id ?
                                        influencerCountry.id
                                        : influencerCountry
                                    : undefined}
                                onChange={(country: any) => {
                                    setInfluencerCountry(country);
                                    clearAutoSave();
                                    autoSave();
                                    setNotification("Änderungen werden gespeichert...");
                                }} />
                        </div>


                        <div className="mb-5 hide-lg"></div>
                        <div className="hide display-lg"><div className="mb-3"></div></div>


                        {/* Contact options. */}
                        <div>
                            <div className="mb-0-5">
                                <div className="flex mb-0-5">
                                    <h5 className="mr-0-5">Kontaktmöglichkeiten</h5>
                                    <button className="btn-info btn-info-placeholder" id="influencerSupportNotify"></button>
                                </div>
                            </div>
                            <input
                                className="w-100 mb-0-5" type="email"
                                id="influencerEmail" name="influencerEmail" placeholder="E-Mail Adresse*"
                                value={influencerEmail}
                                onChange={(e) => {
                                    setInfluencerEmail(e.target.value);
                                    clearAutoSave();
                                    autoSave();
                                    setNotification("Änderungen werden gespeichert...");
                                }} />
                            <input
                                className="w-100 mb-0-5" type="text"
                                id="influencerPhone" name="influencerPhone" placeholder="Telefonnummer (optional)"
                                value={influencerPhone}
                                onChange={(e) => {
                                    setInfluencerPhone(e.target.value);
                                    clearAutoSave();
                                    autoSave();
                                    setNotification("Änderungen werden gespeichert...");
                                }} />
                        </div>
                    </div>
                </div>
            </Backend>
        </React.StrictMode >
    );
}

export default SettingsInfluencer;