import { axiosInstance, axiosLocalErrorHandling } from '../axios';

import { // Functions.
    countryUrl,
} from '../../functions';


// Multiple vehicles.
export const getVehicles = (props: any) => {
    var filter: any = {};

    if (props.loc) {  // Country.
        filter.country = props.loc;
    }

    if (props.limit) {  // Limit.
        filter.limit = props.limit;
        filter.offset = 0;
    }

    if (props.urlParams) {  // General.
        for (const [key, value] of props.urlParams.entries()) {
            if (key !== 'offset' || key !== 'limit') {
                filter[key] = value;
            }
        }

        if (props.urlParams.has('limit')) {
            filter.limit = props.urlParams.get('limit');
        } else {
            filter.limit = 12;
        }
        if (props.urlParams.has('offset')) {
            filter.offset = props.urlParams.get('offset');
        } else {
            filter.offset = 0;
        }

    } else {
        filter.nofilter = true;
        if (props.limit) {
            filter.limit = props.limit;
        }
    }

    if (props.order) {  // Order.
        filter.order = props.order;
    }

    axiosInstance.get('vehicles/', {
        params: filter
    })
        .then(function (response: any) {
            if (response.data) {
                if (response.data.cars && props.setVehicles) {
                    props.setVehicles(response.data.cars);
                }
                if (response.data.count && props.setVehiclesCount) {
                    props.setVehiclesCount(response.data.count);
                }
                if (response.data.filter && props.setVehiclesFilters) {
                    props.setVehiclesFilters(response.data.filter);
                }
            }
        })
        .catch(function (error: any) {
            axiosLocalErrorHandling({ error: error, function: "getVehicles" });
        })
}


// Single vehicle.
export const getVehicle = (props: any) => {
    if (props.manufacturer && props.vehicle) {
        var filter: any = {};

        if (props.loc) {  // Country.
            filter.country = props.loc;
        }

        // Configurator.
        if (props.urlParams.has('auenfarbe')) {
            filter.auenfarbe = props.urlParams.get('auenfarbe');
        }
        if (props.urlParams.has('innenfarbe')) {
            filter.innenfarbe = props.urlParams.get('innenfarbe');
        }
        if (props.urlParams.has('modell-1')) {
            filter.pla1 = props.urlParams.get('modell-1');
        }
        if (props.urlParams.has('modell-2')) {
            filter.pla2 = props.urlParams.get('modell-2');
        }
        if (props.urlParams.has('modell-3')) {
            filter.pla3 = props.urlParams.get('modell-3');
        }
        if (props.urlParams.has('modell-4')) {
            filter.pla4 = props.urlParams.get('modell-4');
        }

        axiosInstance.get('vehicles/' + props.manufacturer + '/' + props.vehicle + '/', {
            params: filter
        })
            .then(function (response: any) {
                if (response.data) {
                    if (response.data && props.setVehicle) {
                        props.setVehicle(response.data);
                    }
                }
            })
            .catch(function (error: any) {
                if (error.response && error.response.status && error.response.status === 500) {
                    window.location.pathname = countryUrl({ loc: props.loc, inf: props.inf }) + "404";
                } else {
                    axiosLocalErrorHandling({ error: error, function: "getVehicle" });
                }
            })
    }
}


// Compare vehicles.
export const getVehiclesCompare = (props: any) => {
    var filter: any = {};

    if (props.loc) {  // Country.
        filter.country = props.loc;
    }

    // Selection.
    if (props.urlParams.has('hersteller-1')) {
        filter.man1 = props.urlParams.get('hersteller-1');
    }
    if (props.urlParams.has('fahrzeug-1')) {
        filter.car1 = props.urlParams.get('fahrzeug-1');
    }
    if (props.urlParams.has('plattform-1')) {
        filter.pla1 = props.urlParams.get('plattform-1');
    }
    if (props.urlParams.has('hersteller-2')) {
        filter.man2 = props.urlParams.get('hersteller-2');
    }
    if (props.urlParams.has('fahrzeug-2')) {
        filter.car2 = props.urlParams.get('fahrzeug-2');
    }
    if (props.urlParams.has('plattform-2')) {
        filter.pla2 = props.urlParams.get('plattform-2');
    }
    if (props.urlParams.has('hersteller-3')) {
        filter.man3 = props.urlParams.get('hersteller-3');
    }
    if (props.urlParams.has('fahrzeug-3')) {
        filter.car3 = props.urlParams.get('fahrzeug-3');
    }
    if (props.urlParams.has('plattform-3')) {
        filter.pla3 = props.urlParams.get('plattform-3');
    }

    if (props.urlParams) {
        for (const [key, value] of props.urlParams.entries()) {
            if (key !== 'offset' || key !== 'limit' || key !== 'plattform-3' || key !== 'plattform-2' || key !== 'plattform-1' || key !== 'fahrzeug-3' || key !== 'fahrzeug-2' || key !== 'fahrzeug-1' || key !== 'hersteller-3' || key !== 'hersteller-2' || key !== 'hersteller-1') {
                filter[key] = value;
            }
        }
    }

    axiosInstance.get('vehicles/compare/', {
        params: filter
    })
        .then(function (response: any) {
            if (response.data) {
                if (props.setVehiclesCompare) {
                    props.setVehiclesCompare(response.data);
                }
                if (props.setVehiclesCount) {
                    // if (response.data.count || response.data.count === 0) {
                    props.setVehiclesCount(response.data.count);
                    // }
                }
                if (props.setVehiclesFilters) {
                    // if (response.data.filter) {
                    props.setVehiclesFilters(response.data.filter);
                    // } else {
                    //     props.setVehiclesFilters(undefined);
                    // }
                }
            }
        })
        .catch(function (error: any) {
            axiosLocalErrorHandling({ error: error, function: "getVehiclesCompare" });
        })
}

