import { Helmet } from 'react-helmet';

import React, { useState } from 'react';

import { // Components.
    TestimonialBanner,
    Wrapper
} from '../components/components';

import { // Functions.
    countryUrl, 
    putUserPassword
} from '../functions/functions';


function SettingsFrontendChangePassword(props: any) {
    // Api data.
    const [userCurrentPassword, setUserCurrentPassword] = useState<string>('');
    const [userNewPassword, setUserNewPassword] = useState<string>('');
    const [userNewPasswordVerify, setUserNewPasswordVerify] = useState<string>('');


    return (
        <React.StrictMode>
            <Helmet>
                <title>
                    Einstellungen - Passwort ändern | electricmagazin.com
                </title>
                <meta
                    name="description"
                    content={"Hier können Sie Ihr Passwort ändern."} />
            </Helmet>

            <Wrapper>
                <div className="flex flex-wrap mt-2 mb-2">
                    <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "account/einstellungen"}><p className="mr-0-5">Einstellungen</p></a>
                    <p className="mr-0-5">{'>'}</p>
                    <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "account/persönliche-date"}><p className="mr-0-5">Persönliche Daten</p></a>
                    <p className="mr-0-5">{'>'}</p>
                    <h5 className="c-prime">Passwort ändern</h5>
                </div>

                <div>
                    <div className="mb-2">
                        <h3>Passwort ändern</h3>
                        <p className="op-50">Geben Sie ihr aktuelles Passwort ein und wählen Sie anschließen ein neues Passwort.</p>
                    </div>

                    <div>
                        {/* Current password. */}
                        <div className="mb-1-5">
                            <div className="flex mb-0-5">
                                <h5 className="mr-0-5">Aktuelles Passwort*</h5>
                                <button className="btn-info btn-info-placeholder" id="userCurrentPasswordNotify"></button>
                            </div>
                            <input 
                                className="w-100" type="password"
                                id="userCurrentPassword" name="userCurrentPassword" placeholder="Akutelles Passwort" 
                                value={userCurrentPassword} 
                                onChange={(e) => {
                                    setUserCurrentPassword(e.target.value);
                                }} />
                        </div>

                        {/* New password. */}
                        <div className="mb-1-5">
                            <div className="flex mb-0-5">
                                <h5 className="mr-0-5">Neues Passwort*</h5>
                                <button className="btn-info btn-info-placeholder" id="userNewPasswordNotify"></button>
                            </div>
                            <input 
                                className="w-100" type="password"
                                id="userNewPassword" name="userNewPassword" placeholder="Neues Passwort" 
                                value={userNewPassword} 
                                onChange={(e) => {
                                    setUserNewPassword(e.target.value);
                                }} />
                        </div>

                        {/* Verify new password. */}
                        <div className="mb-1-5">
                            <div className="flex mb-0-5">
                                <h5 className="mr-0-5">Neues Passwort bestätigen*</h5>
                                <button className="btn-info btn-info-placeholder" id="userNewPasswordVerifyNotify"></button>
                            </div>
                            <input 
                                className="w-100" type="password"
                                id="userNewPasswordVerify" name="userNewPasswordVerify" placeholder="Neues Passwort bestätigen" 
                                value={userNewPasswordVerify} 
                                onChange={(e) => {
                                    setUserNewPasswordVerify(e.target.value);
                                }} />
                        </div>

                        <div className="flex flex-jc">
                            <button 
                                onClick={() => putUserPassword({ user: props.user, setNotification: props.setNotification, loc: props.loc, inf: props.inf })}>
                                    Passwort ändern
                            </button>
                        </div>
                    </div>
                </div>


                <div className="mb-5 hide-lg" id="bewertung"></div>
                <div className="hide display-lg" id="bewertung"><div className="mb-3"></div></div>


                <div>
                    <TestimonialBanner localhost={props.localhost} loc={props.loc} inf={props.inf} user={props.user} />
                </div>


                <div className="mb-5 hide-lg" id="bewertung"></div>
                <div className="hide display-lg" id="bewertung"><div className="mb-3"></div></div>
            </Wrapper>
        </React.StrictMode>
    );
}

export default SettingsFrontendChangePassword;