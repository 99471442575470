import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import AppBackend from './AppBackend';

ReactDOM.render(
  <React.StrictMode>
    {window.location.pathname.includes('/dashboard') ? 
      <AppBackend />
      : <App />}
  </React.StrictMode>,
  document.getElementById('root')
);
