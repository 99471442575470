import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useState } from 'react';

import { // Components
    Nav
} from "../../components/components";

import { // Functions.
    authUserLogout
} from "../../functions/functions";


function Backend(props: any) {
    const [windowPathname] = useState<string>(props.windowPathname);

    return (
        <div className="Backend">
            <div className="Backend-nav" id="Backend-menu">
                <div className="Backend-nav-header">
                    <div className="flex flex-jc-e w-100">
                        <a onClick={() => {
                            const BackendMenu = document.getElementById('Backend-menu');
                            if (BackendMenu) {
                                document.body.classList.remove('no-scroll');
                                BackendMenu.classList.remove('display');
                            }
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.255 141.255"><path d="M132.869,142.033,73.312,82.477,13.746,142.043a6.48,6.48,0,1,1-9.164-9.164L64.148,73.313,4.582,13.747a6.48,6.48,0,0,1,9.165-9.165L73.313,64.148,132.889,4.571a6.48,6.48,0,0,1,9.143,9.185L82.477,73.312l59.578,59.578a6.48,6.48,0,0,1-9.185,9.142Z" transform="translate(-2.685 -2.686)" fill="currentColor" /></svg>
                        </a>
                    </div>
                </div>

                <div className="flex mb-3 hide-med">
                    <div className="circle bg-base h-3em w-3em mr-0-5">
                        {props.influencer && props.influencer.logo ?
                            <LazyLoadImage
                                effect="opacity"
                                className="w-100 h-100"
                                src={props.influencer.logo}
                                alt={(props.influencer.name ? props.influencer.name + " " : "") + "Markenlogo"} />
                            : null}
                    </div>
                    {props.influencer && props.influencer.name ?
                        <h2>{props.influencer.name}</h2>
                        : null}
                </div>
                <div className="hide display-med">
                    <div className="mb-3">
                        <div className="flex flex-jc">
                            <div className="circle bg-base h-2-5em w-2-5em">
                                {props.influencer && props.influencer.logo ?
                                    <LazyLoadImage
                                        effect="opacity"
                                        className="w-100 h-100"
                                        src={props.influencer.logo}
                                        alt={(props.influencer.name ? props.influencer.name + " " : "") + "Markenlogo"} />
                                    : null}
                            </div>
                        </div>
                        {props.influencer && props.influencer.name ?
                            <h3 className="center mt-0-25">{props.influencer.name}</h3>
                            : null}
                    </div>
                </div>
                <a href="/dashboard/reviews">
                    <div className={"mb-1" + (!windowPathname.includes('reviews') ? ' op-50' : '')}>
                        <p className="h4">Fahrzeug-Bewertungen</p>
                    </div>
                </a>
                <a href="/dashboard/testimonials">
                    <div className={"mb-1" + (!windowPathname.includes('testimonials') ? ' op-50' : '')}>
                        <p className="h4">Erfahrungsberichte</p>
                    </div>
                </a>
                <a href="/dashboard/settings">
                    <div className={"mb-1" + (!windowPathname.includes('settings') ? ' op-50' : '')}>
                        <p className="h4">Einstellungen</p>
                    </div>
                </a>
                <div className="Backend-nav-logout">
                    <div className="a">
                        <button
                            className="btn-onclick op-50"
                            onClick={() => {
                                authUserLogout({ loc: props.loc, inf: props.inf, setNotification: props.setNotification })
                            }}>
                            <p className="h4">Abmelden</p>
                        </button>
                    </div>
                </div>
            </div>
            <div className="Backend-content">
                <div className="Backend-content-header">
                    <Nav type="horizontal">
                        <li>
                            <a onClick={() => {
                                const BackendMenu = document.getElementById('Backend-menu');
                                if (BackendMenu) {
                                    document.body.classList.add('no-scroll');
                                    BackendMenu.classList.add('display');
                                }
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.4 137.224"><path d="M11.435,165.812h171.53a11.436,11.436,0,0,0,0-22.871H11.435a11.436,11.436,0,0,0,0,22.871Zm0-57.177h171.53a11.435,11.435,0,0,0,0-22.87H11.435a11.435,11.435,0,1,0,0,22.87Zm0-57.176h171.53a11.436,11.436,0,0,0,0-22.871H11.435a11.436,11.436,0,0,0,0,22.871Z" transform="translate(0 -28.588)" fill="currentColor" fill-rule="evenodd" /></svg>
                            </a>
                        </li>
                    </Nav>
                </div>
                {props.children}
                <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            </div>
        </div>
    );
}

export default Backend;