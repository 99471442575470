import React from 'react';

import { // Functions.
    scrollToId
} from './../../functions/functions';


function ActiveFilters(props: any) {
    const urlParams = props.urlParams;
    const setUrlParams = props.setUrlParams;
    const vehiclesFilters = props.vehiclesFilters;
    const setFilterPowerMin = props.setFilterPowerMin;

    // Update filter.
    function updateFilter(props: any) {
        const key = props.key
        const value = props.value

        if (key) {
            const resetUrlParamsList = ['offset', 'limit', 'hersteller-1', 'hersteller-2', 'hersteller-3', 'fahrzeug-1', 'fahrzeug-2', 'fahrzeug-3', 'plattform-1', 'plattform-2', 'plattform-3'];
            for (let i = 0; i < resetUrlParamsList.length; i++) {
                if (urlParams.has(resetUrlParamsList[i])) {
                    urlParams.delete(resetUrlParamsList[i]);
                }
            }

            if (value) { // Set, Update
                scrollToId('feed');
                urlParams.set(key, value.toString());
                setUrlParams(new URLSearchParams(urlParams.toString()));

            } else if (urlParams.has(key)) { // Delete
                scrollToId('feed');
                urlParams.delete(key);
                setUrlParams(new URLSearchParams(urlParams.toString()));
            }
        }
    }


    return (
        <div className="hide-med">
            <div className="flex flex-wrap">
                {props.filterUrlParamsLength > 0 ?
                    <div className="a mr-1 mb-0-5"><button className="btn-outline btn-sm op-25" onClick={() => {
                        const allToggel: any = document.getElementById('filterAllWeelDrive');
                        if (allToggel) {
                            allToggel.checked = false
                        }
                        const towToggel: any = document.getElementById('filterTowingHitch');
                        if (towToggel) {
                            towToggel.checked = false
                        }
                        const isoToggel: any = document.getElementById('filterISOFIX');
                        if (isoToggel) {
                            isoToggel.checked = false
                        }
                        setFilterPowerMin(0);
                        scrollToId('feed');
                        setUrlParams(new URLSearchParams());
                    }}>
                        <svg className="h-0-75em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
                        Zurücksetzen
                    </button></div>
                    : null}

                {props.filterUrlParamsKeys ?
                    props.filterUrlParamsKeys.map((param: any, index: number) =>
                        urlParams.has(param) ?
                            <React.Fragment key={index}>
                                <div className="a mr-1 mb-0-5">
                                    <button
                                        className="btn-outline btn-sm op-25"
                                        onClick={() => {
                                            updateFilter({ key: param })
                                            if (param === 'isofix') {
                                                const isoToggel: any = document.getElementById('filterISOFIX');
                                                if (isoToggel) {
                                                    isoToggel.checked = false
                                                }
                                            } else if (param === 'anhängerkupplung') {
                                                const towToggel: any = document.getElementById('filterTowingHitch');
                                                if (towToggel) {
                                                    towToggel.checked = false
                                                }
                                            } else if (param === 'allrad') {
                                                const allToggel: any = document.getElementById('filterAllWeelDrive');
                                                if (allToggel) {
                                                    allToggel.checked = false
                                                }
                                            } else if (param === 'leistung-min') {
                                                setFilterPowerMin(0);
                                            }
                                        }}>
                                        <svg className="h-0-75em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>

                                        {param === 'hersteller' ?
                                            (props.manufacturersFiltered && props.manufacturersFiltered.find((brand: any) => brand.slug === urlParams.get(param)) && props.manufacturersFiltered.find((brand: any) => brand.slug === urlParams.get(param)).name ? props.manufacturersFiltered.find((brand: any) => brand.slug === urlParams.get(param)).name : 'Marke')
                                            : ''}

                                        {param === 'karosserieformen' ?
                                            'Karosserieform' : ''}

                                        {param === 'budget-min' ?
                                            ('von € ' + parseInt(urlParams.get(param)).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').toString())
                                            : ''}

                                        {param === 'budget-max' ?
                                            ('bis € ' + parseInt(urlParams.get(param)).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').toString())
                                            : ''}

                                        {param === 'leistung-min' ?
                                            'von ' + urlParams.get('leistung-min').toString() + (urlParams.has('einheit') ? ' ' + urlParams.get('einheit') : ' kW')
                                            : ''}

                                        {param === 'allrad' ? 'Allrad' : ''}

                                        {param === 'anhängerkupplung' ? 'Anhängerkupplung' : ''}

                                        {param === 'sitze-min' ?
                                            ('ab ' + urlParams.get(param) + ' Sitz' + (parseInt(urlParams.get(param)) > 1 ? 'en' : ''))
                                            : ''}

                                        {param === 'isofix' ? 'ISOFIX' : ''}

                                        {param === 'materialien' && vehiclesFilters && vehiclesFilters.interior_materials ?
                                            vehiclesFilters.interior_materials.find((material: any) => material.slug === urlParams.get('materialien')).name : ''}

                                        {param === 'reichweite-min' ? 'ab ' + urlParams.get('reichweite-min').toString() + ' km Reichweite' : ''}

                                    </button></div>
                            </React.Fragment>
                            : null)
                    : null}
            </div>
        </div>
    );
}

export default ActiveFilters;