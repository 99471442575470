import { Helmet } from 'react-helmet';

import React, { useState, useEffect } from 'react';

import { // Components.
    Backend,
    Toggle
} from '../../components/components';

import { // Functions.
    countryUrl,
    putInfluencer
} from '../../functions/functions';


function Settings(props: any) {
    // Api data.
    const [influencerMetaDataId, setInfluencerMetaDataId] = useState<number>(0);
    const [influencerMetaDataStatus, setInfluencerMetaDataStatus] = useState<boolean>(false);
    const [influencerDataComplete, setInfluencerDataComplete] = useState<boolean>(false);
    const [influencerHref, setInfluencerHref] = useState<string>('');

    useEffect(() => {
        if (props.influencer) {
            setInfluencerMetaDataId(props.influencer.meta_data ? props.influencer.meta_data.id : 0);
            setInfluencerMetaDataStatus(props.influencer.meta_data && props.influencer.meta_data.status === 2 ? true : false);
            setInfluencerDataComplete(props.influencer.name && props.influencer.line_1 && props.influencer.city && props.influencer.postal_code && props.influencer.country && props.influencer.email ? true : false);
            setInfluencerHref(props.influencer.href ? props.influencer.href : '');
        }
    }, [props.influencer]);


    // Auto save.
    const [autoSaveTimer, setAutoSaveTimer] = useState<any>(undefined);

    function autoSave() {
        setAutoSaveTimer(setTimeout(function () {
            putInfluencer({ influencer: props.influencer, setInfluencer: props.setInfluencer, setNotification: props.setNotification });
        }, 3000));
    }

    function clearAutoSave() {
        setAutoSaveTimer(clearTimeout(autoSaveTimer));
    }


    return (
        <React.StrictMode>
            <Helmet>
                <title>
                    Einstellungen | electricmagazin.com
                </title>
                <meta
                    name="description"
                    content={"Übersicht aller verfügbaren Einstellungen."} />
            </Helmet>

            <Backend
                windowPathname={props.windowPathname} setNotification={props.setNotification}
                influencer={props.influencer} >
                <div>
                    <div className="mb-2">
                        <h3>Produkt Einstellungen</h3>
                        <p className="op-50">Bearbeiten Sie Funktionen von Electricmagazin die Sie verwenden wollen.</p>
                    </div>

                    <div>
                        <div className="flex mb-0-5">
                            <input id="influencerMetaDataId" type="hidden" value={influencerMetaDataId} />
                            {influencerDataComplete ?
                                <Toggle
                                    className="mr-1"
                                    inputId="influencerMetaDataStatus" name="influencerMetaDataStatus"
                                    defaultChecked={influencerMetaDataStatus}
                                    onChange={() => {
                                        clearAutoSave();
                                        autoSave();
                                        props.setNotification("Änderungen werden gespeichert...");
                                    }} />
                                : null}
                            {influencerDataComplete && influencerMetaDataStatus ?
                                <h4>ONLINE</h4>
                                : <h4 className="op-50">OFFLINE</h4>}
                        </div>
                        {influencerDataComplete && influencerMetaDataStatus && influencerHref ?
                            <div className="flex flex-wrap">
                                <a href={countryUrl({ loc: props.loc }) + influencerHref}>
                                    {/* target="_blank" */}
                                    <p className="op-50 hide-sm">
                                        {window.location.href.split("/")[0] + "//" + window.location.href.split("/")[2] + "/" + influencerHref}
                                    </p>
                                    <p className="op-50 hide display-sm">
                                        Influencer Website ansehen
                                    </p>
                                </a>
                                <div className="w-100 c-transparent">.</div>
                            </div>
                            : null}
                    </div>
                </div>


                <div className="mb-5 hide-lg"></div>
                <div className="hide display-lg"><div className="mb-3"></div></div>


                <div>
                    <div className="mb-2">
                        <h3>Unternehmens Einstellungen</h3>
                    </div>

                    <ul>
                        <li className="mb-0-5">
                            <div className="flex">
                                <a href="/dashboard/settings/influencer">
                                    <p className="mr-0-5">Geschäftsdetails</p>
                                </a>
                                {props.influencerFetched ?
                                    influencerDataComplete ?
                                        <button className="btn-info btn-info-prime">
                                            Ok
                                        </button>
                                        : <button className="btn-info btn-info-second">
                                            Zusätzliche Angaben notwendig
                                        </button>
                                    : null}
                            </div>
                        </li>
                        {/*<li className="mb-0-5">
                                <div className="flex">
                                    <a href="/dashboard/settings/influencer/branding">
                                        <p className="mr-0-5">Branding</p>
                                    </a>
                                </div>
                            </li>*/}
                    </ul>
                </div>


                <div className="mb-5 hide-lg"></div>
                <div className="hide display-lg"><div className="mb-3"></div></div>


                <div>
                    <div className="mb-2">
                        <h3>Persönliche Einstellungen</h3>
                    </div>

                    <ul>
                        <li className="mb-0-5">
                            <div className="flex">
                                <a href="/dashboard/settings/user">
                                    <p className="mr-0-5">Benutzereinstellungen</p>
                                </a>
                            </div>
                        </li>
                        <li className="mb-0-5">
                            <div className="flex">
                                <a href="/dashboard/settings/user/change-password">
                                    <p className="mr-0-5">Passwort ändern</p>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>


                <div className="mb-5 hide-lg"></div>
                <div className="hide display-lg"><div className="mb-3"></div></div>
            </Backend>
        </React.StrictMode >
    );
}

export default Settings;