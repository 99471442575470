import { LazyLoadComponent } from 'react-lazy-load-image-component';

import { // Components
    Wrapper
} from '../components';


function Cover(props: any) {
    return (
        <div className={"Cover" + (props.className ? " " + props.className : '')}>
            <div className={"Cover-inner" + (props.classNameInner ? " " + props.classNameInner : '')}>
                <div className="Cover-slide">
                    {props.srcSet ?
                        <LazyLoadComponent>
                            <picture className="w-100 h-100">
                                <source
                                    srcSet={props.srcSet.img_large ? props.srcSet.img_large : (props.localhost ? '' : '/static') + props.srcSet + '-large' + (props.fileType ? '.' + props.fileType : '.jpg')}
                                    media="(min-width: 1380px)" />
                                <source
                                    srcSet={props.srcSet.img_medium ? props.srcSet.img_medium : (props.localhost ? '' : '/static') + props.srcSet + '-medium' + (props.fileType ? '.' + props.fileType : '.jpg')}
                                    media="(min-width: 990px)" />
                                <source
                                    srcSet={props.srcSet.img_small ? props.srcSet.img_small : (props.localhost ? '' : '/static') + props.srcSet + '-small' + (props.fileType ? '.' + props.fileType : '.jpg')}
                                    media="(min-width: 640px)" />
                                <img
                                    className={"w-100 h-100" + (props.classNameImg ? " " + props.classNameImg : "")}
                                    srcSet={props.srcSet.img_smaller ? props.srcSet.img_smaller : (props.localhost ? '' : '/static') + props.srcSet + '-smaller' + (props.fileType ? '.' + props.fileType : '.jpg')}
                                    alt={props.alt} />
                            </picture>
                        </LazyLoadComponent>
                        : <div className="absolute"><svg className="h-2em spinner" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path></svg></div>}

                    {props.children ?
                        <div className={props.darken ? "Cover-darken" : ""}>
                            <Wrapper>
                                <div className="w-100 h-100">
                                    {props.children}
                                </div>
                            </Wrapper>
                        </div>
                        : null}

                    {props.srcSetBackground ?
                        <LazyLoadComponent>
                            <picture className="w-100 h-100 Cover-backdrop">
                                <source
                                    srcSet={(props.localhost ? '' : '/static') + props.srcSetBackground + '-large.jpg'}
                                    media="(min-width: 1380px)" />
                                <source
                                    srcSet={(props.localhost ? '' : '/static') + props.srcSetBackground + '-medium.jpg'}
                                    media="(min-width: 990px)" />
                                <source
                                    srcSet={(props.localhost ? '' : '/static') + props.srcSetBackground + '-small.jpg'}
                                    media="(min-width: 640px)" />
                                <img
                                    className="w-100 h-100"
                                    srcSet={(props.localhost ? '' : '/static') + props.srcSetBackground + '-smaller.jpg'}
                                    alt={props.altBackground ? props.altBackground : ''} />
                            </picture>
                        </LazyLoadComponent>
                        : null}
                </div>
            </div>
        </div>
    );
}

export default Cover;