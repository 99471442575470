import { axiosInstanceJWT, axiosLocalErrorHandling } from '../axios';

import { // Functions.
    countryUrl,
} from '../../functions';


export const deleteTestimonial = (props: any) => {
    if (props.testimonial) {
        axiosInstanceJWT.delete('backend/testimonials/' + props.testimonial.id + '/')
            .then(function (response: any) {
                if (props.setNotification) { props.setNotification("Erfahrungsbericht erfolgreich gelöscht!"); }
                if (window.location.pathname.includes('dashboard')) {
                    window.location.pathname = '/dashboard/testimonials';
                } else {
                    window.location.pathname = countryUrl({ loc: props.loc, inf: props.inf }) + "account/erfahrungsberichte";
                }
            })
            .catch(function (error: any) {
                if (props.setNotification) { props.setNotification("Ups, bitte versuche es nocheinmal!"); }
                axiosLocalErrorHandling({ error: error, function: "deleteTestimonial" });
            })
    }
}