import { axiosInstance, axiosLocalErrorHandling } from '../axios';


// All countires.
export const getCountries = (props: any) => {
    axiosInstance.get('countries/')
    .then(function (response: any) {
        if (response.data && props.setCountries) {
            props.setCountries(response.data);
        }
    })
    .catch(function (error: any) {
        axiosLocalErrorHandling({ error: error, function: "getCountries" });
    })
}


// Current country.
export const getCountry = (props: any) => {
    if (props.loc) {
        axiosInstance.get('countries/' + props.loc + '/')
        .then(function (response: any) {
            if (response.data && props.setCountry) {
                props.setCountry(response.data);
            }
        })
        .catch(function (error: any) {
            axiosLocalErrorHandling({ error: error, function: "getCountry" });
        })
    }
}