import React, { useState, useEffect } from 'react';

import { // Components.
    Infobox,
    Select,
    Toggle
} from './../../components/components';

import { // Functions.
    scrollToId
} from './../../functions/functions';


function Filter(props: any) {
    const urlParams = props.urlParams;
    const setUrlParams = props.setUrlParams;
    const vehiclesFilters = props.vehiclesFilters;
    const filterPowerMin = props.filterPowerMin;
    const setFilterPowerMin = props.setFilterPowerMin;

    // Update filter.
    function updateFilter(props: any) {
        const key = props.key
        const value = props.value

        if (key) {
            const resetUrlParamsList = ['offset', 'limit', 'hersteller-1', 'hersteller-2', 'hersteller-3', 'fahrzeug-1', 'fahrzeug-2', 'fahrzeug-3', 'plattform-1', 'plattform-2', 'plattform-3'];
            for (let i = 0; i < resetUrlParamsList.length; i++) {
                if (urlParams.has(resetUrlParamsList[i])) {
                    urlParams.delete(resetUrlParamsList[i]);
                }
            }

            if (value) { // Set, Update
                scrollToId('top');
                urlParams.set(key, value.toString());
                setUrlParams(new URLSearchParams(urlParams.toString()));

            } else if (urlParams.has(key)) { // Delete
                scrollToId('top');
                urlParams.delete(key);
                setUrlParams(new URLSearchParams(urlParams.toString()));
            }
        }
    }


    // Auto save "free text" filters.
    const [filterAutoSaveTimer, setFilterAutoSaveTimer] = useState<any>(undefined);

    function filterAutoSaveTimeout(props: any) {
        setFilterAutoSaveTimer(setTimeout(function () {
            updateFilter(props);
        }, 3000));
    }

    function clearFilterAutoSaveTimeout() {
        setFilterAutoSaveTimer(clearTimeout(filterAutoSaveTimer));
    }


    // Open and close filter popup.
    const [filterVisible, setFilterVisible] = useState<boolean>(false);

    useEffect(() => {
        if (filterVisible) {
            const filterOuterDiv = document.getElementById('FilterPopup');
            if (filterOuterDiv) {
                filterOuterDiv.addEventListener('click', () => {
                    setFilterVisible(false);
                    document.body.classList.remove('no-scroll');
                }, false);
            }
        }
    }, [filterVisible]);


    return (
        <div>
            <div className="flex mb-2">
                <div className="w-100">
                    {props.title ?
                        <h2 className={(props.classNameTitle ? props.classNameTitle : '') + (props.classNameOuter ? ' ' + props.classNameOuter : '')}>
                            {(props.vehiclesCount ?
                                '(' + props.vehiclesCount.toString() + ') '
                                : '') +
                                props.title}
                        </h2>
                        : null}
                </div>

                <div>
                    <div className="Filter-trigger flex">
                        {props.filterUrlParamsLength && props.filterUrlParamsLength > 0 ?
                            <h4 className={"mr-0-5" + (props.classNameOuter ? ' ' + props.classNameOuter : '')}>({props.filterUrlParamsLength})</h4>
                            : null}
                        <button className="btn-onclick" onClick={() => {
                            setFilterVisible(!filterVisible);
                            document.body.classList.add('no-scroll');
                        }}>
                            <svg className={props.classNameOuter ? props.classNameOuter : ''} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.4 164.16"><path d="M60.116,146.88a26.2,26.2,0,0,1,50.852-.265l76.974.265a6.48,6.48,0,1,1-.044,12.96l-76.946-.265A26.364,26.364,0,0,1,85.579,179.28h-.006a26.364,26.364,0,0,1-25.3-19.44H6.48a6.48,6.48,0,1,1,0-12.96H60.116Zm12.211,6.2h.006a13.3,13.3,0,0,0,13.24,13.24h0a13.249,13.249,0,1,0-13.245-13.243h-.006v0Zm50.58-65.647a26.2,26.2,0,0,1,50.783.012h14.23a6.48,6.48,0,0,1,0,12.96H173.677a26.367,26.367,0,0,1-25.378,19.71h-.006a26.362,26.362,0,0,1-25.378-19.722H6.48a6.48,6.48,0,0,1,0-12.96Zm12.146,6.483a13.3,13.3,0,0,0,13.24,13.239h.005a13.307,13.307,0,0,0,13.245-13.237,13.469,13.469,0,0,0-.165-2.092,13.246,13.246,0,0,0-26.159-.011,13.568,13.568,0,0,0-.166,2.1ZM20.738,34.842a26.2,26.2,0,0,1,50.71-.269l116.487.269a6.48,6.48,0,0,1-.03,12.96L71.58,47.533A26.363,26.363,0,0,1,46.134,67.525h-.006A26.367,26.367,0,0,1,20.746,47.8H6.48a6.48,6.48,0,1,1,0-12.96H20.738Zm12.146,6.483a13.307,13.307,0,0,0,13.244,13.24h.005a13.249,13.249,0,1,0-13.249-13.24Z" transform="translate(0 -15.12)" fill="currentColor" fill-rule="evenodd" /></svg>
                        </button>
                    </div>


                    <div className={"Filter-popup " + (filterVisible ? "Filter-popup-visible" : '')}>
                        <div id="FilterPopup" className="Filter-popup-outside">.</div>
                        <div className="Filter-popup-inner">
                            <div className="flex mb-2">
                                <div className="flex w-100">
                                    <h3>
                                        {props.filterUrlParamsLength > 0 ? '(' + props.filterUrlParamsLength + ') ' : ''}
                                        Detailsuche
                                    </h3>
                                    {props.filterUrlParamsLength > 0 ?
                                        <Infobox className="Infobox-bottom" txt="Angezeigt werden alle Optionen die mit den aktuellen Filtereinstellungen kompatibel sind!"></Infobox>
                                        : null}
                                </div>
                                <div className="a">
                                    <button className="btn-onclick Filter-popup-close op-25" onClick={() => {
                                        setFilterVisible(!filterVisible);
                                        document.body.classList.remove('no-scroll');
                                    }}>
                                        <svg className="h-1-2em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.255 141.255"><path d="M132.869,142.033,73.312,82.477,13.746,142.043a6.48,6.48,0,1,1-9.164-9.164L64.148,73.313,4.582,13.747a6.48,6.48,0,0,1,9.165-9.165L73.313,64.148,132.889,4.571a6.48,6.48,0,0,1,9.143,9.185L82.477,73.312l59.578,59.578a6.48,6.48,0,0,1-9.185,9.142Z" transform="translate(-2.685 -2.686)" fill="currentColor" /></svg>
                                    </button>
                                </div>
                            </div>


                            {props.manufacturers ?
                                <div>
                                    <div className="flex flex-ai-e mb-1">
                                        <h4 className="w-100">Hersteller</h4>
                                        {props.urlParams.has('hersteller') ?
                                            <button className="a btn-onclick hide-med" onClick={() => updateFilter({ key: 'hersteller' })}><p className="c-danger op-50 mb-0-05">zurücksetzen</p></button>
                                            : null}
                                    </div>
                                    <Select
                                        className="Select-sm Select-regular w-100"
                                        name="filterManufacturers"
                                        options={props.manufacturers}
                                        placeholder="Alle"
                                        selected={props.urlParams && props.urlParams.has('hersteller') && props.manufacturers && props.manufacturers.find((manufacturer: any) => manufacturer.slug === props.urlParams.get('hersteller')) && props.manufacturers.find((manufacturer: any) => manufacturer.slug === props.urlParams.get('hersteller')) ? props.manufacturers.find((manufacturer: any) => manufacturer.slug === props.urlParams.get('hersteller')).name : undefined}
                                        reset="Alle"
                                        onChange={(option: any) => {
                                            if (option === 'Alle') {
                                                updateFilter({ key: 'hersteller' });
                                            } else {
                                                updateFilter({ key: 'hersteller', value: option.slug });
                                            }
                                        }} />
                                </div>
                                : null}

                            {props.bodyStyles ?
                                <div className="mt-2">
                                    <div className="flex flex-ai-e mb-1">
                                        <h4 className="w-100">Karosserieformen</h4>
                                        {props.urlParams.has('karosserieformen') ?
                                            <button className="a btn-onclick hide-med" onClick={() => updateFilter({ key: 'karosserieformen' })}><p className="c-danger op-50 mb-0-05">zurücksetzen</p></button>
                                            : null}
                                    </div>
                                    <div className="Filter-popup-inner-feed">
                                        {props.bodyStyles.map((bodyStyle: any, index: number) =>
                                            <React.Fragment key={index}>
                                                <div className={"a Filter-popup-inner-feed-item" + (props.urlParams.has('karosserieformen') && props.urlParams.get('karosserieformen').includes(bodyStyle.slug) ? ' Filter-popup-inner-feed-item-active' : '')}>
                                                    <button className="btn-onclick" onClick={() => {
                                                        if (props.urlParams.has('karosserieformen') && props.urlParams.get('karosserieformen').includes(bodyStyle.slug)) {
                                                            if (props.urlParams.get('karosserieformen').split(',').length - 1 === 1) {
                                                                updateFilter({ key: 'karosserieformen' });
                                                            } else {
                                                                updateFilter({ key: 'karosserieformen', value: String(props.urlParams.get('karosserieformen').replace(bodyStyle.slug + ',', '')) });
                                                            }
                                                        } else {
                                                            updateFilter({ key: 'karosserieformen', value: String((props.urlParams.has('karosserieformen') ? props.urlParams.get('karosserieformen') : '') + bodyStyle.slug + ',') });
                                                        }
                                                    }}>
                                                        <div className="mb-0-25">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.401 194.401"><path d="M97.2,194.4A97.2,97.2,0,0,1,28.468,28.468,97.2,97.2,0,0,1,165.932,165.932,96.6,96.6,0,0,1,97.2,194.4ZM55.676,96.8a6.73,6.73,0,0,0-4.759,11.493L74.7,132.067a6.9,6.9,0,0,0,.887.778c.132.156.28.319.435.474a6.728,6.728,0,0,0,9.51,0l59.455-59.455a6.765,6.765,0,0,0,0-9.51,6.72,6.72,0,0,0-9.517,0L80.715,119.1,60.42,98.8A6.678,6.678,0,0,0,55.676,96.8Z" transform="translate(0 0)" fill="currentColor" /></svg>
                                                            <img
                                                                src={bodyStyle.thumbnail}
                                                                alt={bodyStyle.slug + " Seitenansicht Elektroauto Icon"} />
                                                        </div>
                                                        <p className="capitalize center">{bodyStyle.name}</p>
                                                    </button>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                                : null}

                            {vehiclesFilters ?
                                vehiclesFilters.budget_min || vehiclesFilters.budget_max ?
                                    <div className="mt-2">
                                        <div className="mb-1">
                                            <h4>Budget</h4>
                                        </div>
                                        <div className="flex">
                                            {vehiclesFilters.budget_min ?
                                                <div className="mr-0-5 w-100">
                                                    <p className="mb-0-5">von</p>
                                                    <Select
                                                        className="Select-sm Select-regular w-100"
                                                        name="filterPriceMin"
                                                        placeholder='Beliebig'
                                                        options={vehiclesFilters.budget_min}
                                                        selected={urlParams.has('budget-min') ? '€ ' + parseInt(urlParams.get('budget-min')).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').toString() : undefined}
                                                        reset="Beliebig"
                                                        onChange={(option: any) => {
                                                            if (option === 'Beliebig') {
                                                                updateFilter({ key: 'budget-min' });
                                                            } else {
                                                                updateFilter({ key: 'budget-min', value: option.slug });
                                                            }
                                                        }} />
                                                </div>
                                                : null}
                                            {vehiclesFilters.budget_max ?
                                                <div className="ml-0-5 w-100">
                                                    <p className="mb-0-5">bis</p>
                                                    <Select
                                                        className="Select-sm Select-regular w-100"
                                                        name="filterPriceMax"
                                                        placeholder='Beliebig'
                                                        options={vehiclesFilters.budget_max}
                                                        selected={urlParams.has('budget-max') ? '€ ' + parseInt(urlParams.get('budget-max')).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').toString() : undefined}
                                                        reset="Beliebig"
                                                        onChange={(option: any) => {
                                                            if (option === 'Beliebig') {
                                                                updateFilter({ key: 'budget-max' });
                                                            } else {
                                                                updateFilter({ key: 'budget-max', value: option.slug });
                                                            }
                                                        }} />
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                    : null
                                : null}

                            {vehiclesFilters && vehiclesFilters.wltp_min ?
                                <div className="mt-2">
                                    <div className="flex flex-ai-e mb-1">
                                        <div className="w-100 flex flex-ai-e">
                                            <h4>Reichweite (WLTP)</h4>
                                            <Infobox className="Infobox-bottom" txt="Dieses neue Testverfahren wird weltweit für realitätsnähere Ergebnisse beim Kraftstoffverbrauch verwendet."></Infobox>
                                        </div>
                                        {props.urlParams.has('reichweite-min') ?
                                            <button className="a btn-onclick hide-med" onClick={() => updateFilter({ key: 'reichweite-min' })}><p className="c-danger op-50 mb-0-05">zurücksetzen</p></button>
                                            : null}
                                    </div>
                                    <div className="flex flex-wrap">
                                        {vehiclesFilters.wltp_min.map((wltp: any, index: number) =>
                                            <React.Fragment key={index}>
                                                <div className="a">
                                                    <button
                                                        className={"btn-sm btn-outline mr-0-5 mb-0-5 " + (parseInt(urlParams.get('reichweite-min')) === wltp.slug ? 'op-100' : 'op-25')}
                                                        onClick={() => {
                                                            wltp.name === 'Beliebig' ?
                                                                updateFilter({ key: 'reichweite-min' })
                                                                : updateFilter({ key: 'reichweite-min', value: wltp.slug });
                                                        }}>
                                                        {wltp === 'Beliebig' ? wltp : wltp.name}
                                                    </button>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                                : null}

                            <div className="flex flex-ai-s mt-2">
                                <div className="w-100 mr-2">
                                    <div className="mb-1">
                                        <h4>Leistung von</h4>
                                    </div>
                                    <div className="flex">
                                        <Select
                                            className="Select-sm Select-regular mr-0-5"
                                            name="filterPowerMin"
                                            placeholder="kW"
                                            options={['kW', 'PS']}
                                            selected={urlParams.get('einheit')}
                                            onChange={(option: any) => {
                                                updateFilter({ key: 'einheit', value: option });
                                            }} />
                                        <input
                                            id="filterPowerMin"
                                            className="btn-sm w-100"
                                            type="number"
                                            placeholder={
                                                urlParams.has('einheit') && urlParams.get('einheit') === 'PS' ? 'PS' : "kW"}
                                            value={filterPowerMin && filterPowerMin > 0 ? filterPowerMin : ''}
                                            onChange={(e: any) => {
                                                setFilterPowerMin(e.target.value);
                                                clearFilterAutoSaveTimeout();
                                                filterAutoSaveTimeout({ key: 'leistung-min', value: e.target.value });
                                            }} />
                                    </div>
                                </div>

                                {vehiclesFilters && vehiclesFilters.all_wheel_drive ?
                                    <div className="w-100 hide-med">
                                        <div className="mb-1">
                                            <h4>Allrad</h4>
                                        </div>
                                        <div>
                                            <Toggle
                                                inputId="filterAllWeelDrive"
                                                name="filterAllWeelDrive"
                                                defaultChecked={urlParams.has('allrad') ? true : false}
                                                onChange={() => {
                                                    if (urlParams.has('allrad')) {
                                                        updateFilter({ key: 'allrad', });
                                                    } else {
                                                        updateFilter({ key: 'allrad', value: true });
                                                    }
                                                }} />
                                        </div>
                                    </div>
                                    : null}
                            </div>

                            {vehiclesFilters && vehiclesFilters.all_wheel_drive ?
                                <div className="w-100 hide display-sm">
                                    <div className="mt-2">
                                        <div className="mb-1">
                                            <h4>Allrad</h4>
                                        </div>
                                        <div>
                                            <Toggle
                                                inputId="filterAllWeelDrive"
                                                name="filterAllWeelDrive"
                                                defaultChecked={urlParams.has('allrad') ? true : false}
                                                onChange={() => {
                                                    if (urlParams.has('allrad')) {
                                                        updateFilter({ key: 'allrad', });
                                                    } else {
                                                        updateFilter({ key: 'allrad', value: true });
                                                    }
                                                }} />
                                        </div>
                                    </div>
                                </div>
                                : null}

                            {vehiclesFilters && vehiclesFilters.towing_hitch ?
                                <div className="w-100 mt-2">
                                    <div className="mb-1">
                                        <h4>Anhängerkupplung</h4>
                                    </div>
                                    <div>
                                        <Toggle
                                            inputId="filterTowingHitch"
                                            name="filterTowingHitch"
                                            defaultChecked={urlParams.has('anhängerkupplung') ? true : false}
                                            onChange={() => {
                                                if (urlParams.has('anhängerkupplung')) {
                                                    updateFilter({ key: 'anhängerkupplung', });
                                                } else {
                                                    updateFilter({ key: 'anhängerkupplung', value: true });
                                                }
                                            }} />
                                    </div>
                                </div>
                                : null}

                            {vehiclesFilters ?
                                vehiclesFilters.seats_min || vehiclesFilters.isofox_min ?
                                    <div className="flex flex-ai-s mt-2">
                                        {vehiclesFilters.seats_min ?
                                            <div className="w-100 mr-2">
                                                <div className="mb-1">
                                                    <h4>Sitze ab</h4>
                                                </div>
                                                <Select
                                                    className="Select-sm Select-regular w-100"
                                                    name="filterSeats"
                                                    placeholder='Beliebig'
                                                    options={vehiclesFilters.seats_min}
                                                    selected={urlParams.has('sitze-min') ? urlParams.get('sitze-min') : undefined}
                                                    reset="Beliebig"
                                                    onChange={(option: any) => {
                                                        if (option === 'Beliebig') {
                                                            updateFilter({ key: 'sitze-min' });
                                                        } else {
                                                            updateFilter({ key: 'sitze-min', value: option.slug });
                                                        }
                                                    }} />
                                            </div>
                                            : null}

                                        {vehiclesFilters.isofix ?
                                            <div className="w-100">
                                                <div className="mb-1">
                                                    <h4>ISOFIX</h4>
                                                </div>
                                                <div>
                                                    <Toggle
                                                        inputId="filterISOFIX"
                                                        name="filterISOFIX"
                                                        defaultChecked={urlParams.has('isofix') ? true : false}
                                                        onChange={() => {
                                                            if (urlParams.has('isofix')) {
                                                                updateFilter({ key: 'isofix', });
                                                            } else {
                                                                updateFilter({ key: 'isofix', value: vehiclesFilters.isofix.slug });
                                                            }
                                                        }} />
                                                </div>
                                            </div>
                                            : null}
                                    </div>
                                    : null
                                : null}

                            {vehiclesFilters && vehiclesFilters.interior_materials ?
                                <div className="mt-2">
                                    <div className="mb-1">
                                        <h4>Polster Material</h4>
                                    </div>
                                    <Select
                                        className="Select-sm Select-regular w-100"
                                        name="filterInteriorMaterial"
                                        placeholder='Alle'
                                        options={vehiclesFilters.interior_materials}
                                        selected={urlParams.has('materialien') ? vehiclesFilters.interior_materials.find((material: any) => material.slug === urlParams.get('materialien')).name : undefined}
                                        reset="Alle"
                                        onChange={(option: any) => {
                                            if (option === 'Alle') {
                                                updateFilter({ key: 'materialien' });
                                            } else {
                                                updateFilter({ key: 'materialien', value: option.slug });
                                            }
                                        }} />
                                </div>
                                : null}

                            <div className="mt-5 mb-5 c-transparent">.</div>
                            <div className="mt-5 mb-5 c-transparent">.</div>

                            <div className="Filter-nav flex">
                                <div className="a">
                                    <button
                                        className="btn btn-sm btn-outline btn-outline-danger op-50 mr-0-5"
                                        onClick={() => {
                                            const allToggel: any = document.getElementById('filterAllWeelDrive');
                                            if (allToggel) {
                                                allToggel.checked = false
                                            }
                                            const towToggel: any = document.getElementById('filterTowingHitch');
                                            if (towToggel) {
                                                towToggel.checked = false
                                            }
                                            const isoToggel: any = document.getElementById('filterISOFIX');
                                            if (isoToggel) {
                                                isoToggel.checked = false
                                            }
                                            setFilterPowerMin(0);
                                            scrollToId('top');
                                            setUrlParams(new URLSearchParams());
                                        }}>
                                        <strong className="hide-sm">zurücksetzen</strong>
                                        <svg className="hide display-sm ml-0-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170.102 194.4"><path d="M113.9,157.947h9.109a4.581,4.581,0,0,0,4.559-4.558V71.383a4.581,4.581,0,0,0-4.559-4.559H113.9a4.581,4.581,0,0,0-4.559,4.559V153.4A4.58,4.58,0,0,0,113.9,157.947ZM176.178,30.372h-31.29L131.977,8.845A18.245,18.245,0,0,0,116.336,0H78.064A18.245,18.245,0,0,0,62.431,8.845L49.512,30.372H18.222a6.1,6.1,0,0,0-6.073,6.072v6.081A6.1,6.1,0,0,0,18.222,48.6H24.3V176.174A18.313,18.313,0,0,0,42.521,194.4H151.879a18.313,18.313,0,0,0,18.226-18.226V48.6h6.073a6.1,6.1,0,0,0,6.073-6.073V36.444a6.1,6.1,0,0,0-6.073-6.072ZM77.392,19.332a2.283,2.283,0,0,1,1.956-1.106h35.721a2.283,2.283,0,0,1,1.956,1.106l6.626,11.057H70.766Zm74.478,156.85H42.521V48.606H151.879ZM71.379,157.947H80.5a4.581,4.581,0,0,0,4.559-4.558V71.383A4.581,4.581,0,0,0,80.5,66.824H71.379c-2.552,0-4.559,1.216-4.559,4.559V153.4A4.58,4.58,0,0,0,71.379,157.947Z" transform="translate(-12.149)" fill="currentColor" /></svg>
                                    </button>
                                </div>
                                <div className="a w-100">
                                    <button className="btn-sm w-100 hide display-sm" onClick={() => {
                                        scrollToId('top');
                                        setFilterVisible(!filterVisible);
                                        document.body.classList.remove('no-scroll');
                                    }}>
                                        ({props.vehiclesCount}) {props.vehiclesCount && props.vehiclesCount === 1 ? " E-Auto" : " E-Autos"}
                                    </button>
                                    <button className="btn-sm w-100 hide-sm" onClick={() => {
                                        scrollToId('top');
                                        setFilterVisible(!filterVisible);
                                        document.body.classList.remove('no-scroll');
                                    }}>
                                        ({props.vehiclesCount}) {props.vehiclesCount && props.vehiclesCount === 1 ? " passendes E-Auto" : " passende E-Autos"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Filter;