import React, { useState, useEffect } from 'react';


function Select(props: any) {
    const options = props.options;
    const [placeholder] = useState<string>(props.placeholder);
    const [name] = useState<string>(props.name);
    const [selected, setSelected] = useState<string>(props.selected);
    const onChange = props.onChange;
    const [journalistCookie] = useState<string>(props.journalistCookie);
    const [reset] = useState<string>(props.reset);

    useEffect(() => {
        setSelected(props.selected);

        // let radio: any = document.querySelector('input[name="' + name + '"]:checked');
        // if (radio) {
        //     radio.checked = false;
        // }
    }, [props.selected]);

    // Clear select after action.
    useEffect(() => {
        if (selected && props.clear) {
            setSelected('');
            const radioInput: any = document.querySelector('input[name="' + name + '"]:checked');
            if (radioInput) { radioInput.checked = false; }
        }
    }, [selected]);


    // Dropdown logic.
    const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
    const [dropdownRandomClassName] = useState<string>("Select-trigger-" + Math.random().toString(16).substr(2, 14));

    useEffect(() => {
        if (dropdownVisible) {
            document.body.addEventListener('click', (e: any) => {
                if (!e.target.classList.contains(dropdownRandomClassName)) {
                    setDropdownVisible(false);
                }
            }, false);
        }
    }, [dropdownVisible]);


    return (
        <div className={"Select " + props.className}>
            <button className={"Select-trigger " + dropdownRandomClassName + (dropdownVisible ? " Select-trigger-active " : "") + (selected ? " Select-trigger-selected " : "")} disabled={props.disabled} onClick={() => setDropdownVisible(!dropdownVisible)}>
                {selected && options ?
                    options.find((option: any) => parseInt(option.id) === parseInt(selected)) ?
                        options.find((option: any) => parseInt(option.id) === parseInt(selected)).name
                        : selected
                    : placeholder ? placeholder : "Auswählen"}
                {props.arrow ?
                    <svg className="Select-trigger-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path></svg>
                    : null}
            </button>
            {options ?
                <div className={"Select-dropdown " + (dropdownVisible ? "Select-dropdown-active" : "") + " " + props.classNameDropdown}>
                    <ul>
                        {reset ?
                            <li>
                                <label>
                                    <input
                                        type="radio"
                                        value={undefined}
                                        name={name}
                                        onChange={(e: any) => {
                                            onChange(reset);
                                            setSelected(reset);
                                            setDropdownVisible(!dropdownVisible);
                                        }} />
                                    <button>{reset}</button>
                                </label>
                            </li>
                            : null}
                        {options.map((option: any, index: number) =>
                            <React.Fragment key={index}>
                                <li>
                                    <label>
                                        <input
                                            type="radio"
                                            name={name}
                                            value={option.id ? option.id : option}
                                            onClick={(e: any) => {
                                                // (onChange === 'setCountry' ?
                                                //     window.location.assign('/' + (journalistCookie ? journalistCookie + '/' : '') + option.iso)
                                                //     : onChange(option));
                                                // if (!props.noSelected) {
                                                setSelected(option.name ? (option.emoji ? option.emoji + ' ' : '') + option.name : option);
                                                // }
                                                setDropdownVisible(!dropdownVisible);
                                                onChange(option);
                                            }}
                                            defaultChecked={
                                                selected ?
                                                    option.id && selected.toString().includes(option.id.toString()) ?
                                                        true
                                                        : option.name && selected.toString().includes(option.name.toString()) ?
                                                            true
                                                            : selected.toString().includes(option.toString()) ?
                                                                true
                                                                : false
                                                    : false
                                            }
                                        />
                                        <button>
                                            {option.logo ?
                                                <img
                                                    src={option.logo}
                                                    alt={option.name + ' Logo'} />
                                                : null}
                                            {option.name ? (option.emoji ? option.emoji + ' ' : '') + option.name : option}
                                            {option.most_sporty || option.longest_range ?
                                                option.most_sporty ? ' (Sport)' : ' (Reichweite)'
                                                : ''}
                                        </button>
                                    </label>
                                </li>
                            </React.Fragment>
                        )}
                    </ul>
                </div>
                : null}
        </div>
    );
}

export default Select;