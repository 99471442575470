import React, { useState, useEffect } from 'react';

import { // Functions.
    generateRandomID
} from '../../functions/functions';


function Scroller(props: any) {
    const [id] = useState<string>(() => generateRandomID());
    const [scrollerWidth, setScrollerWidth] = useState<number>(0);
    const [displayNavArrows, setDisplayNavArrows] = useState<boolean>(false);
    const [scrollerActive, setScrollerActive] = useState<boolean>(false);
    const [startClientX, setStartClientX] = useState<number>(0);
    const [startScrollPosition, setStartScrollPosition] = useState<number>(0);

    // Page resize listener.
    function getScrollerWith() {
        const scroller: any = document.getElementsByClassName('Scroller');
        if (scroller) {
            setScrollerWidth(scroller[0].offsetWidth);
        }
    }

    useEffect(() => {
        if (id) {
            getScrollerWith();
            window.removeEventListener('resize', getScrollerWith, true);
            window.addEventListener('resize', getScrollerWith, true);
        }
    }, [id]);

    // Decide if nav arrrows are displayed.
    useEffect(() => {
        const scrollerInner: any = document.getElementById(id);
        if (scrollerInner) {
            const scrollerInnerChildren: any = scrollerInner.children;
            if (scrollerInnerChildren && scrollerInnerChildren.length > 0) {
                const scrollerInnerWidth: number = scrollerInnerChildren[scrollerInnerChildren.length - 1].offsetLeft + scrollerInnerChildren[scrollerInnerChildren.length - 1].offsetWidth;
                if (scrollerWidth && scrollerInnerWidth && scrollerWidth < scrollerInnerWidth) {
                    setDisplayNavArrows(true);
                } else {
                    setDisplayNavArrows(false);
                }
            }
        }
    }, [scrollerWidth, props.children]);

    // Scroller navagation.
    function onMouseMove(e: any) {
        if (scrollerActive) {
            e.target.scrollLeft = startScrollPosition + startClientX - e.clientX;
        }
    }

    function scrollLeft() {
        if (id) {
            const scrollerInner: any = document.getElementById(id);
            if (scrollerInner) {
                scrollerInner.scrollBy({ left: -150, behavior: 'smooth' });
                setStartScrollPosition(scrollerInner.scrollLeft);
            }
        }
    }

    function scrollRight() {
        if (id) {
            const scrollerInner: any = document.getElementById(id);
            if (scrollerInner) {
                scrollerInner.scrollBy({ left: 150, behavior: 'smooth' });
                setStartScrollPosition(scrollerInner.scrollLeft);
            }
        }
    }

    return (
        <div className={"Scroller" + (props.className ? " " + props.className : "")}>
            {displayNavArrows && startScrollPosition > 0 ?
                <button className="btn-onclick Scroller-prev" onClick={() => scrollLeft()}>
                    <div className="Scroller-prev-inner">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path></svg>
                    </div>
                </button>
                : null}
            {displayNavArrows ?
                <button className="btn-onclick Scroller-next" onClick={() => scrollRight()}>
                    <div className="Scroller-next-inner">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg>
                    </div>
                </button>
                : null}
            <div className="Scroller-inner" id={id}>
                {props.children}
            </div>
        </div>
    );
}

export default Scroller;

/*

onMouseDown={(e: any) => {
    const childern = e.target.childNodes
    if (childern) {
        childern.forEach((child: any) => {
            child.style.pointerEvents = "none";
        });
    }
    setStartClientX(e.clientX);
    setStartScrollPosition(e.target.scrollLeft);
    setScrollerActive(true); }}
onMouseUp={(e: any) => {
    const childern = e.target.childNodes
    if (childern) {
        childern.forEach((child: any) => {
            child.style.pointerEvents = "auto";
        });
    }
    setScrollerActive(false)}}
onMouseMove={(e: any) => onMouseMove(e)}

*/