import { axiosInstanceJWT, axiosLocalErrorHandling } from '../axios';


export const putInfluencer = (props: any) => {
    const formData = new FormData();
    var formDataLength = 0;

    // Meta data.
    let influencerMetaDataData: any = {}
    const influencerMetaDataIdInput: any = document.getElementById('influencerMetaDataId');
    const influencerMetaDataId = (influencerMetaDataIdInput && influencerMetaDataIdInput.value ? influencerMetaDataIdInput.value : '');
    if (influencerMetaDataId) {
        influencerMetaDataData.id = influencerMetaDataId;
    }
    const influencerStatusInput: any = document.getElementById('influencerMetaDataStatus');
    const influencerStatusId = (influencerStatusInput && influencerStatusInput.checked ? influencerStatusInput.checked : '');
    if (influencerStatusId) {
        influencerMetaDataData.status = 2;
    } else {
        influencerMetaDataData.status = 1;
    }
    if (influencerStatusInput && Object.keys(influencerMetaDataData).length > 0) {
        formData.append('meta_data_json', JSON.stringify(influencerMetaDataData));
        formDataLength = formDataLength + 1;
    }

    // Legal name.
    const influencerLegalNameNotify: any = document.getElementById('influencerLegalNameNotify');
    const influencerLegalNameInput: any = document.getElementById('influencerLegalName');
    const dataLegalName = (influencerLegalNameInput && influencerLegalNameInput.value ? influencerLegalNameInput.value : '');
    if (dataLegalName) {
        formData.append('name', dataLegalName);
        formDataLength = formDataLength + 1;
        influencerLegalNameNotify.classList.add("btn-info-placeholder");
        influencerLegalNameNotify.classList.remove("btn-info-second");
        influencerLegalNameNotify.innerHTML = "";
    } else if (influencerLegalNameInput && props.influencer && props.influencer.name) {
        influencerLegalNameNotify.classList.remove("btn-info-placeholder");
        influencerLegalNameNotify.classList.add("btn-info-second");
        influencerLegalNameNotify.innerHTML = "Der Unternehmensname kann nicht leer gespeichert werden!"
    }

    // Address.
    const influencerAddressNotify: any = document.getElementById('influencerAddressNotify');

    // Line 1.
    const influencerLine1Input: any = document.getElementById('influencerLine1');
    const dataLine1 = (influencerLine1Input && influencerLine1Input.value ? influencerLine1Input.value : '');
    if (dataLine1) {
        formData.append('line_1', dataLine1);
        formDataLength = formDataLength + 1;
        influencerAddressNotify.classList.add("btn-info-placeholder");
        influencerAddressNotify.classList.remove("btn-info-second");
        influencerAddressNotify.innerHTML = "";
    } else if (influencerLine1Input && props.influencer && props.influencer.line_1) {
        influencerAddressNotify.classList.remove("btn-info-placeholder");
        influencerAddressNotify.classList.add("btn-info-second");
        influencerAddressNotify.innerHTML = "Notwendige Addressangaben können nicht leer gespeichert werden!"
    }

    // Line 2.
    const influencerLine2Input: any = document.getElementById('influencerLine2');
    const dataLine2 = (influencerLine2Input && influencerLine2Input.value ? influencerLine2Input.value : '');
    if (influencerLine2Input) {
        formData.append('line_2', dataLine2);
        formDataLength = formDataLength + 1;
    }

    // City.
    const influencerCityInput: any = document.getElementById('influencerCity');
    const dataCity = (influencerCityInput && influencerCityInput.value ? influencerCityInput.value : '');
    if (dataCity) {
        formData.append('city', dataCity);
        formDataLength = formDataLength + 1;
        influencerAddressNotify.classList.add("btn-info-placeholder");
        influencerAddressNotify.classList.remove("btn-info-second");
        influencerAddressNotify.innerHTML = "";
    } else if (influencerCityInput && props.influencer && props.influencer.city) {
        influencerAddressNotify.classList.remove("btn-info-placeholder");
        influencerAddressNotify.classList.add("btn-info-second");
        influencerAddressNotify.innerHTML = "Notwendige Addressangaben können nicht leer gespeichert werden!"
    }

    // Postal code.
    const influencerPostalCodeInput: any = document.getElementById('influencerPostalCode');
    const dataPostalCode = (influencerPostalCodeInput && influencerPostalCodeInput.value ? influencerPostalCodeInput.value : '');
    if (dataPostalCode) {
        formData.append('postal_code', dataPostalCode);
        formDataLength = formDataLength + 1;
        influencerAddressNotify.classList.add("btn-info-placeholder");
        influencerAddressNotify.classList.remove("btn-info-second");
        influencerAddressNotify.innerHTML = "";
    } else if (influencerPostalCodeInput && props.influencer && props.influencer.postal_code) {
        influencerAddressNotify.classList.remove("btn-info-placeholder");
        influencerAddressNotify.classList.add("btn-info-second");
        influencerAddressNotify.innerHTML = "Addressangaben können nicht leer gespeichert werden!"
    }

    // Country.
    const influencerCountryInput: any = document.querySelector('input[name="influencerCountry"]:checked');
    const dataCountry = (influencerCountryInput && influencerCountryInput.value ? influencerCountryInput.value : '');
    // console.log(dataCountry)
    if (dataCountry) {
        formData.append('country_json', dataCountry);
        formDataLength = formDataLength + 1;
        influencerAddressNotify.classList.add("btn-info-placeholder");
        influencerAddressNotify.classList.remove("btn-info-second");
        influencerAddressNotify.innerHTML = "";
    } else if (influencerCountryInput && props.influencer && props.influencer.country) {
        influencerAddressNotify.classList.remove("btn-info-placeholder");
        influencerAddressNotify.classList.add("btn-info-second");
        influencerAddressNotify.innerHTML = "Notwendige Addressangaben können nicht leer gespeichert werden!"
    }

    // Customer support, messaging.
    const influencerSupportNotify: any = document.getElementById('influencerSupportNotify');

    // E-mail.
    const influencerEmailInput: any = document.getElementById('influencerEmail');
    const dataEmail = (influencerEmailInput && influencerEmailInput.value ? influencerEmailInput.value : '');
    if (dataEmail && new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i).test(dataEmail)) {
        formData.append('email', dataEmail);
        formDataLength = formDataLength + 1;
        influencerSupportNotify.classList.remove("btn-info-second");
        influencerSupportNotify.classList.add("btn-info-placeholder");
        influencerSupportNotify.innerHTML = ""
    } else if (influencerEmailInput && props.influencer && props.influencer.email && !new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i).test(dataEmail)) {
        influencerSupportNotify.classList.remove("btn-info-placeholder");
        influencerSupportNotify.classList.add("btn-info-second");
        influencerSupportNotify.innerHTML = "Geben Sie eine korrekte E-Mail Adresse ein!"
    } else if (influencerEmailInput && props.influencer && props.influencer.email) {
        influencerSupportNotify.classList.remove("btn-info-placeholder");
        influencerSupportNotify.classList.add("btn-info-second");
        influencerSupportNotify.innerHTML = "Ihre E-Mail Adresse kann nicht leer gespeichert werden!"
    }

    // Phone.
    const influencerPhoneInput: any = document.getElementById('influencerPhone');
    const dataPhone = (influencerPhoneInput && influencerPhoneInput.value ? influencerPhoneInput.value : '');
    if (influencerPhoneInput) {
        formData.append('phone', dataPhone);
        formDataLength = formDataLength + 1;
    }

    // Brand-name.
    const influencerBrandingNameInput: any = document.getElementById('influencerBrandingName');
    const dataInfluencerName = (influencerBrandingNameInput && influencerBrandingNameInput.value ? influencerBrandingNameInput.value : '');
    const influencerBrandingNameNotify: any = document.getElementById('influencerBrandingNameNotify');
    if (influencerBrandingNameInput) {
        formData.append('branding', dataInfluencerName);
        formDataLength = formDataLength + 1;
    }

    // Brand-logo.
    const influencerLogoInput: any = document.getElementById('influencerLogo');
    const dataLogo = (influencerLogoInput && influencerLogoInput.files[0] ? influencerLogoInput.files[0] : undefined);
    if (dataLogo) {  // influencerLogoInput
        formData.append('logo', dataLogo);
        formDataLength = formDataLength + 1;
    }

    if (formDataLength > 0) {
        if (props.influencer) {
            axiosInstanceJWT.put('backend/influencer/update/' + props.influencer.id + '/', formData)
                .then(function (response: any) {
                    props.setNotification("Änderungen gespeichert!");
                    if (response && response.data && props.setInfluencer) {
                        props.setInfluencer(response.data);
                    }
                })
                .catch(function (error: any) {
                    if (error.response && error.response.data) {
                        // Name already exists.
                        if (error.response.data.name && influencerLegalNameNotify) {
                            influencerLegalNameNotify.classList.remove("btn-info-placeholder");
                            influencerLegalNameNotify.classList.add("btn-info-second");
                            influencerLegalNameNotify.innerHTML = error.response.data.name
                        }
                    } else {
                        props.setNotification("Ups, bitte versuche es nocheinmal!");
                        axiosLocalErrorHandling({ error: error, function: "putInfluencer" });
                    }
                })

        } else {
            console.log("Influencer instance not provided!");
        }
    }
}