import React, { useState, useEffect } from 'react';


function Element(props: any) {
    const name = props.name;
    const quickSave = props.quickSave;

    // Api data.
    const [text, setText] = useState<string>('');
    const [order, setOrder] = useState<number>(0);
    const [id, setId] = useState<number>(0);
    const [type, setType] = useState<number>(0);
    
    useEffect(() => {
        if (props.element) {
            setText(props.element.text);
            setOrder(props.element.order);
            setId(props.element.id);
            setType(props.element.type);
        }
    }, [props.element]);

    // Reorder.
    function reorderElements(props: any) {
        if (name) {
            const allElements: any = document.getElementsByName(name);
            if (allElements.length) {
                for (let i = 0; i < allElements.length; i++) {
                    if (parseInt(allElements[i].getAttribute("data-order")) === (props.order + props.reorder)) {
                        allElements[i].setAttribute("data-order", props.order);
                    } else if (parseInt(allElements[i].getAttribute("data-order")) === props.order) {
                        allElements[i].setAttribute("data-order", props.order + props.reorder);
                    }
                }
                quickSave();
            }
        }
    }

    // Delete.
    function deleteElement() {
        const allElements: any = document.getElementsByName(name);
        if (allElements.length) {
            for (let i = 0; i < allElements.length; i++) {
                if (parseInt(allElements[i].getAttribute("data-order")) > order) {
                    allElements[i].setAttribute("data-order", (parseInt(allElements[i].getAttribute("data-order")) - 1));
                }
                if (parseInt(allElements[i].getAttribute("data-id")) === id) {
                    allElements[i].value = "";
                }
            }
        }
        quickSave();
    }

    // Create new.
    const [newElement, setNewElement] = useState<string>('');
    const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

    function closeElementDropdown(e: any) {
        if (!e.target.classList.contains("Element-trigger")) {
            setDropdownVisible(false);
            document.body.removeEventListener('click', closeElementDropdown, false);
        }
    }

    useEffect(() => {
        if (dropdownVisible) {
            document.body.addEventListener('click', closeElementDropdown, false);
        }
    }, [dropdownVisible]);


    return (
        <div className="Element">
            {props.element ? 
                <div className="flex Element-excisting">
                    {props.element.type === 1 ? 
                        <input 
                            name={name} className="w-100 input-header" 
                            placeholder="Neue Überschrift..." value={text} data-order={order} data-id={id} data-type={type}
                            onChange={(e: any) => {
                                setText(e.target.value);
                                props.clearAutoSave();
                                props.autoSave();
                                props.setNotification("Änderungen werden gespeichert...");}} />
                        : <textarea 
                            name={name} className="w-100" rows={6} 
                            placeholder="Neuer Absatz..." value={text} data-order={order} data-id={id} data-type={type}
                            onChange={(e: any) => {
                                setText(e.target.value);
                                props.clearAutoSave();
                                props.autoSave();
                                props.setNotification("Änderungen werden gespeichert...");}} />}
                    
                    <div className="ml-0-5">
                        <div className="a flex flex-jc mb-0-25">
                            {order > 1 ? 
                                <button 
                                    className="btn-onclick op-50"
                                    onClick={() => reorderElements({ order: order, reorder: -1, })}>
                                    <svg className="h-0-5em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.092 6.441"><path d="M1140.384-264.895l4.6,4.03,4.671-4.03" transform="translate(1151.065 -259.865) rotate(180)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/></svg>
                                </button>
                                : null}
                        </div>
                        <div className="a flex flex-jc">
                            <button 
                                className="btn-onclick op-50"
                                onClick={() => deleteElement()}>
                                <svg className="h-1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 22.857"><path d="M11.964,18.571h1.071a.536.536,0,0,0,.536-.536V8.393a.536.536,0,0,0-.536-.536H11.964a.536.536,0,0,0-.536.536v9.643A.536.536,0,0,0,11.964,18.571Zm7.321-15H15.607L14.089,1.04A2.143,2.143,0,0,0,12.25,0H7.75A2.143,2.143,0,0,0,5.912,1.04L4.393,3.571H.714A.714.714,0,0,0,0,4.286V5a.714.714,0,0,0,.714.714h.714v15a2.143,2.143,0,0,0,2.143,2.143H16.429a2.143,2.143,0,0,0,2.143-2.143v-15h.714A.714.714,0,0,0,20,5V4.286A.714.714,0,0,0,19.286,3.571ZM7.671,2.273a.268.268,0,0,1,.23-.13h4.2a.268.268,0,0,1,.23.13l.779,1.3H6.892Zm8.757,18.442H3.571v-15H16.429ZM6.964,18.571H8.036a.536.536,0,0,0,.536-.536V8.393a.536.536,0,0,0-.536-.536H6.964c-.3,0-.4-2.009-.536.536s0,9.643,0,9.643A.536.536,0,0,0,6.964,18.571Z" fill="currentColor"/></svg>
                            </button>
                        </div>
                        <div className="a flex flex-jc">
                            <button 
                                className="btn-onclick op-50"
                                onClick={() => reorderElements({ order: order, reorder: 1, })}>
                                <svg className="h-0-5em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.092 6.441"><path d="M1140.384-264.895l4.6,4.03,4.671-4.03" transform="translate(-1138.973 266.306)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/></svg>
                            </button>
                        </div>
                    </div>
                </div>

                : <div className="Element-create">
                    {newElement ? 
                        <React.StrictMode>
                            {newElement === 'header' ? 
                                <input 
                                    name={name} className="w-100 input-header newElementInput"
                                    placeholder="Neue Überschrift..." value={text} data-order={props.order} data-type={1}
                                    onChange={(e: any) => setText(e.target.value)} />
                                : <textarea 
                                    name={name} className="w-100 newElementInput" rows={6} 
                                    placeholder="Neuer Absatz..." value={text} data-order={props.order} data-type={2}
                                    onChange={(e: any) => setText(e.target.value)} />}
                            <div className="a flex flex-jc">
                                <button 
                                    className="btn-outline btn-sm mt-0-5 op-50 mr-0-25"
                                    onClick={() => setNewElement('')}>
                                    <h5>Abbrechen</h5>
                                </button>
                                {text ? 
                                    <button 
                                        className="btn-sm mt-0-5 ml-0-25"
                                        onClick={() => {
                                            quickSave();
                                            props.setNotification("Änderungen werden gespeichert...");
                                            setText('');
                                            setNewElement('');}}>
                                        <h5>Speichern</h5>
                                    </button>
                                    : null}
                            </div>
                        </React.StrictMode>
                        
                        : <button 
                            className="btn-onclick Element-create-trigger op-50"
                            onClick={() => setDropdownVisible(!dropdownVisible)}>
                            <svg className="w-1-2em h-1-2em flex-noshrink" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.4 194.4"><path d="M97.2,0a97.2,97.2,0,1,0,97.2,97.2A97.246,97.246,0,0,0,97.2,0Zm0,16.663A80.537,80.537,0,1,1,16.663,97.2,80.575,80.575,0,0,1,97.2,16.663ZM92.534,138.724a3.718,3.718,0,0,1-3.732-3.733V104.665H59.1a3.718,3.718,0,0,1-3.733-3.733v-8.4A3.718,3.718,0,0,1,59.1,88.8H88.8V59.409a3.534,3.534,0,0,1,1.089-2.722,3.742,3.742,0,0,1,2.643-1.011h9.332a3.484,3.484,0,0,1,3.732,3.733V88.8h29.7a3.718,3.718,0,0,1,3.733,3.732v8.4a3.743,3.743,0,0,1-1.011,2.644,3.534,3.534,0,0,1-2.722,1.089H105.6v30.326a3.745,3.745,0,0,1-1.011,2.644,3.533,3.533,0,0,1-2.721,1.089Z" fill="currentColor" /></svg>
                            <h5 className="ml-0-5">Element hinzufügen</h5>
                        </button>}
                    
                    {dropdownVisible ? 
                        <div className="Element-create-dropdown">
                            <button 
                                className="btn-outline btn-sm"
                                onClick={() => setNewElement('header')}>
                                Überschrift</button>
                            <button 
                                className="btn-outline btn-sm"
                                onClick={() => setNewElement('paragraph')}>
                                Absatz</button>
                        </div>
                        : null}
                </div>}
        </div>
    );
}

export default Element;