import React, { useState, useEffect } from 'react';

import { // Components.
    Banner, TestimonialBanner,
    Feed,
    Nav,
    Wrapper
} from '../components/components';

import { // Functions.
    getManufacturers, getBodyStyles, getReviews,
    getCookie
} from "../functions/functions";


function ReviewFeed(props: any) {
    // Api data.
    const [allBodyStyles, setAllBodyStyles] = useState<any>(undefined);
    const [allManufacturers, setAllManufacturers] = useState<any>(undefined);
    const [reviews, setReviews] = useState<number>(0);
    const [reviewsCount, setReviewsCount] = useState<number>(0);

    useEffect(() => {
        if (getCookie('loc')) {
            getBodyStyles({ loc: props.loc, setBodyStyles: setAllBodyStyles, });
            getManufacturers({ loc: props.loc, setManufacturers: setAllManufacturers, });
            getReviews({ loc: props.loc, inf: props.inf, setReviews: setReviews, setReviewsCount: setReviewsCount, order: "popularity", });
        }
    }, [props.loc, props.inf]);


    return (
        <React.StrictMode>
            <div className="bg-prime w-100 mb-3">
                <hr className="bg-base" />
                <div className="flex flex-ai-e h-15em">
                    <Wrapper>
                        <div className="mb-3">
                            <h2 className="c-base">
                                {reviewsCount ? "(" + reviewsCount + ") " : ""} Aktuelle Fahrzeug-Bewertung{reviewsCount && reviewsCount > 1 ? "en" : ""}
                            </h2>
                        </div>
                    </Wrapper>
                </div>
            </div>

            <Wrapper>
                {reviews && reviewsCount === 0 ?
                    <div>
                        <div className="mb-5 hide-lg"></div>
                        <div className="hide display-lg"><div className="mb-3"></div></div>
                        <div className="flex flex-jc op-50">
                            <h2>Keine Fahrzeug-Bewertungen gefunden!</h2>
                        </div>
                    </div>
                    : <Feed
                        type="review" qs={reviews}
                        localhost={props.localhost}
                        loc={props.loc} country={props.country} inf={props.inf} influencer={props.influencer} />}


                <div className="mb-5 hide-lg"></div>
                <div className="hide display-lg"><div className="mb-3"></div></div>


                {/* Current promotions. */}
                {props.country && props.country.promotions.length ?
                    <Banner country={props.country} localhost={props.localhost} />
                    : null}


                <div className="mb-5 hide-lg"></div>
                <div className="hide display-lg"><div className="mb-3"></div></div>


                {/* All manufacturers. */}
                <Nav
                    header="Alle Marken"
                    type="horizontal" columns="5" limit="8"
                    items={allManufacturers} href="pkw?hersteller="
                    loc={props.loc} inf={props.inf} />


                <div className="mb-5 hide-lg"></div>
                <div className="hide display-lg"><div className="mb-3"></div></div>


                {/* All body-styles. */}
                <Nav
                    header="Alle Karosserieformen"
                    type="horizontal" columns="5"
                    items={allBodyStyles} href="pkw?karosserieform="
                    loc={props.loc} inf={props.inf} />


                <div className="mb-5 hide-lg"></div>
                <div className="hide display-lg"><div className="mb-3"></div></div>


                <div>
                    <TestimonialBanner localhost={props.localhost} loc={props.loc} inf={props.inf} user={props.user} />
                </div>


                <div className="mb-5 hide-lg"></div>
                <div className="hide display-lg"><div className="mb-3"></div></div>
            </Wrapper>
        </React.StrictMode >
    );
}

export default ReviewFeed;