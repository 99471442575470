import { Helmet } from 'react-helmet';

import React from 'react';

import { // Components.
    Wrapper 
} from '../components/components';

import { // Functions.
    countryUrl 
} from '../functions/functions';


function TermsOfUse(props: any) {
    // 🕸 Site wide vars 🕸
    const loc = props.loc;


    // 🤝 Partner Logic. 🤝
    const inf = props.inf;
    const influencer = props.influencer;


    return (
        <React.StrictMode>
            <Helmet>
                <title>
                    {influencer && influencer.slug ? 
                        "Nutzungsbedingungen | electricmagazin.com/" + influencer.slug
                        : "Nutzungsbedingungen | electricmagazin.com"}
                </title>
                <meta
                    name="description"
                    content={"Unsere allgemeinen Geschäfts- sowie Nutzungsbedingungen gelten für die Nutzung unserer Dienste. Grundlegend gilt immer die aktuellste Version unserer Nutzungsbedingungen sowie unserer Datenschutzrichtlinien."} />
            </Helmet>

            <Wrapper>
                <div className="mb-2 c-transparent">.</div>

                <h1 className="mb-1 center capitalize">Nutzungsbedingungen</h1>

                <p className="mb-3">
                    Wir möchten Ihnen im Folgenden unsere allgemeinen Geschäfts- und Nutzungsbedingungen kundmachen.
                </p>

                <h2 className="mb-1">1. Geltungsbereich</h2>

                <p className="mb-3">
                    Unsere allgemeinen Geschäfts- sowie Nutzungsbedingungen gelten für die Nutzung unserer Dienste. Grundlegend gilt immer die aktuellste Version unserer Nutzungsbedingungen sowie unserer Datenschutzrichtlinien, welche Sie unter: <a href={countryUrl({ loc: loc, inf: inf }) + "nutzungsbedingungen"}><u>https://electricmagazin.com/{influencer ? influencer.slug + '/' : ''}{loc ? loc + '/' : ''}nutzungsbedingungen</u></a> und <a href={countryUrl({ loc: loc, inf: inf }) + "datenschutzrichtlinien"}><u>https://electricmagazin.com/{influencer ? influencer.slug + '/' : ''}{loc ? loc + '/' : ''}datenschutzrichtlinien</u></a> abrufen können.
                    <br /><br />
                    Wir ({influencer ? influencer.name + ", " + influencer.line1 + " - " + influencer.city : "Christoph Amort, Embach 101 - 6320 Angerberg"}, im Folgenden “{influencer ? influencer.name : "ElectricMagazin"}” oder “wir” genannt") ermöglichen Ihnen auf unseren Seiten Elektrofahrzeugen aller Art zu Entdecken und zu Vergleichen.
                </p>

                <h2 className="mb-1">2. Allgemeine Bestimmungen</h2>

                <p className="mb-3">
                    Für die Nutzung unserer Dienste setzen wir voraus, dass Sie die Nutzungsbedingungen akzeptieren. Diese gelten als akzeptiert, sobald Sie unsere Dienste nutzen.
                </p>

                <h2 className="mb-1">3. Angebot</h2>

                <p className="mb-3">
                    Auf unseren Seiten ermöglichen wir Ihnen Elektrofahrzeuge aller Art zu entdecken und diese untereinander zu Vergleichen.
                    <br /><br />
                    Trotz sorgfältiger Bearbeitung haften wir nicht für die Vollständigkeit sowie die Richtigkeit der angegebenen Informationen.
                </p>

                <h2 className="mb-1">4. Preise</h2>

                <p className="mb-3">
                    Alle abgegebenen Preise verstehen sich in Brutto-Beträgen, ohne Abzug von möglichen Förderungen und werden in Euro angegeben.
                </p>

                <h2 className="mb-1">5. Vertragliche Kommunikation und Vertragssprache</h2>

                <p className="mb-3">
                    Sie stimmen zu, dass die vertragliche Kommunikation in elektronischer Form geführt wird.
                    <br /><br />
                    Die Vertrags-, Bestell- und Geschäftssprache ist Deutsch.
                </p>

                <h2 className="mb-1">6. Benachrichtigungen</h2>

                <p className="mb-3">
                    Sie erhalten von uns nur dann Benachrichtigungen, wenn Sie in den Erhalt eingewilligt haben. Ausgenommen davon sind Benachrichtigungen, die uns gesetzlich gestattet sind und/oder zu denen wir gesetzlich und/oder vertraglich verpflichtet sind. Sie können direkt am Ende jeder E-Mail dem zukünftigen Erhalt des betroffenen E-Mail-Typs widersprechen, ohne dass hierfür andere als die Übermittlungskosten nach den Basistarifen entstehen.
                </p>

                <h2 className="mb-1">7. Datenschutz</h2>

                <p className="mb-3">
                    Wir legen sehr viel Wert auf den Schutz Ihrer persönlichen Daten. Aus diesem Grund halten wir uns streng an die Datenschutzrichtlinien der EU.
                    <br /><br />
                    Sie haben jederzeit das Recht auf Berichtigung, Auskunft und Sperrung sowie Löschung Ihrer gespeicherten Daten. Dieses Recht ist nicht anwendbar, sofern wir die Löschung zur Wahrung unserer Ansprüche aussetzen können. Genauere Bestimmungen zum Datenschutz können Sie unseren Datenschutzrichtlinien unter: <a href={countryUrl({ loc: loc, inf: inf }) + "datenschutzrichtlinien"}><u>https://electricmagazin.com/{influencer ? influencer.slug + '/' : ''}{loc ? loc + '/' : ''}datenschutzrichtlinien</u></a> entnehmen.
                </p>

                <h2 className="mb-1">8. Gewerbliche Schutzrechte</h2>

                <p className="mb-3">
                    Auf unseren Seiten gelten das Urheberrecht und/oder andere Gesetze zum Schutze des geistigen Eigentums, welche Firmennamen, Marken, Logos, Daten, Software, Informationen, Bilder, Videos, Suchergebnisse und Texte beinhalten. Nur mit unserer ausdrücklichen Genehmigung dürfen Sie diese Inhalte verbreiten, vervielfältigen und bearbeiten.
                </p>

                <h2 className="mb-1">9. Gerichtsstand</h2>

                <p className="mb-3">
                    Es gilt das österreichische Recht der Republik Österreich unter Ausschluss des UN-Kaufrecht. Als Gerichtsstand wird das sachlich zuständige Gericht in Innsbruck vereinbart.
                    <br /><br />
                    Dies gilt nicht für Klagen gegen Konsumenten, sofern zwingende Verbraucherschutzvorschriften (deines Wohnsitz Ortes) verletzt würden.
                </p>

                <h2 className="mb-1">10. Schlussbestimmungen</h2>

                <p className="mb-3">
                    Wir bemühen uns, Ihnen unsere Dienste so unterbrechungsfrei wie möglich zur Verfügung zu stellen, können jedoch keine bestimmte Verfügbarkeitsrate garantieren.
                    <br /><br />
                    Jeder Teilnehmer haftet selbst und unmittelbar für seine Handlungen über unsere Dienste. {influencer ? influencer.name : "ElectricMagazin"} haftet nicht für Rechtsverletzungen ihrerseits. Wenn bei Nichtbeachtung der Nutzungsbedingungen Schäden für eine Partei entstehen, muss der Verursacher für diese persönlich aufkommen.
                    <br /><br />
                    Wir haften für alle eigens erstellten (und selbst veröffentlichten) Inhalte auf unseren Seiten.
                    <br /><br />
                    Wir haften nicht für Datenverlust aus computertechnischen Gründen.
                </p>

                <h2 className="mb-1">11. Änderungsvorbehalt</h2>

                <p className="mb-3">
                    Wir sind berechtigt, unsere Nutzungsbedingungen und Datenschutzrichtlinien zu ändern und zu ergänzen. Unsere neuen Nutzungsbedingungen und Datenschutzrichtlinien, gelten nach deren Kundmachung unmittelbar.
                </p>

                <h2 className="mb-1">12. Kundenbetreuung</h2>

                <p className="mb-5">
                    Bei Unklarheiten oder Fragen können Sie uns gerne jederzeit ohne, dass hierfür andere als die Übermittlungskosten nach den Basistarifen entstehen unter: <a className="c-primary" href={"mailto:" + (influencer ? influencer.email : "hallo@electricmagazin.com")}><u>{influencer ? influencer.email : "hallo@electricmagazin.com"}</u></a> kontaktieren.
                </p>
            </Wrapper>
        </React.StrictMode >
    );
}

export default TermsOfUse;