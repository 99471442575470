import { axiosInstance, axiosInstanceJWT, axiosLocalErrorHandling } from '../axios';

import { // Functions.
    countryUrl,
    postIpBlacklist
} from '../../functions';


// Register new user.
export const postUser = (props: any) => {
    if (props.email && props.password && props.password2) {
        let jsonData: any = {}
        jsonData.email = props.email;
        jsonData.password = props.password;
        jsonData.password2 = props.password2;
        if (props.inf) { jsonData.referrer = props.inf; }

        axiosInstance.post('backend/user/create/',
            jsonData, {
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            }
        })
            .then(function (response: any) {
                props.setNotification('Account erfolgreich erstellt!');

                // Login in new user.
                if (props.authUser) {
                    props.authUser({ email: props.email, password: props.password, loc: props.loc, inf: props.inf, setNotification: props.setNotification, token: props.token });
                }
            })
            .catch(function (error: any) {
                if (error.response && error.response.data) {
                    // E-Mail already exists.
                    if (error.response.data.email) {
                        const emailInput = document.getElementById('email');
                        if (emailInput) { emailInput.classList.add("error"); }
                        props.setNotification(error.response.data.email);
                    }
                } else {
                    props.setNotification("Ups, bitte versuche es nocheinmal!");
                    axiosLocalErrorHandling({ error: error, function: "postUser" });
                }
            })
    }
}


// Get refreshtoken and authtoken.
export const authUser = (props: any) => {
    if (props.email && props.password) {
        axiosInstance.post('auth/token/', {
            'email': props.email,
            'password': props.password,
        }, {
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            }
        })
            .then(function (response: any) {
                localStorage.setItem('access_token', response.data.access);
                localStorage.setItem('refresh_token', response.data.refresh);
                axiosInstanceJWT.defaults.headers['Authorization'] =
                    'JWT ' + localStorage.getItem('access_token');


                axiosInstanceJWT.get('backend/user/current/')
                    .then(function (response: any) {
                        if (response && response.data && response.data.influencer && response.data.influencer.length) {

                            // Add current ip address to ipblacklist.
                            postIpBlacklist();

                            if (props.target === "erfahrungsbericht") {
                                window.location.pathname = "/dashboard/testimonials";
                            } else {
                                window.location.pathname = "/dashboard/settings";
                            }
                        } else {
                            if (props.token) {
                                let jsonData: any = {}
                                jsonData.token = props.token;

                                axiosInstanceJWT.post('backend/influencer/create/',
                                    jsonData, {
                                    headers: {
                                        'Content-Type': 'application/json',
                                        accept: 'application/json',
                                    }
                                })
                                    .then(function (response: any) {
                                        if (props.target === "erfahrungsbericht") {
                                            window.location.pathname = "/dashboard/testimonials";
                                        } else {
                                            window.location.pathname = "/dashboard/settings";
                                        }
                                    })
                                    .catch(function (error: any) {
                                        props.setNotification("Ups, dieser Link ist ungültig!");
                                        axiosLocalErrorHandling({ error: error, function: "authUser 1" });
                                    })

                            } else {
                                if (props.target === "erfahrungsbericht") {
                                    window.location.pathname = countryUrl({ loc: props.loc, inf: props.inf }) + "account/erfahrungsberichte";
                                } else {
                                    window.location.pathname = countryUrl({ loc: props.loc, inf: props.inf }) + "account/einstellungen";
                                }
                            }
                        }
                    })
                    .catch(function (error: any) {
                        props.setNotification("Ups, bitte versuche es nocheinmal!");
                        axiosLocalErrorHandling({ error: error, function: "authUser 2" });
                    })
            })
            .catch(function (error: any) {
                if (error.response && error.response.data) {
                    props.setNotification("Ups, E-Mail oder Passwort falsch!")
                } else {
                    props.setNotification("Ups, bitte versuche es nocheinmal!");
                    axiosLocalErrorHandling({ error: error, function: "authUser 3" });
                }
            })
    }
}


// Blacklist refreshtoken.
export const authUserLogout = (props: any) => {
    axiosInstanceJWT.post('auth/token/blacklist/', {
        refresh_token: localStorage.getItem('refresh_token'),
    }, {
        headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
        }
    })
        .then(function (response: any) {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            axiosInstanceJWT.defaults.headers['Authorization'] = null;
            window.location.href = countryUrl({ loc: props.loc, inf: props.inf }) + "anmelden?abgemeldet=erfolgreich";
        })
        .catch(function (error: any) {
            props.setNotification("Ups, bitte versuche es nocheinmal!");
            axiosLocalErrorHandling({ error: error, function: "authUserLogout" });
        })
}