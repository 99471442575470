import { // Functions.
    scrollToId
} from "../../../../functions/functions";


function FloatNav(props: any) {
    return (
        <div className={"FloatNav hide-xxlg" + (props.className ? " " + props.className : "")}>
            <ul>
                <li>
                    <button
                        className="btn-onclick"
                        onClick={() => scrollToId('technische-daten')}>
                        <div>
                            <h5>Technische Daten</h5>
                        </div>
                    </button>
                </li>
                {props.vehicle && props.vehicle.review ?
                    <li>
                        <button
                            className="btn-onclick"
                            onClick={() => scrollToId('bewertung')}>
                            <div>
                                <h5>Review</h5>
                            </div>
                        </button>
                    </li>
                    : null}
                <li>
                    <button
                        className="btn-onclick"
                        onClick={() => scrollToId('konfigurator')}>
                        <div>
                            <h5>Konfigurator</h5>
                        </div>
                    </button>
                </li>
                <li>
                    <button
                        className="btn-onclick"
                        onClick={() => scrollToId('platzangebot')}>
                        <div>
                            <h5>Platzangebot</h5>
                        </div>
                    </button>
                </li>
                {props.vehicle && props.vehicle.ncap && props.vehicle.ncap.overall ?
                    <li>
                        <button
                            className="btn-onclick"
                            onClick={() => scrollToId('sicherheit')}>
                            <div>
                                <h5>Sicherheit</h5>
                            </div>
                        </button>
                    </li>
                    : null}
                <li>
                    <button
                        className="btn-onclick"
                        onClick={() => scrollToId('laden-und-langstrecken')}>
                        <div>
                            <h5>Laden und Langstrecken</h5>
                        </div>
                    </button>
                </li>
                <li>
                    <button
                        className="btn-onclick"
                        onClick={() => scrollToId('umwelt')}>
                        <div>
                            <h5>Umwelt</h5>
                        </div>
                    </button>
                </li>
                <li>
                    <button
                        className="btn-onclick"
                        onClick={() => scrollToId('service-garantie-und-wartung')}>
                        <div>
                            <h5>Service, Garantie und Wartung</h5>
                        </div>
                    </button>
                </li>
                {props.vehicle && props.vehicle.testimonials && props.vehicle.testimonials.length ?
                    <li>
                        <button
                            className="btn-onclick"
                            onClick={() => scrollToId('erfahrungsberichte')}>
                            <div>
                                <h5>Erfahrungsberichte</h5>
                            </div>
                        </button>
                    </li>
                    : null}
            </ul>
        </div>
    );
}

export default FloatNav;