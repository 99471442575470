import { axiosInstance, axiosLocalErrorHandling } from '../axios';


export const getBodyStyles = (props: any) => {
    var filter: any = {};

    if (props.loc) {  // Country.
        filter.cars__platforms__availability__country__country_code= props.loc;
    }

    if (props.urlParams) {  // General.
        if (props.urlParams.has('hersteller')) { 
            filter.cars__manufacturer__slug__in= props.urlParams.get('hersteller')
        }
        if (props.urlParams.has('budget-min')) { 
            filter.cars__platforms__availability__price__gt= props.urlParams.get('budget-min');
        }
        if (props.urlParams.has('budget-max')) { 
            filter.cars__platforms__availability__price__lt = props.urlParams.get('budget-max'); 
        }
        if (props.urlParams.has('reichweite-min')) { 
            filter.cars__platforms__wltp__gt= parseInt(props.urlParams.get('reichweite-min')) - 1;
        }
        if (props.urlParams.has('leistung-min')) {
            if (props.urlParams.has('einheit') && props.urlParams.get('einheit') === 'PS') { 
                filter.cars__platforms__ps__gt= parseInt(props.urlParams.get('leistung-min')) - 1;
            } else { 
                filter.cars__platforms__kw__gt= parseInt(props.urlParams.get('leistung-min')) - 1;
            }
        }
        if (props.urlParams.has('allrad')) { 
            filter.cars__platforms__drive = 3;
        }
        if (props.urlParams.has('anhängerkupplung')) { 
            filter.cars__specifications__towing_max__gt = 0; 
        }
        if (props.urlParams.has('sitze-min')) { 
            filter.cars__specifications__seats_max__gt= parseInt(props.urlParams.get('sitze-min')) - 1;
        }
        if (props.urlParams.has('isofix')) { 
            filter.cars__specifications__isofix_max__gt = 0;
        }
        if (props.urlParams.has('materialien')) { 
            filter.cars__configurable_equipment__options__tags__slug= props.urlParams.get('materialien');
        }
    }

    axiosInstance.get('body-styles/', {
        params: filter
    })
    .then(function (response: any) {
        if (response.data && props.setBodyStyles) {
            props.setBodyStyles(response.data); 
        }
    })
    .catch(function (error: any) {
        axiosLocalErrorHandling({ error: error, function: "getBodyStyles" });
    })
}