// https://www.intricatecloud.io/2021/06/handling-async-errors-with-axios-in-react/
import axios from 'axios';

const baseURL = window.location.protocol + '//' + window.location.hostname + (window.location.hostname.includes("localhost") ? ':54190' : '') + '/api/';


export const axiosInstance = axios.create({
	baseURL: baseURL,
	timeout: 20000,
});


export const axiosInstanceJWT = axios.create({
	baseURL: baseURL,
	timeout: 20000,
	headers: {
		Authorization: localStorage.getItem('access_token')
			? 'JWT ' + localStorage.getItem('access_token')
			: null,
	},
});


export const axiosLocalErrorHandling = (props: any) => {
	if (!window.location.hostname.includes("electricmagazin.com")) {
		if (props.error) {
			if (props.function) {
				console.log(props.function);
			}

			if (props.error.response) {
				console.log(props.error.response.data);
				console.log(props.error.response.status);
				console.log(props.error.response.headers);
			} else if (props.error.request) {
				console.log(props.error.request);
			} else {
				console.log('Error', props.error.message);
			}
			console.log(props.error.config);
		}
	}
}


// Update authtoken.
// https://www.bezkoder.com/axios-interceptors-refresh-token/
axiosInstanceJWT.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		const originalRequest = error.config;

		if (error.response) {
			if (error.response.status === 401 && originalRequest.url === baseURL + "auth/token/refresh/") {
				window.location.pathname = "/anmelden";
				return Promise.reject(error);
			}

			if (error.response.status === 401 && error.response.data.code === 'token_not_valid' && error.config && !error.config.__isRetryRequest) { // !originalRequest._retry // && error.response.statusText === 'Unauthorized'
				originalRequest._retry = true;
				const refreshToken = localStorage.getItem('refresh_token');

				if (refreshToken) {
					const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));
					const now = Math.ceil(Date.now() / 1000);
					if (tokenParts.exp > now) {
						return axiosInstance
							.post('auth/token/refresh/', { refresh: localStorage.getItem('refresh_token') })
							.then((response) => {
								localStorage.setItem('access_token', response.data.access);
								localStorage.setItem('refresh_token', response.data.refresh);

								axiosInstanceJWT.defaults.headers['Authorization'] =
									'JWT ' + response.data.access;
								originalRequest.headers['Authorization'] =
									'JWT ' + response.data.access;

								console.log('new tokens created...');
								return axiosInstanceJWT(originalRequest);
							})
							.catch((err) => {
								console.log('fetching failed...');
								return axiosLocalErrorHandling(err);
							});

					} else {
						console.log('Refresh token is expired', tokenParts.exp, now);
						window.location.pathname = "/anmelden";
					}

				} else {
					console.log('Refresh token not available.');
					window.location.pathname = "/anmelden";
				}

			}

			console.log('Noting happened!!');
			return Promise.reject(error);
		}
	}
)