function Infobox(props: any) {
    return (
        <div className={"Infobox " + props.className}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.4 194.4"><path d="M97.2,0a97.2,97.2,0,1,0,97.2,97.2A97.246,97.246,0,0,0,97.2,0Zm0,15A82.2,82.2,0,1,1,15,97.2,82.239,82.239,0,0,1,97.2,15ZM92.9,150.5h8.68a5.786,5.786,0,0,0,4.081-1.578,5.414,5.414,0,0,0,1.779-4.142v-8.12a5.845,5.845,0,0,0-5.86-5.86H92.9a5.414,5.414,0,0,0-4.142,1.779,5.786,5.786,0,0,0-1.578,4.081v8.12a5.61,5.61,0,0,0,5.72,5.72Zm1.26-25.54h6.86a5.725,5.725,0,0,0,3.318-.976,6.7,6.7,0,0,0,2.629-4.374l.053-.252V119.1q0-1.9.274-5.7a16.13,16.13,0,0,1,2.666-7.681,80.015,80.015,0,0,1,7.734-9.418,83.177,83.177,0,0,0,9.949-12.419,25.316,25.316,0,0,0,3.6-13.64,25.578,25.578,0,0,0-3.709-13.245,26.607,26.607,0,0,0-11.393-10.131C111.166,44.378,105.059,43.1,97.8,43.1a39.438,39.438,0,0,0-18.293,4.127A31.66,31.66,0,0,0,66.919,58.471a30.428,30.428,0,0,0-4.8,15.458l0,.091A5.3,5.3,0,0,0,63.834,78.1,5.51,5.51,0,0,0,67.7,79.6h6.16a6.086,6.086,0,0,0,4.065-1.362,6.212,6.212,0,0,0,2.035-3.771c.9-5.179,2.8-9.015,5.876-11.432a18.092,18.092,0,0,1,11.544-3.6A19.384,19.384,0,0,1,108.7,62.775a9.453,9.453,0,0,1,4.383,8.165,6.069,6.069,0,0,1-.065,1.214l-.042.168-.019.172a12.421,12.421,0,0,1-2.4,6.073,82.34,82.34,0,0,1-7.262,8.27,85.385,85.385,0,0,0-10.45,12.555,27.215,27.215,0,0,0-4.256,14.154l0,.094v5.74a5.38,5.38,0,0,0,5.58,5.58Z" fill="currentColor" fill-rule="evenodd"/></svg>
            <div>
                <p>{props.txt}</p>
            </div>
        </div>
    );
}

export default Infobox;