import { LazyLoadImage } from 'react-lazy-load-image-component';

import React, { useState, useEffect } from 'react';

import { // Components.
    Scroller
} from '../../components/components';


function Gallery(props: any) {
    const images = props.images;
    const alt = props.alt;

    const [currentSlide, setCurrentSlide] = useState<number>(0);
    const [currentSlideLoading, setCurrentSlideLoading] = useState<boolean>(true);

    useEffect(() => {
        setCurrentSlideLoading(true);
        if ((Object.keys(images).length - 1) < currentSlide) {
            setCurrentSlide(0);
        }
    }, [images]);

    return (
        <div className={"Gallery" + (props.className ? " " + props.className : "")}>
            <div className="Gallery-inner">
                {props.youtube ?
                    <div className={"Gallery-slide " + (currentSlide !== 0 ? "Gallery-slide-hidden" : "")}>
                        <iframe src={"https://www.youtube.com/embed/" + props.youtube + "?cc_lang_pref=de&cc_load_policy=1&color=white&hl=de&listType=user_uploads&modestbranding=1&origin=https://electricmagazin.com&rel=0&widget_referrer=https://electricmagazin.com"} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                    : null}

                {images ?
                    images.map((image: any, index: any) =>
                        <React.Fragment key={index}>
                            <div className={"Gallery-slide " + (currentSlide !== index + (props.youtube ? 1 : 0) ? "Gallery-slide-hidden" : "")}>
                                <LazyLoadImage
                                    height="100%"
                                    width="100%"
                                    effect="opacity"
                                    className={props.imgClassName}
                                    draggable="false"
                                    srcSet={
                                        (image.img_smaller ? image.img_smaller + " 640w, " : '') + (image.img_small ? image.img_small + " 990w, " : '') + (image.img_medium ? image.img_medium + " 1380w, " : '') + (image.img_large ? image.img_large + " 1920w" : '')}
                                    alt={alt ? alt : ''}
                                    onLoad={() => setTimeout(function () { setCurrentSlideLoading(false); }, 500)} />
                            </div>
                            {currentSlideLoading ?
                                props.youtube && currentSlide === 0 ?
                                    null
                                    : <div className="absolute">
                                        <svg className="h-2em spinner c-base" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path></svg>
                                    </div>
                                : null}
                        </React.Fragment>
                    )
                    : null}

                <button className="btn-onclick Gallery-prev" onClick={() => (images && currentSlide === 0 ? setCurrentSlide(images.length - (props.youtube ? 0 : 1)) : setCurrentSlide(currentSlide - 1))}>
                    <div className="Gallery-prev-inner">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path></svg>
                    </div>
                </button>
                <button className="btn-onclick Gallery-next" onClick={() => (images && currentSlide + (props.youtube ? 0 : 1) === images.length ? setCurrentSlide(0) : setCurrentSlide(currentSlide + 1))}>
                    <div className="Gallery-next-inner">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg>
                    </div>
                </button>
            </div>

            <div className="Gallery-thumbnails">
                <Scroller>
                    {props.youtube ?
                        <button
                            className="btn-onclick"
                            onClick={() => setCurrentSlide(0)}>
                            <div className="Gallery-thumbnail bg-black">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path></svg>
                            </div>
                        </button>
                        : null}

                    {images ?
                        images.map((image: any, index: any) =>
                            <React.Fragment key={index}>
                                <button
                                    className="btn-onclick"
                                    onClick={() => setCurrentSlide(index + (props.youtube ? 1 : 0))}>
                                    <div className="Gallery-thumbnail">
                                        <LazyLoadImage
                                            effect="opacity"
                                            srcSet={
                                                (image.img_smaller ? image.img_smaller + " 640w, " : '') + (image.img_small ? image.img_small + " 990w, " : '') + (image.img_medium ? image.img_medium + " 1380w, " : '') + (image.img_large ? image.img_large + " 1920w" : '')}
                                            alt={alt ? alt : ''} />
                                    </div>
                                </button>
                            </React.Fragment>
                        )
                        : null}
                </Scroller>
            </div>
        </div>
    );
}

export default Gallery;