import React, { useState, useEffect } from 'react';

import { // Components
    NormalItem, ReviewItem, TestimonialItem
} from '../components';

import { // Functions.
    countryUrl,
    scrollToId
} from '../../functions/functions';


function Feed(props: any) {
    // Pagination.
    const [paginationValues, setPaginationValues] = useState<any>(undefined);

    useEffect(() => {
        if (props.vehiclesCount && props.vehiclesCount > 12) {
            let carsCountPages = Math.ceil(props.vehiclesCount / 12);
            let carsCountArrayHelper = [];
            for (let i = 0; i < carsCountPages; i++) {
                carsCountArrayHelper.push(i * 12);
            }
            return setPaginationValues(carsCountArrayHelper);
        } else {
            setPaginationValues(undefined);
        }
    }, [props.vehiclesCount]);


    return (
        <div className={"Feed " + props.className}>
            {props.title ?
                <div className="mb-3">
                    <h2 className="mb-0-25">
                        {(props.count ? '(' + props.count.toString() + ') ' : '') + props.title}
                    </h2>
                    {props.subtitle ?
                        <p>{props.subtitle}</p>
                        : null}
                </div>
                : null}

            <div className="Feed-inner">
                {/* Normal item. */}
                {props.type === 'normal' ?
                    props.qs && props.qs.length ?
                        props.qs.map((car: any, index: number) =>
                            <React.Fragment key={index}>
                                <NormalItem
                                    car={car}
                                    localhost={props.localhost}
                                    loc={props.loc} country={props.country} inf={props.inf} influencer={props.influencer} />
                            </React.Fragment>)
                        : <React.StrictMode>
                            <NormalItem car={null} />
                            <NormalItem car={null} />
                            <NormalItem car={null} />
                        </React.StrictMode>
                    : null}

                {/* Review item. */}
                {props.type === 'review' ?
                    props.qs && props.qs.length ?
                        props.qs.map((review: any, index: number) =>
                            <React.Fragment key={index}>
                                <ReviewItem
                                    review={review}
                                    localhost={props.localhost}
                                    loc={props.loc} country={props.country} inf={props.inf} influencer={props.influencer} />
                            </React.Fragment>)
                        : <React.StrictMode>
                            <ReviewItem review={null} />
                            <ReviewItem review={null} />
                            <ReviewItem review={null} />
                        </React.StrictMode>
                    : null}

                {/* Testimonial item. */}
                {props.type === 'testimonial' ?
                    props.qs && props.qs.length ?
                        props.qs.map((testimonial: any, index: number) =>
                            <React.Fragment key={index}>
                                <TestimonialItem
                                    testimonial={testimonial}
                                    localhost={props.localhost}
                                    loc={props.loc} inf={props.inf} />
                            </React.Fragment>)
                        : <React.StrictMode>
                            <TestimonialItem review={null} />
                            <TestimonialItem review={null} />
                            <TestimonialItem review={null} />
                        </React.StrictMode>
                    : null}

                {/* Info item. */}
                {props.type === 'info' ?
                    props.children
                    : null}
            </div>

            {paginationValues ?
                <div>
                    <div className="hide display-lg"><div className="mb-3"></div></div>
                    <div className="Feed-pagination">
                        {paginationValues.map((paginationValue: any, index: number) =>
                            <React.Fragment key={index}>
                                <button
                                    className={"Feed-pagination-page " + (parseInt(props.urlParams.get('offset')) === paginationValue || !props.urlParams.get('offset') && paginationValue === 0 ? 'Feed-pagination-page-active' : '')}
                                    onClick={() => {
                                        scrollToId('top');
                                        props.urlParams.set('offset', paginationValue);
                                        props.setUrlParams(new URLSearchParams(props.urlParams.toString()));
                                    }}>
                                    {index + 1}
                                </button>
                            </React.Fragment>)}
                    </div>
                </div>
                : null}

            {props.button && props.qs ?
                <div>
                    <div className="mb-3 hide-lg"></div>
                    <div className="hide display-lg"><div className="mb-2"></div></div>

                    <div className="flex flex-jc">
                        {props.type === 'normal' ?
                            <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "pkw"}>
                                <button className="btn-underlined hide-med">
                                    Weitere Elektroautos entdecken
                                    <svg className="ml-0-25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg>
                                </button>
                                <button className="btn-underlined hide display-med">
                                    Alle Elektroautos
                                </button>
                            </a>
                            : null}
                        {props.type === 'review' ?
                            <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "bewertungen"}>
                                <button className="btn-underlined hide-med">
                                    Weitere Bewertungen entdecken
                                    <svg className="ml-0-25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg>
                                </button>
                                <button className="btn-underlined hide display-med">
                                    Alle Bewertungen
                                </button>
                            </a>
                            : null}
                        {props.type === 'testimonial' ?
                            <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "erfahrungsberichte"}>
                                <button className="btn-underlined hide-med">
                                    Weitere Erfahrungsberichte entdecken
                                    <svg className="ml-0-25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg>
                                </button>
                                <button className="btn-underlined hide display-med">
                                    Alle Erfahrungsberichte
                                </button>
                            </a>
                            : null}
                    </div>
                </div>
                : null}
        </div>
    );
}

export default Feed;