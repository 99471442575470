import { // Functions.
    countryUrl
} from '../../functions/functions';


function TestimonialBanner(props: any) {
    return (
        <div className={"Banner" + (props.className ? " " + props.className : '')}>
            <picture className="Banner-backdrop">
                <source
                    srcSet={(props.localhost ? '' : '/static') + "/media/covers/id3-charging-large.jpg"}
                    media="(min-width: 1380px)" />
                <source
                    srcSet={(props.localhost ? '' : '/static') + "/media/covers/id3-charging-medium.jpg"}
                    media="(min-width: 990px)" />
                <source
                    srcSet={(props.localhost ? '' : '/static') + "/media/covers/id3-charging-small.jpg"}
                    media="(min-width: 640px)" />
                <img
                    className="w-100 h-100"
                    srcSet={(props.localhost ? '' : '/static') + "/media/covers/id3-charging-smaller.jpg"}
                    alt="Sie besitzen bereits eines der aktuellen Elektroauto am Markt? Erstellen Sie einen Erfahrungsbericht und helfen Sie uns mehr Menschen zur Elektromobilität zu bewegen!" />
            </picture>
            <div className="Banner-darken"></div>
            <div className="Banner-content">
                <h5 className="mb-0-5">
                    Sie besitzen bereits eines der <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "pkw"}><u className="strong c-base">aktuellen Elektroautos</u></a> am Markt? Erstellen Sie einen Erfahrungsbericht und helfen Sie uns mehr Menschen zur Elektromobilität zu bewegen!
                </h5>
                <a href={props.user ? countryUrl({ loc: props.loc, inf: props.inf }) + "account/erfahrungsberichte" : countryUrl({ loc: props.loc, inf: props.inf }) + "anmelden?ziel=erfahrungsbericht"}>
                    <button className="btn-sm btn-outline btn-outline-base hide-med">Erfahrungsbericht erstellen</button>
                    <button className="btn-sm btn-outline btn-outline-base hide display-med">Jetzt erstellen</button>
                </a>
            </div>
        </div>
    );
}

export default TestimonialBanner;