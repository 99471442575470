import React, { useState, useEffect } from 'react';

import { // Components.
    Banner, TestimonialBanner,
    Feed,
    Filter, ActiveFilters,
    Nav,
    ScrollerBodyStyles,
    Wrapper
} from '../components/components';

import { // Functions.
    getManufacturers, getBodyStyles, getVehicles,
    getCookie,
    scrollToId
} from "../functions/functions";


function VehicleFeed(props: any) {
    useEffect(() => {
        if (props.urlParams) {
            var urlParamsLength = 0;
            props.filterUrlParamsKeys.forEach((param: string) => {
                props.urlParams.get(param) ? urlParamsLength = urlParamsLength + 1 : urlParamsLength = urlParamsLength
            });
            props.setFilterUrlParamsLength(urlParamsLength);
            props.setFilterPowerMin(props.urlParams.has('leistung-min') ? props.urlParams.get('leistung-min') : 0);
        }
    }, [props.urlParams]);

    // Api data.
    const [bodyStyles, setBodyStyles] = useState<any>(undefined);
    const [manufacturers, setManufacturers] = useState<any>(undefined);
    const [vehicles, setVehicles] = useState<any>(undefined);
    const [vehiclesCount, setVehiclesCount] = useState<number>(0);

    useEffect(() => {
        if (props.loc) {
            getVehicles({ loc: props.loc, setVehicles: setVehicles, setVehiclesCount: setVehiclesCount, setVehiclesFilters: props.setVehiclesFilters, urlParams: props.urlParams });
            getBodyStyles({ loc: props.loc, setBodyStyles: setBodyStyles, urlParams: props.urlParams });
            getManufacturers({ loc: props.loc, setManufacturers: setManufacturers, urlParams: props.urlParams });
        }
    }, [props.urlParams, props.loc]);


    return (
        <React.StrictMode>
            <div className="bg-prime w-100">
                <hr className="bg-base" />
                <div className="flex flex-ai-e h-15em">
                    <Wrapper>
                        <div className="mb-3">
                            <Filter
                                classNameOuter="c-base"
                                urlParams={props.urlParams} setUrlParams={props.setUrlParams} filterUrlParamsKeys={props.filterUrlParamsKeys} filterUrlParamsLength={props.filterUrlParamsLength} filterPowerMin={props.filterPowerMin} setFilterPowerMin={props.setFilterPowerMin}
                                bodyStyles={bodyStyles} manufacturers={manufacturers} vehiclesFilters={props.vehiclesFilters} vehiclesCount={vehiclesCount}
                                title={props.filterUrlParamsLength && props.filterUrlParamsLength > 0 ?
                                    "Alle passenden Elektroautos" : "Alle Elektroautos"}
                                setNotification={props.setNotification} />
                        </div>
                    </Wrapper>
                </div>
            </div>


            <div className="Cover-new-gradient">
                <ScrollerBodyStyles
                    urlParams={props.urlParams} setUrlParams={props.setUrlParams}
                    bodyStyles={bodyStyles} />
            </div>
            <hr className="mb-3" />


            <Wrapper>
                <div className="mb-3">
                    <ActiveFilters
                        urlParams={props.urlParams} setUrlParams={props.setUrlParams}
                        vehiclesFilters={props.vehiclesFilters} setFilterPowerMin={props.setFilterPowerMin} filterUrlParamsLength={props.filterUrlParamsLength} filterUrlParamsKeys={props.filterUrlParamsKeys} />
                </div>


                {vehicles && vehiclesCount === 0 ?
                    <div>
                        <div className="mb-5 hide-lg"></div>
                        <div className="hide display-lg"><div className="mb-3"></div></div>
                        <div className="flex flex-jc op-50">
                            <h2>Keine Elektroautos gefunden!</h2>
                        </div>
                    </div>
                    : <Feed
                        type="normal"
                        urlParams={props.urlParams} setUrlParams={props.setUrlParams}
                        qs={vehicles} vehiclesCount={vehiclesCount} localhost={props.localhost}
                        loc={props.loc} country={props.country} inf={props.inf} influencer={props.influencer} />}


                <div className="mb-5 hide-lg"></div>
                <div className="hide display-lg"><div className="mb-3"></div></div>


                {props.country && props.country.promotions.length ?
                    <Banner country={props.country} localhost={props.localhost} />
                    : null}


                <div className="mb-5 hide-lg"></div>
                <div className="hide display-lg"><div className="mb-3"></div></div>


                <Nav
                    header={props.filterUrlParamsLength && props.filterUrlParamsLength > 0 ?
                        (props.filterUrlParamsLength === 1 && props.urlParams.has('hersteller') ? "Alle Marken" : "Alle passenden Marken") : "Alle Marken"}
                    type="horizontal" columns="5" limit="8"
                    urlParams={props.urlParams} setUrlParams={props.setUrlParams}
                    items={manufacturers}
                    onClick={(item: any) => {
                        scrollToId('top');
                        props.urlParams.set('hersteller', item.slug);
                        props.setUrlParams(new URLSearchParams(props.urlParams.toString()));
                    }} />


                <div className="mb-5 hide-lg"></div>
                <div className="hide display-lg"><div className="mb-3"></div></div>


                <Nav
                    header={props.filterUrlParamsLength && props.filterUrlParamsLength > 0 ?
                        (props.filterUrlParamsLength === 1 && props.urlParams.has('karosserieformen') ? "Alle Karosserieformen" : "Alle passenden Karosserieformen") : "Alle Karosserieformen"}
                    type="horizontal" columns="5"
                    urlParams={props.urlParams} setUrlParams={props.setUrlParams}
                    items={bodyStyles}
                    onClick={(item: any) => {
                        scrollToId('top');
                        props.urlParams.set('karosserieformen', item.slug + ',');
                        props.setUrlParams(new URLSearchParams(props.urlParams.toString()));
                    }} />


                <div className="mb-5 hide-lg"></div>
                <div className="hide display-lg"><div className="mb-3"></div></div>


                <div>
                    <TestimonialBanner localhost={props.localhost} loc={props.loc} inf={props.inf} user={props.user} />
                </div>


                <div className="mb-5 hide-lg"></div>
                <div className="hide display-lg"><div className="mb-3"></div></div>
            </Wrapper>
        </React.StrictMode >
    );
}

export default VehicleFeed;