import { axiosInstance, axiosInstanceJWT, axiosLocalErrorHandling } from '../axios';


export const getInfluencer = (props: any) => {
    axiosInstanceJWT.get('backend/influencer/current/')
        .then(function (response: any) {
            if (response && response.data && props.setInfluencer) {
                props.setInfluencer(response.data);
            }
        })
        .catch(function (error: any) {
            axiosLocalErrorHandling({ error: error, function: "getInfluencer" });
        })
}


export const getInfluencerFromUrl = (props: any) => {
    if (props.windowPathname) {
        axiosInstance.get('influencer/')
            .then(function (response: any) {
                if (response && response.data) {
                    var infSet = false;
                    response.data.forEach((influencer: any) => {
                        if (props.windowPathname.includes(influencer.slug)) {
                            localStorage.setItem('inf', influencer.slug);
                            infSet = true;
                            if (props.setInfluencer) {
                                props.setInfluencer(influencer);
                            }
                            if (props.setBus) {
                                props.setBus(influencer.slug);
                            }
                        }
                    });

                    if (!infSet && localStorage.getItem('inf')) {
                        response.data.forEach((influencer: any) => {
                            if (localStorage.getItem('inf') === influencer.slug) {
                                if (props.setInfluencer) {
                                    props.setInfluencer(influencer);
                                }
                                if (props.setBus) {
                                    props.setBus(influencer.slug);
                                }
                            }
                        });
                    }
                }
            })
            .catch(function (error: any) {
                axiosLocalErrorHandling({ error: error, function: "getInfluencerFromUrl" });
            })
    }
}