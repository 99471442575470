import { LazyLoadImage } from 'react-lazy-load-image-component';

import React, { useState } from 'react';

import { // Functions.
    countryUrl,
    generateRandomID,
    postVehiclePopularity
} from '../../../../functions/functions';


function Dropdown(props: any) {
    const localhost = props.localhost;
    const loc = props.loc
    const inf = props.inf;

    // API data.
    const objects = props.objects;
    const subObjects = props.subObjects;

    // Dropdown logic.
    const [idDownManufacturers] = useState<string>(() => generateRandomID());
    const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
    const [activeDropdown, setActiveDropdown] = useState<number>(0);
    const [activeDropdownDropout, setActiveDropdownDropout] = useState<number>(0);

    function scrollDownManufacturers() {
        if (idDownManufacturers) {
            const scrollerInner: any = document.getElementById(idDownManufacturers);
            if (scrollerInner) {
                scrollerInner.scrollBy({ top: 380, behavior: 'smooth' });
            }
        }
    }


    return (
        <div className="Dropdown flex"
            onMouseEnter={() => {
                setDropdownVisible(true);
                // document.body.classList.add('no-scroll');
            }}
            onMouseLeave={() => {
                setDropdownVisible(false);
                // document.body.classList.remove('no-scroll');
            }}>
            <a href={countryUrl({ loc: loc, inf: inf }) + "pkw"}>
                <strong>Elektroautos</strong>
            </a>


            {objects && objects.length > 0 && dropdownVisible ?
                <div className="flex flex-ai-s Dropdown-dropdown-wrapper">
                    <div className="relative h-100">
                        <div className="Dropdown-dropdown" id={idDownManufacturers}>
                            <ul>
                                {objects.map((object: any, index: number) =>
                                    <React.Fragment key={index}>
                                        <li id={"dropdownElem" + index.toString()} className={"Dropdown-dropout-elem " + (index === activeDropdown ? 'active' : '')}
                                            onMouseEnter={() => {
                                                setActiveDropdown(index);
                                                setActiveDropdownDropout(0);
                                            }}>
                                            <a className="w-100"
                                                href={object.slug ?
                                                    countryUrl({ loc: loc, inf: inf }) + 'pkw/?hersteller=' + object.slug
                                                    : countryUrl({ loc: loc, inf: inf }) + 'pkw/'}>
                                                <button className="btn-onclick padding-sm w-100">
                                                    {object.logo ?
                                                        <img
                                                            src={object.logo}
                                                            alt={object.name + " Logo"} />
                                                        : null}
                                                    {object.name}
                                                </button>
                                            </a>
                                        </li>
                                    </React.Fragment>
                                )}
                            </ul>

                            {objects && objects.length > 9 ?
                                <button
                                    className="btn-onclick Dropdown-dropdown-nav"
                                    onClick={() => scrollDownManufacturers()}>
                                    <div className="Dropdown-dropdown-nav-inner">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path></svg>
                                    </div>
                                </button>
                                : null}
                        </div>
                    </div>


                    {subObjects ?
                        <React.StrictMode>
                            {objects.map((object: any, index: number) =>
                                <React.Fragment key={index}>
                                    <div className={"Dropdown-dropdown-dropout " + (index === activeDropdown ? '' : 'hide')}>
                                        <ul>
                                            {subObjects.filter((subObject: any) => subObject.manufacturer.slug === object.slug).map((subObject: any, subIndex: number) =>
                                                <React.Fragment key={subIndex}>
                                                    <li className={(index === activeDropdown && activeDropdownDropout === subIndex ? 'active' : '')}
                                                        onMouseEnter={() => setActiveDropdownDropout(subIndex)}>
                                                        <a
                                                            className="w-100"
                                                            href={subObject ?
                                                                countryUrl({ loc: loc, inf: inf }) + `pkw/${subObject.manufacturer.slug}/${subObject.slug}` : ''}
                                                            onMouseDown={(e: any) => {
                                                                e.stopPropagation();
                                                                if (subObject && subObject.meta_data) {
                                                                    postVehiclePopularity({
                                                                        meta_data: subObject.meta_data,
                                                                        type: 1,
                                                                        country: props.country ? props.country.id : undefined,
                                                                        influencer: props.influencer ? props.influencer.id : undefined,
                                                                    });
                                                                }
                                                            }}>
                                                            <button className="btn-onclick padding-sm w-100">
                                                                {subObject.name}
                                                            </button>
                                                        </a>
                                                    </li>
                                                </React.Fragment>
                                            )}
                                        </ul>
                                    </div>


                                    {subObjects.filter((subObject: any) => subObject.manufacturer.slug === object.slug).map((subObject: any, subIndex: number) =>
                                        <React.Fragment key={subIndex}>
                                            <div className={"Dropdown-dropdown-dropout-dropout " + (index === activeDropdown && subIndex === activeDropdownDropout ? '' : 'hide')}>
                                                <LazyLoadImage
                                                    effect="opacity"
                                                    src={localhost ? "/media/backdrops/asphalt-small.png" : "/static/media/backdrops/asphalt-small.png"}
                                                    alt="Elektroauto Asphalt Hintergrund" />
                                                <LazyLoadImage
                                                    effect="opacity"
                                                    src={subObject.thumbnail.img_small}
                                                    alt={subObject ? object.name + ' ' + subObject.name + ' Elektroauto' : ''} />
                                                <div className="Dropdown-dropdown-dropout-dropout-content w-100">
                                                    <div className="w-100 flex">
                                                        <div className="w-100 flex flex-jc">
                                                            <div>
                                                                <h4 className="">ab € {subObject.price_min ? subObject.price_min.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : ''}</h4>
                                                                <h6>Preis</h6>
                                                            </div>
                                                        </div>
                                                        <div className="c-txt op-50">
                                                            <svg className="h-3em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 100.696"><path d="M2405.564,657.022v96.7" transform="translate(-2403.564 -655.022)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="3" /></svg>
                                                        </div>
                                                        <div className="w-100 flex flex-jc">
                                                            <div>
                                                                <h4 className="">{subObject.wltp_max ? (subObject.wltp_min && subObject.wltp_min !== subObject.wltp_max ? subObject.wltp_min + ' bis ' : 'bis zu ') + subObject.wltp_max : ''} km</h4>
                                                                <h6>Reichweite</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-100 flex flex-jc mt-1">
                                                        <a
                                                            href={countryUrl({ loc: loc, inf: inf }) + subObject.href_compare}
                                                            onMouseDown={(e: any) => {
                                                                e.stopPropagation();
                                                                if (subObject && subObject.meta_data) {
                                                                    postVehiclePopularity({
                                                                        meta_data: subObject.meta_data,
                                                                        type: 2,
                                                                        country: props.country ? props.country.id : undefined,
                                                                        influencer: props.influencer ? props.influencer.id : undefined,
                                                                    });
                                                                }
                                                            }}>
                                                            <button className="btn-sm mr-2 ml-3">
                                                                Vergleichen
                                                            </button>
                                                        </a>
                                                        <a
                                                            href={countryUrl({ loc: loc, inf: inf }) + subObject.href}
                                                            onMouseDown={(e: any) => {
                                                                e.stopPropagation();
                                                                if (subObject && subObject.meta_data) {
                                                                    postVehiclePopularity({
                                                                        meta_data: subObject.meta_data,
                                                                        type: 1,
                                                                        country: props.country ? props.country.id : undefined,
                                                                        influencer: props.influencer ? props.influencer.id : undefined,
                                                                    });
                                                                }
                                                            }}>
                                                            <button className="btn-sm btn-outline mr-2">
                                                                Zum Fahrzeug
                                                            </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                        </React.StrictMode>
                        : null}
                </div>
                : null}
        </div>
    );
}

export default Dropdown;