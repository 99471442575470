import { Helmet } from 'react-helmet';

import React, { useState, useEffect } from 'react';

import { // Components.
    Feed,
    Wrapper
} from '../components/components';

import { // Functions.
    countryUrl, 
    getTestimonialsBackend
} from '../functions/functions';


function SettingsFrontendTestimonials(props: any) {
    // Api data.
    const [testimonials, setTestimonials] = useState<any>(undefined);

    useEffect(() => {
        getTestimonialsBackend({ setTestimonials: setTestimonials });
    }, [props.windowPathname]);


    return (
        <React.StrictMode>
            <Helmet>
                <title>
                    Erfahrungsberichte erstellen und bearbeiten | electricmagazin.com
                </title>
                <meta
                    name="description"
                    content={"Erstellen und bearbeiten Sie Ihre Erfahrungsberichte."} />
            </Helmet>

            <Wrapper className="h-min-50vh">
                <div className="mt-2">
                    <div className="mb-2">
                        <h3>Erfahrungsberichte</h3>
                        <p className="op-50">
                            Erstellen und bearbeiten Sie Erfahrungsberichte. Erfahrungsberichte können nur veröffentlicht werden wenn Sie das jeweilige Fahrzeug mindestens einen Monat lang im Alltag gefahren haben und dafür ein Amtliches Dokument besitzen.
                        </p>
                    </div>

                    <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "account/erfahrungsberichte/erstellen"}>
                        <div className="w-100 w-border-box h-5em flex flex-jc c-prime border border-dashed border-radius">
                            <svg className="w-1-5em h-1-5em mr-0-5 c-prime flex-noshrink" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.4 194.4"><path d="M97.2,0a97.2,97.2,0,1,0,97.2,97.2A97.246,97.246,0,0,0,97.2,0Zm0,16.663A80.537,80.537,0,1,1,16.663,97.2,80.575,80.575,0,0,1,97.2,16.663ZM92.534,138.724a3.718,3.718,0,0,1-3.732-3.733V104.665H59.1a3.718,3.718,0,0,1-3.733-3.733v-8.4A3.718,3.718,0,0,1,59.1,88.8H88.8V59.409a3.534,3.534,0,0,1,1.089-2.722,3.742,3.742,0,0,1,2.643-1.011h9.332a3.484,3.484,0,0,1,3.732,3.733V88.8h29.7a3.718,3.718,0,0,1,3.733,3.732v8.4a3.743,3.743,0,0,1-1.011,2.644,3.534,3.534,0,0,1-2.722,1.089H105.6v30.326a3.745,3.745,0,0,1-1.011,2.644,3.533,3.533,0,0,1-2.721,1.089Z" fill="currentColor" /></svg>
                            <h5 className="hide-sm">Neuen Erfahrungsbericht erstellen</h5>
                            <h5 className="hide display-sm">Neuen Bericht</h5>
                        </div>
                    </a>
                </div>


                <div className="mb-5 hide-lg" id="bewertung"></div>
                <div className="hide display-lg" id="bewertung"><div className="mb-3"></div></div>


                {testimonials && testimonials.offline && testimonials.offline.count && testimonials.offline.count > 0 ? 
                    <div>
                        <div className="mb-2">
                            <h3 className="mb-0-25">
                                ({testimonials.offline.count}) 
                                Offline Erfahrungsbericht{testimonials.offline.count > 1 ? "e" : ""}
                            </h3>
                        </div>
                        
                        <Feed
                            type="testimonial" localhost={props.localhost}
                            qs={testimonials.offline.testimonials ? testimonials.offline.testimonials : undefined} />

                        <div className="mb-5 hide-lg" id="bewertung"></div>
                        <div className="hide display-lg" id="bewertung"><div className="mb-3"></div></div>
                    </div>
                    : null}

                {testimonials && testimonials.online && testimonials.online.count && testimonials.online.count > 0 ? 
                    <div>
                        <div className="mb-2">
                            <h3>
                                ({testimonials.online.count}) 
                                Online Erfahrungsbericht{testimonials.online.count > 1 ? "e" : ""}
                            </h3>
                        </div>
                        
                        <Feed
                            type="testimonial" localhost={props.localhost}
                            qs={testimonials.online.testimonials ? testimonials.online.testimonials : undefined} />

                        <div className="mb-5 hide-lg" id="bewertung"></div>
                        <div className="hide display-lg" id="bewertung"><div className="mb-3"></div></div>
                    </div>
                    : null}
            </Wrapper>
        </React.StrictMode>
    );
}

export default SettingsFrontendTestimonials;