import { axiosInstance, axiosInstanceJWT, axiosLocalErrorHandling } from '../axios';

import { // Functions.
    countryUrl,
} from '../../functions';


// Multiple testimonials backend.
export const getTestimonialsBackend = (props: any) => {
    axiosInstanceJWT.get('backend/testimonials/')
        .then(function (response: any) {
            if (response.data && props.setTestimonials) {
                props.setTestimonials(response.data);
            }
        })
        .catch(function (error: any) {
            axiosLocalErrorHandling({ error: error, function: "getTestimonialsBackend" });
        })
}


// Single testimonial backend.
export const getTestimonialBackend = (props: any) => {
    if (props.testimonialId) {
        axiosInstanceJWT.get('backend/testimonials/' + props.testimonialId + '/')
            .then(function (response: any) {
                // console.log(response)
                if (response.data && props.setTestimonial) {
                    props.setTestimonial(response.data);
                }
            })
            .catch(function (error: any) {
                if (error.response && error.response.status && error.response.status === 500) {
                    window.location.pathname = countryUrl({ loc: props.loc, inf: props.inf }) + "404";
                } else {
                    axiosLocalErrorHandling({ error: error, function: "getTestimonialBackend" });
                }
            })
    }
}


// Multiple testimonials frontend.
export const getTestimonialsFrontend = (props: any) => {
    var filter: any = {};

    if (props.loc) {  // Country.
        filter.country = props.loc;
    }

    if (props.limit) {  // Limit.
        filter.limit = props.limit;
        filter.offset = 0;
    }

    axiosInstance.get('testimonials/', {
        params: filter
    })
        .then(function (response: any) {
            if (response.data && props.setTestimonials) {
                props.setTestimonials(response.data);
            }
        })
        .catch(function (error: any) {
            axiosLocalErrorHandling({ error: error, function: "getTestimonialsFrontend" });
        })
}

export const getTestimonialsCount = (props: any) => {
    var filter: any = {};
    if (props.loc) { filter.country = props.loc; }

    axiosInstance.get('testimonials/count/', {
        params: filter
    })
        .then(function (response: any) {
            if (response.data && response.data.count && props.setTestimonialsCount) {
                props.setTestimonialsCount(response.data.count);
            }
        })
        .catch(function (error: any) {
            axiosLocalErrorHandling({ error: error, function: "getTestimonialsCount" });
        })
}


// Single testimonial frontend.
export const getTestimonialFrontend = (props: any) => {
    if (props.testimonial) {
        var filter: any = {};

        if (props.loc) {  // Country.
            filter.country = props.loc;
        }

        axiosInstance.get('testimonials/' + props.testimonial + '/', {
            params: filter
        })
            .then(function (response: any) {
                if (response.data && props.setTestimonial) {
                    props.setTestimonial(response.data);
                }
            })
            .catch(function (error: any) {
                if (error.response && error.response.status && error.response.status === 500) {
                    window.location.pathname = countryUrl({ loc: props.loc, inf: props.inf }) + "404";
                } else {
                    axiosLocalErrorHandling({ error: error, function: "getRegetTestimonialFrontendiews" });
                }
            })
    }
}