import React, { useState, useEffect } from 'react';

import { // Components.
    Banner, TestimonialBanner,
    Feed,
    Nav,
    Wrapper
} from '../components/components';

import { // Functions.
    getManufacturers, getBodyStyles, getTestimonialsFrontend, getTestimonialsCount,
    getCookie
} from "../functions/functions";


function TestimonialFeed(props: any) {
    // Api data.
    const [allBodyStyles, setAllBodyStyles] = useState<any>(undefined);
    const [allManufacturers, setAllManufacturers] = useState<any>(undefined);
    const [testimonials, setTestimonials] = useState<number>(0);
    const [testimonialsCount, setTestimonialsCount] = useState<number>(0);

    useEffect(() => {
        if (getCookie('loc')) {
            getTestimonialsFrontend({ loc: props.loc, setTestimonials: setTestimonials, order: "popularity", });
            getTestimonialsCount({ loc: props.loc, setTestimonialsCount: setTestimonialsCount });
            getBodyStyles({ loc: props.loc, setBodyStyles: setAllBodyStyles, });
            getManufacturers({ loc: props.loc, setManufacturers: setAllManufacturers, });
        }
    }, [props.loc]);


    return (
        <React.StrictMode>
            <div className="bg-prime w-100 mb-3">
                <hr className="bg-base" />
                <div className="flex flex-ai-e h-15em">
                    <Wrapper>
                        <div className="mb-3">
                            <h2 className="c-base">
                                {testimonialsCount ? "(" + testimonialsCount + ") " : ""} Aktuelle Erfahrungsberichte
                            </h2>
                        </div>
                    </Wrapper>
                </div>
            </div>

            <Wrapper>
                {testimonials && testimonialsCount === 0 ?
                    <div>
                        <div className="mb-5 hide-lg"></div>
                        <div className="hide display-lg"><div className="mb-3"></div></div>
                        <div className="flex flex-jc op-50">
                            <h2>Keine Erfahrungsberichte gefunden!</h2>
                        </div>
                    </div>
                    : <Feed
                        type="testimonial" qs={testimonials}
                        localhost={props.localhost}
                        loc={props.loc} inf={props.inf} />}


                <div className="mb-5 hide-lg"></div>
                <div className="hide display-lg"><div className="mb-3"></div></div>


                {/* Current promotions. */}
                {props.country && props.country.promotions && props.country.promotions.length ?
                    <Banner country={props.country} localhost={props.localhost} />
                    : null}


                <div className="mb-5 hide-lg"></div>
                <div className="hide display-lg"><div className="mb-3"></div></div>


                {/* All manufacturers. */}
                <Nav
                    header="Alle Marken"
                    type="horizontal" columns="5" limit="8"
                    items={allManufacturers} href="pkw?hersteller="
                    loc={props.loc} inf={props.inf} />


                <div className="mb-5 hide-lg"></div>
                <div className="hide display-lg"><div className="mb-3"></div></div>


                {/* All body-styles. */}
                <Nav
                    header="Alle Karosserieformen"
                    type="horizontal" columns="5"
                    items={allBodyStyles} href="pkw?karosserieform="
                    loc={props.loc} inf={props.inf} />


                <div className="mb-5 hide-lg"></div>
                <div className="hide display-lg"><div className="mb-3"></div></div>


                <div>
                    <TestimonialBanner localhost={props.localhost} loc={props.loc} inf={props.inf} user={props.user} />
                </div>


                <div className="mb-5 hide-lg"></div>
                <div className="hide display-lg"><div className="mb-3"></div></div>
            </Wrapper>
        </React.StrictMode >
    );
}

export default TestimonialFeed;