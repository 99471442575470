function Toggle(props: any) {
    return (
        <div className={"flex" + (props.className ? ' ' + props.className : '')}>
            <label>
                <input id={props.inputId} type="checkbox" name={props.name} defaultChecked={props.defaultChecked} onChange={props.onChange} />
                <div className="toggle">
                    <div></div>
                </div>
            </label>
            <div className="w-100"></div>
        </div>
    );
}

export default Toggle;