import { axiosInstance, axiosLocalErrorHandling } from '../axios';


export const postInfluencerApplicant = (props: any) => {
    let jsonData: any = {}
    var errorCount: number = 0

    // Name.
    const influencerNameNotify: any = document.getElementById('influencerNameNotify');
    const influencerNameInput: any = document.getElementById('influencerName');
    const influencerName = (influencerNameInput && influencerNameInput.value ? influencerNameInput.value : '');
    if (influencerName) {
        jsonData.name = influencerName;
        influencerNameNotify.classList.add("btn-info-placeholder");
        influencerNameNotify.classList.remove("btn-info-second");
        influencerNameNotify.innerHTML = "";
    } else {
        errorCount += 1
        influencerNameNotify.classList.remove("btn-info-placeholder");
        influencerNameNotify.classList.add("btn-info-second");
        influencerNameNotify.innerHTML = "Bitte ausfüllen!"
    }

    // Link 1.
    const influencerLink1Notify: any = document.getElementById('influencerLink1Notify');
    const influencerLink1Input: any = document.getElementById('influencerLink1');
    const influencerLink1 = (influencerLink1Input && influencerLink1Input.value ? influencerLink1Input.value : '');
    if (influencerLink1 && new RegExp('^(https?:\\/\\/)?' + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + '((\\d{1,3}\\.){3}\\d{1,3}))' + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + '(\\?[;&a-z\\d%_.~+=-]*)?' + '(\\#[-a-z\\d_]*)?$', 'i').test(influencerLink1)) {
        jsonData.link_1 = influencerLink1;
        influencerLink1Notify.classList.add("btn-info-placeholder");
        influencerLink1Notify.classList.remove("btn-info-second");
        influencerLink1Notify.innerHTML = "";
    } else if (influencerLink1 && !new RegExp('^(https?:\\/\\/)?' + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + '((\\d{1,3}\\.){3}\\d{1,3}))' + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + '(\\?[;&a-z\\d%_.~+=-]*)?' + '(\\#[-a-z\\d_]*)?$', 'i').test(influencerLink1)) {
        influencerLink1Notify.classList.remove("btn-info-placeholder");
        influencerLink1Notify.classList.add("btn-info-second");
        influencerLink1Notify.innerHTML = "Bitte korrekte URL eingeben!"
    } else {
        errorCount += 1
        influencerLink1Notify.classList.remove("btn-info-placeholder");
        influencerLink1Notify.classList.add("btn-info-second");
        influencerLink1Notify.innerHTML = "Bitte ausfüllen!"
    }

    // Link 2.
    const influencerLink2Notify: any = document.getElementById('influencerLink2Notify');
    const influencerLink2Input: any = document.getElementById('influencerLink2');
    const influencerLink2 = (influencerLink2Input && influencerLink2Input.value ? influencerLink2Input.value : '');
    if (influencerLink2 && new RegExp('^(https?:\\/\\/)?' + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + '((\\d{1,3}\\.){3}\\d{1,3}))' + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + '(\\?[;&a-z\\d%_.~+=-]*)?' + '(\\#[-a-z\\d_]*)?$', 'i').test(influencerLink2)) {
        jsonData.link_2 = influencerLink2;
        influencerLink2Notify.classList.add("btn-info-placeholder");
        influencerLink2Notify.classList.remove("btn-info-second");
        influencerLink2Notify.innerHTML = "";
    } else if (influencerLink2 && !new RegExp('^(https?:\\/\\/)?' + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + '((\\d{1,3}\\.){3}\\d{1,3}))' + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + '(\\?[;&a-z\\d%_.~+=-]*)?' + '(\\#[-a-z\\d_]*)?$', 'i').test(influencerLink2)) {
        influencerLink2Notify.classList.remove("btn-info-placeholder");
        influencerLink2Notify.classList.add("btn-info-second");
        influencerLink2Notify.innerHTML = "Bitte korrekte URL eingeben!"
    }

    // E-mail.
    const influencerEmailNotify: any = document.getElementById('influencerEmailNotify');
    const influencerEmailInput: any = document.getElementById('influencerEmail');
    const dataEmail = (influencerEmailInput && influencerEmailInput.value ? influencerEmailInput.value : '');
    if (dataEmail && new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i).test(dataEmail)) {
        jsonData.email = dataEmail;
        influencerEmailNotify.classList.remove("btn-info-second");
        influencerEmailNotify.classList.add("btn-info-placeholder");
        influencerEmailNotify.innerHTML = ""
    } else if (dataEmail && !new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i).test(dataEmail)) {
        influencerEmailNotify.classList.remove("btn-info-placeholder");
        influencerEmailNotify.classList.add("btn-info-second");
        influencerEmailNotify.innerHTML = "Bitte korrekte E-Mail eingeben!"
    } else {
        influencerEmailNotify.classList.remove("btn-info-placeholder");
        influencerEmailNotify.classList.add("btn-info-second");
        influencerEmailNotify.innerHTML = "Bitte ausfüllen!"
    }

    // Phone.
    const influencerPhoneNotify: any = document.getElementById('influencerPhoneNotify');
    const influencerPhoneInput: any = document.getElementById('influencerPhone');
    const influencerPhone = (influencerPhoneInput && influencerPhoneInput.value ? influencerPhoneInput.value : '');
    if (influencerPhone) {
        jsonData.phone = influencerPhone;
        influencerPhoneNotify.classList.add("btn-info-placeholder");
        influencerPhoneNotify.classList.remove("btn-info-second");
        influencerPhoneNotify.innerHTML = "";
    } else {
        errorCount += 1
        influencerPhoneNotify.classList.remove("btn-info-placeholder");
        influencerPhoneNotify.classList.add("btn-info-second");
        influencerPhoneNotify.innerHTML = "Bitte ausfüllen!"
    }

    // Referrer text.
    const influencerReferrerNotify: any = document.getElementById('influencerReferrerNotify');
    const influencerReferrerInput: any = document.getElementById('influencerReferrer');
    const influencerReferrer = (influencerReferrerInput && influencerReferrerInput.value ? influencerReferrerInput.value : '');
    if (influencerReferrer) {
        jsonData.referrer_text = influencerReferrer;
        influencerReferrerNotify.classList.add("btn-info-placeholder");
        influencerReferrerNotify.classList.remove("btn-info-second");
        influencerReferrerNotify.innerHTML = "";
    } else {
        errorCount += 1
        influencerReferrerNotify.classList.remove("btn-info-placeholder");
        influencerReferrerNotify.classList.add("btn-info-second");
        influencerReferrerNotify.innerHTML = "Bitte ausfüllen!"
    }

    // Referrer (influencer).
    const influencerInfluencerInput: any = document.getElementById('influencerInfluencer');
    const influencerInfluencer = (influencerInfluencerInput && influencerInfluencerInput.value ? influencerInfluencerInput.value : '');
    if (influencerInfluencer) {
        jsonData.referrer = influencerInfluencer;
    }
    
    if (errorCount === 0 && Object.keys(jsonData).length > 0) {
        axiosInstance.post('backend/influencer-application/create/', 
            jsonData, { 
                headers: {
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                }
            })
        .then(function (response: any) {
            if (props.setNotification) { props.setNotification("Vielen Dank! Wir haben Ihre Bewerbung erhalten."); }
            if (props.setInfluencerName) { props.setInfluencerName(''); }
            if (props.setInfluencerLink1) { props.setInfluencerLink1(''); }
            if (props.setInfluencerLink2) { props.setInfluencerLink2(''); } 
            if (props.setInfluencerEmail) { props.setInfluencerEmail(''); } 
            if (props.setInfluencerPhone) { props.setInfluencerPhone(''); }
            if (props.setInfluencerReferrer) { props.setInfluencerReferrer(''); }
            if (props.setApplicationOpen) { props.setApplicationOpen(false); }
        })
        .catch(function (error: any) {
            if (props.setNotification) { props.setNotification("Ups, bitte versuche es nocheinmal!"); }
            axiosLocalErrorHandling({ error: error, function: "postInfluencerApplicant" });
        })
    }
}