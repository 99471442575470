import { axiosInstanceJWT, axiosLocalErrorHandling } from '../axios';


export const postIpBlacklist = () => {
    axiosInstanceJWT.post('backend/ipblacklist/create/',
        {}, {
        headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
        }
    })
        .catch(function (error: any) {
            axiosLocalErrorHandling({ error: error, function: "postIpBlacklist" });
        })
}