import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import React, { useState, useEffect } from 'react';

import { // Components.
    Cookies,
    Header, Footer,
    Notify
} from './components/components';

import { // Pages.
    Compare,
    VehicleFeed, Detail,
    ReviewFeed, ReviewDetail,
    Home,
    Login,
    NoMatch,
    Register,
    Imprint, PrivacyPolicy, TermsOfUse,
    LandingpageBusiness, LandingpageInfluencer,
    TestimonialDetail, TestimonialFeed,
    SettingsFrontend, SettingsFrontendChangePassword, SettingsFrontendPersonalData, SettingsFrontendTestimonials, SettingsFrontendTestimonialsCreateEdit
} from './pages/pages';

import { // Functions.
    setCookie, getCookie,
    getCountry, getCountries, getInfluencerFromUrl,
    getUser,
    getManufacturers, getVehicles,
    getReviewsCount, getTestimonialsCount, getBodyStyles
} from "./functions/functions";


function App() {
    // Global vars.
    const [windowPathname, setWindowPathname] = useState<string>(window.location.pathname.toLowerCase());
    const [localhost] = useState<boolean>(window.location.hostname.includes("localhost") ? true : false);

    // User authentication.
    const [user, setUser] = useState<any>(undefined);

    // Influencer logic.
    const [influencer, setInfluencer] = useState<any>(undefined);
    const [inf, setBus] = useState<any>(localStorage.getItem('inf') ? localStorage.getItem('inf') : '');

    useEffect(() => {
        getInfluencerFromUrl({ windowPathname: windowPathname, setInfluencer: setInfluencer, setBus: setBus });
        if (localStorage.getItem('access_token')) {
            getUser({ setUser: setUser });
        }
    }, [windowPathname]);


    // Location logic.
    const [locales] = useState<any>(['at', 'de']);
    const [loc, setLoc] = useState<string>(
        locales.find((loc: any) => windowPathname.includes(loc.toLowerCase())) ?
            locales.find((loc: any) => windowPathname.includes(loc.toLowerCase()))
            : (getCookie('loc') && getCookie('loc') !== 'undefined' ? getCookie('loc') : '')
    );
    const [country, setCountry] = useState<any>(undefined);
    const [countries, setCountries] = useState<any>(undefined);

    useEffect(() => {
        // if (!windowPathname.includes('dashboard')) {
        var windowPathnameNew = '';
        var windowPathnameHelper = windowPathname.split("/").filter((e: any) => e);

        if (!loc) {
            if (getCookie('loc') !== 'undefined') {
                var xmlHttp = new XMLHttpRequest();
                xmlHttp.open("GET", "https://www.cloudflare.com/cdn-cgi/trace", false);
                xmlHttp.send(null);
                const ipData = xmlHttp.responseText.split('\n');
                const loc = ipData.find((data: any) => data.startsWith('loc'))?.split('=')[1].toLowerCase();
                if (locales.indexOf(loc) > -1) {
                    window.history.replaceState({}, '', loc + windowPathname + (urlParams.toString().length > 0 ? '?' : '') + urlParams.toString());
                    setWindowPathname(loc + windowPathname);
                    setCookie({ name: 'loc', value: loc });
                    setLoc(loc ? loc : '');
                    /* if (loc === 'de') {
                        setNotification('Sie sind nun auf der 🇩🇪 deutschen Seite');
                    } else if (loc === 'at') {
                        setNotification('Sie sind nun auf der 🇦🇹 österreichischen Seite');
                    } */
                } else {
                    setCookie({ name: 'loc', value: 'undefined' });
                }
            } else {
                if (windowPathname.includes(inf)) {
                    windowPathnameHelper = windowPathnameHelper.filter((e: any) => e !== inf);
                }
                if (inf) {
                    windowPathnameNew = windowPathnameNew + '/' + inf;
                }
            }
        } else {
            // Promise.all([ ]);
            getManufacturers({ loc: loc, setManufacturers: setAllManufacturers });
            getVehicles({ loc: loc, setVehicles: setAllVehicles, setVehiclesCount: setAllVehiclesCount, });
            getBodyStyles({ loc: loc, setBodyStyles: setAllBodyStyles, });
            getReviewsCount({ loc: loc, inf: inf, setReviewsCount: setAllReviewsCount });
            getTestimonialsCount({ loc: loc, setTestimonialsCount: setAllTestimonialsCount });
            getCountry({ loc: loc, setCountry: setCountry });
            getCountries({ setCountries: setCountries });
            if (!getCookie('loc') || loc !== getCookie('loc')) {
                setCookie({ name: 'loc', value: loc });
                if (loc === 'de') {
                    setNotification('Sie sind nun auf der 🇩🇪 deutschen Seite');
                } else if (loc === 'at') {
                    setNotification('Sie sind nun auf der 🇦🇹 österreichischen Seite');
                }
            }

            if (windowPathname.includes(loc)) {
                windowPathnameHelper = windowPathnameHelper.filter((e: any) => e !== loc);
            }
            windowPathnameNew = windowPathnameNew + '/' + loc;

            if (windowPathname.includes(inf)) {
                windowPathnameHelper = windowPathnameHelper.filter((e: any) => e !== inf);
            }
            if (inf) {
                windowPathnameNew = windowPathnameNew + '/' + inf;
            }
        }

        if (windowPathnameNew.length > 0) {
            window.history.replaceState({}, '', windowPathnameNew + '/' + windowPathnameHelper.join('/') + (urlParams.toString().length > 0 ? '?' : '') + urlParams.toString());
            setWindowPathname(windowPathnameNew);
        }
        // }
    }, [inf, loc]);

    // Api data. ✅
    const [allManufacturers, setAllManufacturers] = useState<any>(undefined);
    const [allVehicles, setAllVehicles] = useState<any>(undefined);
    const [allBodyStyles, setAllBodyStyles] = useState<any>(undefined);

    const [allVehiclesCount, setAllVehiclesCount] = useState<any>(undefined);
    const [allReviewsCount, setAllReviewsCount] = useState<number>(0);
    const [allTestimonialsCount, setAllTestimonialsCount] = useState<number>(0);

    const [vehicle, setVehicle] = useState<any>(undefined);
    const [review, setReview] = useState<any>(undefined);
    const [testimonial, setTestimonial] = useState<any>(undefined);

    // Filter. ✅
    const [urlParams, setUrlParams] = useState<any>(new URLSearchParams(window.location.search));
    const [urlParamsDefault, setUrlParamsDefault] = useState<boolean>(false);
    const [filterUrlParamsLength, setFilterUrlParamsLength] = useState<number>(0);
    const [filterUrlParamsKeys] = useState<any>(['hersteller', 'karosserieformen', 'budget-min', 'budget-max', 'reichweite-min', 'leistung-min', 'allrad', 'anhängerkupplung', 'sitze-min', 'isofix', 'materialien']);
    const [filterPowerMin, setFilterPowerMin] = useState<number>(0);
    const [vehiclesFilters, setVehiclesFilters] = useState<any>(undefined);

    useEffect(() => {
        if (Array.from(urlParams).length && urlParamsDefault) {
            window.history.replaceState({}, '', window.location.pathname + (urlParams.toString().length > 0 ? '?' : '') + urlParams.toString());
        } else {
            if (urlParamsDefault) {
                window.history.replaceState({}, '', window.location.pathname + (urlParams.toString().length > 0 ? '?' : '') + urlParams.toString());
            }
            setUrlParamsDefault(true);
        }
    }, [urlParams]);

    // Notifications. ✅
    const [notification, setNotification] = useState<any>('');
    const [cookieNotice, setCookieNotice] = useState<any>(getCookie('cookieNotice'));

    // Redirect if not logged in
    useEffect(() => {
        if (windowPathname.includes('account') && !localStorage.getItem('refresh_token')) {
            window.location.pathname = "/anmelden";
        }
    }, [user]);


    return (
        <React.StrictMode>
            <Router>
                <Header
                    localhost={localhost}
                    allManufacturers={allManufacturers} allVehicles={allVehicles} allVehiclesCount={allVehiclesCount} vehicle={vehicle} allReviewsCount={allReviewsCount} allTestimonialsCount={allTestimonialsCount} review={review} testimonial={testimonial} allBodyStyles={allBodyStyles}
                    urlParams={urlParams}
                    user={user} loc={loc} country={country} countries={countries} inf={inf} influencer={influencer} />

                <Switch>
                    <Route // No match.
                        component={NoMatch}
                        path={[
                            "/404", "/404/",
                            "/:country/404", "/:country/404/",
                            "/:influencer/404", "/:influencer/404/", "/influencer/:country/404", "/influencer/:country/404/",]}
                        loc={loc} />

                    <Route // Login.
                        path={[
                            "/anmelden", "/anmelden/",
                            "/:country/anmelden", "/:country/anmelden/",
                            "/:influencer/anmelden", "/:influencer/anmelden/", "/:country/:influencer/anmelden", "/:country/:influencer/anmelden/",]} exact
                        render={() => <Login
                            setNotification={setNotification}
                            loc={loc} inf={inf} user={user}
                            urlParams={urlParams} />} />

                    <Route // Register.
                        path={[
                            "/registrieren", "/registrieren/",
                            "/:country/registrieren", "/:country/registrieren/",
                            "/:influencer/registrieren", "/:influencer/registrieren/", "/:country/:influencer/registrieren", "/:country/:influencer/registrieren/",]} exact
                        render={() => <Register
                            setNotification={setNotification}
                            loc={loc} inf={inf} user={user}
                            urlParams={urlParams} />} />

                    <Route // Settings.
                        path={[
                            "/account/einstellungen", "/account/einstellungen/",
                            "/:country/account/einstellungen", "/:country/account/einstellungen/",
                            "/:influencer/account/einstellungen", "/:influencer/account/einstellungen/", "/:influencer/:country/account/einstellungen", "/:influencer/:country/account/einstellungen/",]} exact
                        render={
                            () => <SettingsFrontend
                                localhost={localhost}
                                user={user} loc={loc} country={country} inf={inf} influencer={influencer}
                                setNotification={setNotification} />} />

                    <Route // SettingsChangePassword.
                        path={[
                            "/account/einstellungen/passwort-ändern", "/account/einstellungen/passwort-ändern/",
                            "/:country/account/einstellungen/passwort-ändern", "/:country/account/einstellungen/passwort-ändern/",
                            "/:influencer/account/einstellungen/passwort-ändern", "/:influencer/account/einstellungen/passwort-ändern/", "/:influencer/:country/account/einstellungen/passwort-ändern", "/:influencer/:country/account/einstellungen/passwort-ändern/",]} exact
                        render={
                            () => <SettingsFrontendChangePassword
                                localhost={localhost}
                                user={user} loc={loc} country={country} inf={inf} influencer={influencer}
                                setNotification={setNotification} />} />

                    <Route // SettingsPersonalData.
                        path={[
                            "/account/einstellungen/persönliche-daten", "/account/einstellungen/persönliche-daten/",
                            "/:country/account/einstellungen/persönliche-daten", "/:country/account/einstellungen/persönliche-daten/",
                            "/:influencer/account/einstellungen/persönliche-daten", "/:influencer/account/einstellungen/persönliche-daten/", "/:influencer/:country/account/einstellungen/persönliche-daten", "/:influencer/:country/account/einstellungen/persönliche-daten/",]} exact
                        render={
                            () => <SettingsFrontendPersonalData
                                localhost={localhost}
                                user={user} loc={loc} country={country} inf={inf} influencer={influencer}
                                setNotification={setNotification} />} />

                    <Route // SettingsTestimonials.
                        path={[
                            "/account/erfahrungsberichte", "/account/erfahrungsberichte/",
                            "/:country/account/erfahrungsberichte", "/:country/account/erfahrungsberichte/",
                            "/:influencer/account/erfahrungsberichte", "/:influencer/account/erfahrungsberichte/", "/:influencer/:country/account/erfahrungsberichte", "/:influencer/:country/account/erfahrungsberichte/",]} exact
                        render={
                            () => <SettingsFrontendTestimonials
                                localhost={localhost}
                                user={user} loc={loc} country={country} inf={inf} influencer={influencer}
                                setNotification={setNotification} windowPathname={windowPathname} />} />

                    <Route // SettingsTestimonialsCreateEdit.
                        path={[
                            "/account/erfahrungsberichte/:testimonial", "/account/erfahrungsberichte/:testimonial/",
                            "/:country/account/erfahrungsberichte/:testimonial", "/:country/account/erfahrungsberichte/:testimonial/",
                            "/:influencer/account/erfahrungsberichte/:testimonial", "/:influencer/account/erfahrungsberichte/:testimonial/", "/:influencer/:country/account/erfahrungsberichte/:testimonial", "/:influencer/:country/account/erfahrungsberichte/:testimonial/",]} exact
                        render={
                            () => <SettingsFrontendTestimonialsCreateEdit
                                localhost={localhost}
                                user={user} loc={loc} country={country} inf={inf} influencer={influencer}
                                setNotification={setNotification} windowPathname={windowPathname} />} />

                    <Route // Compare page.
                        path={[
                            "/vergleichen", "/vergleichen/",
                            "/:country/vergleichen", "/:country/vergleichen/",
                            "/:influencer/vergleichen", "/:influencer/vergleichen/", "/:influencer/:country/vergleichen", "/:influencer/:country/vergleichen/",]} exact
                        render={
                            () => <Compare
                                urlParams={urlParams} setUrlParams={setUrlParams} filterUrlParamsLength={filterUrlParamsLength} filterUrlParamsKeys={filterUrlParamsKeys} setFilterUrlParamsLength={setFilterUrlParamsLength} filterPowerMin={filterPowerMin} setFilterPowerMin={setFilterPowerMin} vehiclesFilters={vehiclesFilters} setVehiclesFilters={setVehiclesFilters}
                                localhost={localhost}
                                loc={loc} country={country} inf={inf} influencer={influencer} />} />

                    <Route // Vehicles feed.
                        path={[
                            "/pkw/", "/pkw",
                            "/:country/pkw", "/:country/pkw/",
                            "/:influencer/pkw", "/:influencer/pkw/", "/:country/:influencer/pkw", "/:country/:influencer/pkw/"]} exact
                        render={() => <VehicleFeed
                            urlParams={urlParams} setUrlParams={setUrlParams} filterUrlParamsLength={filterUrlParamsLength} filterUrlParamsKeys={filterUrlParamsKeys} setFilterUrlParamsLength={setFilterUrlParamsLength} filterPowerMin={filterPowerMin} setFilterPowerMin={setFilterPowerMin} vehiclesFilters={vehiclesFilters} setVehiclesFilters={setVehiclesFilters}
                            localhost={localhost}
                            loc={loc} country={country} inf={inf} influencer={influencer} />} />

                    <Route // Detail page.
                        path={[
                            "/pkw/:manufacturer/:vehicle", "/pkw/:manufacturer/:vehicle/",
                            "/:country/pkw/:manufacturer/:vehicle", "/:country/pkw/:manufacturer/:vehicle/",
                            "/:influencer/pkw/:manufacturer/:vehicle", "/:influencer/pkw/:manufacturer/:vehicle/", "/:influencer/:country/pkw/:manufacturer/:vehicle", "/:influencer/:country/pkw/:manufacturer/:vehicle/",]} exact
                        render={() => <Detail
                            urlParams={urlParams} setUrlParams={setUrlParams}
                            vehicle={vehicle} setCar={setVehicle} manufacturers={allManufacturers}
                            localhost={localhost}
                            loc={loc} country={country} inf={inf} influencer={influencer} windowPathname={windowPathname} />} />

                    <Route // Reviews feed.
                        path={[
                            "/bewertungen/", "/bewertungen",
                            "/:country/bewertungen", "/:country/bewertungen/",
                            "/:influencer/bewertungen", "/:influencer/bewertungen/", "/:country/:influencer/bewertungen", "/:country/:influencer/bewertungen/"]} exact
                        render={() => <ReviewFeed
                            localhost={localhost}
                            loc={loc} country={country} inf={inf} user={user} influencer={influencer} />} />

                    <Route // Review detail.
                        path={[
                            "/bewertungen/:review/", "/bewertungen/:review",
                            "/:country/bewertungen/:review", "/:country/bewertungen/:review/",
                            "/:influencer/bewertungen/:review", "/:influencer/bewertungen/:review/", "/:country/:influencer/bewertungen/:review", "/:country/:influencer/bewertungen/:review/"]} exact
                        render={() => <ReviewDetail
                            review={review} setReview={setReview}
                            localhost={localhost}
                            loc={loc} country={country} inf={inf} influencer={influencer} />} />

                    <Route // Testimonial feed.
                        path={[
                            "/erfahrungsberichte/", "/erfahrungsberichte",
                            "/:country/erfahrungsberichte", "/:country/erfahrungsberichte/",
                            "/:influencer/erfahrungsberichte", "/:influencer/erfahrungsberichte/", "/:country/:influencer/erfahrungsberichte", "/:country/:influencer/erfahrungsberichte/"]} exact
                        render={() => <TestimonialFeed
                            localhost={localhost}
                            loc={loc} country={country} inf={inf} user={user} influencer={influencer} />} />

                    <Route // Testimonial detail.
                        path={[
                            "/erfahrungsberichte/:testimonial/", "/erfahrungsberichte/:testimonial",
                            "/:country/erfahrungsberichte/:testimonial", "/:country/erfahrungsberichte/:testimonial/",
                            "/:influencer/erfahrungsberichte/:testimonial", "/:influencer/erfahrungsberichte/:testimonial/", "/:country/:influencer/erfahrungsberichte/:testimonial", "/:country/:influencer/erfahrungsberichte/:testimonial/"]} exact
                        render={() => <TestimonialDetail
                            testimonial={testimonial} setTestimonial={setTestimonial}
                            localhost={localhost}
                            loc={loc} country={country} inf={inf} influencer={influencer} />} />

                    <Route // Imprint page.
                        path={[
                            "/impressum", "/impressum/", "/:country/impressum", "/:country/impressum/",
                            "/:influencer/impressum", "/:influencer/impressum/", "/:country/:influencer/impressum", "/:country/:influencer/impressum/",]} exact
                        render={() => <Imprint
                            localhost={localhost}
                            loc={loc} country={country} inf={inf} influencer={influencer} />} />

                    <Route // Privacy-policy page.
                        path={[
                            "/datenschutzrichtlinien", "/datenschutzrichtlinien/", "/:country/datenschutzrichtlinien", "/:country/datenschutzrichtlinien/",
                            "/:influencer/datenschutzrichtlinien", "/:influencer/datenschutzrichtlinien/", "/:country/:influencer/datenschutzrichtlinien", "/:country/:influencer/datenschutzrichtlinien/",]} exact
                        render={() => <PrivacyPolicy
                            localhost={localhost}
                            loc={loc} country={country} inf={inf} influencer={influencer} />} />


                    <Route // Terms-of-use page.
                        path={[
                            "/nutzungsbedingungen", "/nutzungsbedingungen/", "/:country/nutzungsbedingungen", "/:country/nutzungsbedingungen/",
                            "/:influencer/nutzungsbedingungen", "/:influencer/nutzungsbedingungen/", "/:country/:influencer/nutzungsbedingungen", "/:country/:influencer/nutzungsbedingungen/",]} exact
                        render={() => <TermsOfUse
                            localhost={localhost}
                            loc={loc} country={country} inf={inf} influencer={influencer} />} />

                    <Route // Business info landingpage.
                        path={[
                            "/händler", "/händler/", "/:country/händler", "/:country/händler/",
                            "/:influencer/händler", "/:influencer/händler/", "/:country/:influencer/händler", "/:country/:influencer/händler/",]} exact
                        render={() => <LandingpageBusiness
                            localhost={localhost}
                            loc={loc} inf={inf}
                            setNotification={setNotification} />} />

                    <Route // Influencer info landingpage.
                        path={[
                            "/influencer", "/influencer/", "/:country/influencer", "/:country/influencer/",
                            "/:influencer/influencer", "/:influencer/influencer/", "/:country/:influencer/influencer", "/:country/:influencer/influencer/",]} exact
                        render={() => <LandingpageInfluencer
                            localhost={localhost}
                            loc={loc} inf={inf}
                            setNotification={setNotification} />} />

                    <Route // Home page.
                        path={[
                            "/", "/:country", "/:country/",
                            "/:influencer", "/:influencer/", "/:country/:influencer", "/:country/:influencer/",]} exact
                        render={() => <Home
                            localhost={localhost}
                            manufacturers={allManufacturers}
                            loc={loc} country={country} inf={inf} user={user} influencer={influencer} urlParams={urlParams} />}
                        allReviewsCount={allReviewsCount} allTestimonialsCount={allTestimonialsCount} />

                    <Route // No match.
                        component={NoMatch}
                        path="*"
                        loc={loc} />
                </Switch>

                <Footer
                    localhost={localhost} urlParams={urlParams}
                    allReviewsCount={allReviewsCount} allTestimonialsCount={allTestimonialsCount}
                    loc={loc} country={country} countries={countries} inf={inf} user={user} influencer={influencer} />

                <Notify notification={notification} setNotification={setNotification} />
                <Cookies cookieNotice={cookieNotice} setCookieNotice={setCookieNotice} loc={loc} inf={inf} />
            </Router>
        </React.StrictMode>
    );
}

export default App;
