import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import React, { useState, useEffect } from 'react';

import { // Components.
    TestimonialBanner,
    Wrapper
} from '../components/components';

import { // Functions.
    authUserLogout,
    countryUrl,
    putUser
} from '../functions/functions';


function SettingsFrontendPersonalData(props: any) {
    // Api data.
    const [userEmail, setUserEmail] = useState<string>(props.user ? props.user.email : '');
    const [userFirstname, setUserFirstname] = useState<string>(
        props.user && props.user.data && props.user.data.firstname ? props.user.data.firstname : '');
    const [userLastname, setUserLastname] = useState<string>(
        props.user && props.user.data && props.user.data.lastname ? props.user.data.lastname : '');
    const [userProfilePicture, setUserProfilePicture] = useState<string>(
        props.user && props.user.data && props.user.data.profile_picture ? props.user.data.profile_picture : '');

    useEffect(() => {
        if (props.user) {
            setUserEmail(props.user.email);
            if (props.user.data && props.user.data.firstname) { setUserFirstname(props.user.data.firstname) }
            if (props.user.data && props.user.data.lastname) { setUserLastname(props.user.data.lastname) }
            if (props.user.data && props.user.data.profile_picture) { setUserProfilePicture(props.user.data.profile_picture) }
        }
    }, [props.user]);

    // Auto save.
    const [autoSaveTimer, setAutoSaveTimer] = useState<any>(undefined);

    function autoSave() {
        setAutoSaveTimer(setTimeout(function () {
            putUser({ setUser: props.setUser, userEmail: userEmail, userFirstname: userFirstname, userLastname: userLastname, user: props.user, setNotification: props.setNotification, setUserProfilePicture: setUserProfilePicture });
        }, 3000));
    }

    function clearAutoSave() {
        setAutoSaveTimer(clearTimeout(autoSaveTimer));
    }


    return (
        <React.StrictMode>
            <Helmet>
                <title>
                    Einstellungen - Persönliche Daten | electricmagazin.com
                </title>
                <meta
                    name="description"
                    content={"Überprüfen und aktualisieren Sie Ihre persönlichen Daten wie Name, Profilbild und E-Mail."} />
            </Helmet>

            <Wrapper>
                <div className="flex flex-wrap mt-2 mb-2">
                    <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "account/einstellungen"}><p className="mr-0-5">Einstellungen</p></a>
                    <p className="mr-0-5">{'>'}</p>
                    <h5 className="c-prime">Persönliche Daten</h5>
                </div>

                <div>
                    <div className="mb-2">
                        <h3>Benutzereinstellungen</h3>
                        <p className="op-50">Überprüfen und aktualisieren Sie Ihre persönlichen Daten wie Name, Profilbild und E-Mail.</p>
                    </div>

                    <div>
                        <input
                            id="userDataId" type="hidden"
                            value={props.user && props.user.data ? props.user.data.id : 0} />

                        {/* Profile picture. */}
                        <div className="mb-1-5">
                            <div className="flex mb-0-5">
                                <h5 className="mr-0-5">Profilbild</h5>
                                <button className="btn-info hide-sm">bei Erfahrungsberichten angezeigt</button>
                            </div>
                            <div className="flex">
                                <div className="circle bg-dark h-3em w-3em mr-0-5">
                                    {userProfilePicture ?
                                        <LazyLoadImage
                                            effect="opacity"
                                            className="w-100 h-100"
                                            src={userProfilePicture}
                                            alt={userFirstname + ' ' + userLastname + ' ' + 'Profilbild'} />
                                        : null}
                                </div>
                                <input id="userProfilePicture" className="file hide-med" type="file" name="userProfilePicture" aria-label="File browser example" onChange={() => {
                                    clearAutoSave();
                                    autoSave();
                                    props.setNotification("Änderungen werden gespeichert...");
                                }} />
                            </div>
                            <div className="hide display-med">
                                <input id="userProfilePicture" className="file mt-1" type="file" name="userProfilePicture" aria-label="File browser example" onChange={() => {
                                    clearAutoSave();
                                    autoSave();
                                    props.setNotification("Änderungen werden gespeichert...");
                                }} />
                            </div>
                        </div>

                        {/* User email address. */}
                        <div className="mb-1-5">
                            <div className="flex mb-0-5">
                                <h5 className="mr-0-5">E-Mail Adresse*</h5>
                                {userEmail ?
                                    <button className="btn-info btn-info-prime">ok</button>
                                    : null}
                                <button className="btn-info btn-info-placeholder" id="userEmailNotify"></button>
                            </div>
                            <input
                                className="w-100" type="email"
                                id="userEmail" name="userEmail" placeholder="E-Mail Adresse*"
                                value={userEmail}
                                onChange={(e) => {
                                    setUserEmail(e.target.value);
                                    clearAutoSave();
                                    autoSave();
                                    props.setNotification("Änderungen werden gespeichert...");
                                }} />
                        </div>

                        {/* User firstname. */}
                        <div className="mb-1-5">
                            <div className="flex mb-0-5">
                                <h5 className="mr-0-5">Vorname</h5>
                                <button className="btn-info hide-sm">bei Erfahrungsberichten angezeigt</button>
                                <button className="btn-info btn-info-placeholder" id="userFirstnameNotify"></button>
                            </div>
                            <input
                                className="w-100" type="text"
                                id="userFirstname" name="userFirstname" placeholder="Vorname"
                                value={userFirstname}
                                onChange={(e) => {
                                    setUserFirstname(e.target.value);
                                    clearAutoSave();
                                    autoSave();
                                    props.setNotification("Änderungen werden gespeichert...");
                                }} />
                        </div>

                        {/* User lastname. */}
                        <div className="mb-1-5">
                            <div className="flex mb-0-5">
                                <h5 className="mr-0-5">Nachname</h5>
                                <button className="btn-info hide-sm">bei Erfahrungsberichten angezeigt</button>
                                <button className="btn-info btn-info-placeholder" id="userLastnameNotify"></button>
                            </div>
                            <input
                                className="w-100" type="text"
                                id="userLastname" name="userLastname" placeholder="Nachname"
                                value={userLastname}
                                onChange={(e) => {
                                    setUserLastname(e.target.value);
                                    clearAutoSave();
                                    autoSave();
                                    props.setNotification("Änderungen werden gespeichert...");
                                }} />
                        </div>
                    </div>
                </div>


                <div className="mb-5 hide-lg" id="bewertung"></div>
                <div className="hide display-lg" id="bewertung"><div className="mb-3"></div></div>


                <div>
                    <TestimonialBanner localhost={props.localhost} loc={props.loc} inf={props.inf} user={props.user} />
                </div>


                <div className="mb-5 hide-lg" id="bewertung"></div>
                <div className="hide display-lg" id="bewertung"><div className="mb-3"></div></div>


                <div>
                    <div className="mb-2">
                        <h3>Weitere Einstellungen</h3>
                    </div>

                    <div className="flex flex-ai-s">
                        <div className="border border-txt-25 border-radius p-1 w-100">
                            <div className="mb-1">
                                <h4>Account Sicherheit</h4>
                            </div>

                            <p className="op-50 mb-1">Hier können Sie Ihr Passwort ändern.</p>

                            <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "account/einstellungen/passwort-ändern"}>
                                <button className="btn-sm btn-grey">Passwort ändern</button>
                            </a>
                        </div>

                        <div className="border border-txt-25 border-radius p-1 w-100 ml-1 hide-med">
                            <div className="a">
                                <button
                                    className="btn-onclick flex"
                                    onClick={() => {
                                        authUserLogout({ loc: props.loc, inf: props.inf, setNotification: props.setNotification });
                                    }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"></path></svg>
                                    <span className="ml-0-5 strong">Abmelden</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="hide display-med">
                        <div className="border border-txt-25 border-radius p-1 mt-1">
                            <div className="a">
                                <button
                                    className="btn-onclick flex"
                                    onClick={() => {
                                        authUserLogout({ loc: props.loc, inf: props.inf, setNotification: props.setNotification });
                                    }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"></path></svg>
                                    <span className="ml-0-5 strong">Abmelden</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <ul className="mt-2">
                        <li className="mb-0-5">
                            <div className="flex">
                                <div className="a">
                                    <button className="btn-onclick op-25 c-danger">
                                        <p className="mr-0-5">Account Löschen</p>
                                    </button>
                                </div>
                                <div className="w-100 c-transparent">.</div>
                            </div>
                        </li>
                    </ul>
                </div>


                <div className="mb-5 hide-lg" id="bewertung"></div>
                <div className="hide display-lg" id="bewertung"><div className="mb-3"></div></div>
            </Wrapper>
        </React.StrictMode>
    );
}

export default SettingsFrontendPersonalData;