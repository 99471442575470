import { axiosInstance, axiosLocalErrorHandling } from '../axios';


export const postBusinessApplicant = (props: any) => {
    let jsonData: any = {}
    var errorCount: number = 0

    // Name.
    const businessNameNotify: any = document.getElementById('businessNameNotify');
    const businessNameInput: any = document.getElementById('businessName');
    const businessName = (businessNameInput && businessNameInput.value ? businessNameInput.value : '');
    if (businessName) {
        jsonData.name = businessName;
        businessNameNotify.classList.add("btn-info-placeholder");
        businessNameNotify.classList.remove("btn-info-second");
        businessNameNotify.innerHTML = "";
    } else {
        errorCount += 1
        businessNameNotify.classList.remove("btn-info-placeholder");
        businessNameNotify.classList.add("btn-info-second");
        businessNameNotify.innerHTML = "Bitte ausfüllen!"
    }

    // Dealership.
    const businessDealershipNotify: any = document.getElementById('businessDealershipNotify');
    const businessDealershipInput: any = document.getElementById('businessDealership');
    const businessDealership = (businessDealershipInput && businessDealershipInput.value ? businessDealershipInput.value : '');
    if (businessDealership) {
        jsonData.dealership = businessDealership;
        businessDealershipNotify.classList.add("btn-info-placeholder");
        businessDealershipNotify.classList.remove("btn-info-second");
        businessDealershipNotify.innerHTML = "";
    } else {
        errorCount += 1
        businessDealershipNotify.classList.remove("btn-info-placeholder");
        businessDealershipNotify.classList.add("btn-info-second");
        businessDealershipNotify.innerHTML = "Bitte ausfüllen!"
    }

    // Job.
    const businessJobNotify: any = document.getElementById('businessJobNotify');
    const businessJobInput: any = document.getElementById('businessJob');
    const businessJob = (businessJobInput && businessJobInput.value ? businessJobInput.value : '');
    if (businessJob) {
        jsonData.job = businessJob;
        businessJobNotify.classList.add("btn-info-placeholder");
        businessJobNotify.classList.remove("btn-info-second");
        businessJobNotify.innerHTML = "";
    } else {
        errorCount += 1
        businessJobNotify.classList.remove("btn-info-placeholder");
        businessJobNotify.classList.add("btn-info-second");
        businessJobNotify.innerHTML = "Bitte ausfüllen!"
    }

    // E-mail.
    const businessEmailNotify: any = document.getElementById('businessEmailNotify');
    const businessEmailInput: any = document.getElementById('businessEmail');
    const dataEmail = (businessEmailInput && businessEmailInput.value ? businessEmailInput.value : '');
    if (dataEmail && new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i).test(dataEmail)) {
        jsonData.email = dataEmail;
        businessEmailNotify.classList.remove("btn-info-second");
        businessEmailNotify.classList.add("btn-info-placeholder");
        businessEmailNotify.innerHTML = ""
    } else if (dataEmail && !new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i).test(dataEmail)) {
        businessEmailNotify.classList.remove("btn-info-placeholder");
        businessEmailNotify.classList.add("btn-info-second");
        businessEmailNotify.innerHTML = "Bitte korrekte E-Mail eingeben!"
    } else {
        businessEmailNotify.classList.remove("btn-info-placeholder");
        businessEmailNotify.classList.add("btn-info-second");
        businessEmailNotify.innerHTML = "Bitte ausfüllen!"
    }

    // Phone.
    const businessPhoneNotify: any = document.getElementById('businessPhoneNotify');
    const businessPhoneInput: any = document.getElementById('businessPhone');
    const businessPhone = (businessPhoneInput && businessPhoneInput.value ? businessPhoneInput.value : '');
    if (businessPhone) {
        jsonData.phone = businessPhone;
        businessPhoneNotify.classList.add("btn-info-placeholder");
        businessPhoneNotify.classList.remove("btn-info-second");
        businessPhoneNotify.innerHTML = "";
    } else {
        errorCount += 1
        businessPhoneNotify.classList.remove("btn-info-placeholder");
        businessPhoneNotify.classList.add("btn-info-second");
        businessPhoneNotify.innerHTML = "Bitte ausfüllen!"
    }

    // Referrer text.
    const businessReferrerNotify: any = document.getElementById('businessReferrerNotify');
    const businessReferrerInput: any = document.getElementById('businessReferrer');
    const businessReferrer = (businessReferrerInput && businessReferrerInput.value ? businessReferrerInput.value : '');
    if (businessReferrer) {
        jsonData.referrer_text = businessReferrer;
        businessReferrerNotify.classList.add("btn-info-placeholder");
        businessReferrerNotify.classList.remove("btn-info-second");
        businessReferrerNotify.innerHTML = "";
    } else {
        errorCount += 1
        businessReferrerNotify.classList.remove("btn-info-placeholder");
        businessReferrerNotify.classList.add("btn-info-second");
        businessReferrerNotify.innerHTML = "Bitte ausfüllen!"
    }

    // Referrer (influencer).
    const businessInfluencerInput: any = document.getElementById('businessInfluencer');
    const businessInfluencer = (businessInfluencerInput && businessInfluencerInput.value ? businessInfluencerInput.value : '');
    if (businessInfluencer) {
        jsonData.referrer = businessInfluencer;
    }
    
    
    if (errorCount === 0 && Object.keys(jsonData).length > 0) {
        axiosInstance.post('backend/business-application/create/', 
            jsonData, { 
                headers: {
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                }
            })
        .then(function (response: any) {
            if (props.setNotification) { props.setNotification("Vielen Dank! Wir haben Ihre Bewerbung erhalten."); }
            if (props.setBusinessName) { props.setBusinessName(''); }
            if (props.setBusinessDealership) { props.setBusinessDealership(''); }
            if (props.setBusinessJob) { props.setBusinessJob(''); } 
            if (props.setBusinessEmail) { props.setBusinessEmail(''); } 
            if (props.setBusinessPhone) { props.setBusinessPhone(''); }
            if (props.setBusinessReferrer) { props.setBusinessReferrer(''); }
            if (props.setApplicationOpen) { props.setApplicationOpen(false); }
        })
        .catch(function (error: any) {
            if (props.setNotification) { props.setNotification("Ups, bitte versuche es nocheinmal!"); }
            axiosLocalErrorHandling({ error: error, function: "postBusinessApplicant" });
        })
    }
}