export const deleteCookie = (cookie: any) => {
    if (document.cookie.includes(cookie)) {
        document.cookie = cookie + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
}

export const getCookie = (cookie: any) => {
    var cookieVal = null
    if (document.cookie && document.cookie.includes(cookie)) {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookieArray = cookies[i].split('=');
            if (cookieArray[0] === " " + cookie || cookieArray[0] === cookie) {
                cookieVal = cookieArray[1];
                break;
            }
        }
    }
    return cookieVal;
}

export const setCookie = (props: any) => {
    const name = props.name;
    const value = props.value;
    const minutes = props.minutes;
    const days = props.days;

    if (name && value && value.length > 0 && value !== '{}') {
        if (minutes || days) {
            var expires = new Date();
            if (minutes) {
                expires.setTime(expires.getTime() + (parseInt(minutes) * 60 * 1000));
            } else if (days) {
                expires.setDate(expires.getDate() + parseInt(days));
            }
            document.cookie = name + "=" + value + "; path=/; expires=" + expires.toUTCString() + ";";

        } else {
            document.cookie = name + "=" + value + "; path=/;";
        }

    } else if (name && minutes || name && days) {
        var expires = new Date();
        if (minutes) {
            expires.setTime(expires.getTime() + (parseInt(minutes) * 60 * 1000));
        } else if (days) {
            expires.setDate(expires.getDate() + parseInt(days));
        }
        document.cookie = name + "=" + expires.toUTCString() + "; path=/; expires=" + expires.toUTCString() + ";";

    } else {
        if (document.cookie.includes(name)) {
            document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        }
    }
}