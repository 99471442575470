function Banner(props: any) {
    return (
        <div className={"Banner" + (props.className ? " " + props.className : '')}>
            <picture className="Banner-backdrop">
                <source
                    srcSet={(props.localhost ? '' : '/static') + "/media/covers/id4-driving-large.jpg"}
                    media="(min-width: 1380px)" />
                <source
                    srcSet={(props.localhost ? '' : '/static') + "/media/covers/id4-driving-medium.jpg"}
                    media="(min-width: 990px)" />
                <source
                    srcSet={(props.localhost ? '' : '/static') + "/media/covers/id4-driving-small.jpg"}
                    media="(min-width: 640px)" />
                <img
                    className="w-100 h-100"
                    srcSet={(props.localhost ? '' : '/static') + "/media/covers/id4-driving-smaller.jpg"}
                    alt={"Aktuelle Elektroauto Förderung in " + (props.country && props.country.name ? props.country.name : '')} />
            </picture>
            <h5 className="mb-0-5">Aktuelle Elektroauto Förderung in {props.country && props.country.name ? props.country.name : ''}</h5>
            <div className="flex flex-ai-e">
                <h5 className="mr-0-5 mb-0-3 hide display-sm">bis zu:</h5>
                <h5 className="mr-0-5 mb-0-45 hide-sm">bis zu:</h5>
                <h2>€ {props.country && props.country.promotion_max ? props.country.promotion_max : ''}</h2>
            </div>
        </div>
    );
}

export default Banner;