import { axiosInstance, axiosInstanceJWT, axiosLocalErrorHandling } from '../axios';

import { // Functions.
    countryUrl,
} from '../../functions';


// Multiple reviews frontend.
export const getReviews = (props: any) => {
    var filter: any = {};

    if (props.loc) {  // Country.
        filter.country = props.loc;
    }

    if (props.inf) {  // Influencer.
        filter.influencer = props.inf;
    }

    if (props.limit) {  // Limit.
        filter.limit = props.limit;
        filter.offset = 0;
    }

    if (props.order) {  // Order.
        filter.order = props.order;
    }

    axiosInstance.get('reviews/', {
        params: filter
    })
        .then(function (response: any) {
            if (response.data) {
                if (response.data.reviews && props.setReviews) {
                    props.setReviews(response.data.reviews);
                }
                if (response.data.count && props.setReviewsCount) {
                    props.setReviewsCount(response.data.count);
                }
            }
        })
        .catch(function (error: any) {
            axiosLocalErrorHandling({ error: error, function: "getReviews" });
        })
}


// Reviews count frontend.
export const getReviewsCount = (props: any) => {
    var filter: any = {};
    if (props.loc) { filter.country = props.loc; }
    if (props.inf) { filter.influencer = props.inf; }

    axiosInstance.get('reviews/count/', {
        params: filter
    })
        .then(function (response: any) {
            if (response.data && response.data.count && props.setReviewsCount) {
                props.setReviewsCount(response.data.count);
            }
        })
        .catch(function (error: any) {
            axiosLocalErrorHandling({ error: error, function: "getReviewsCount" });
        })
}


// Single review frontend.
export const getReview = (props: any) => {
    if (props.review) {
        var filter: any = {};

        if (props.loc) {  // Country.
            filter.country = props.loc;
        }

        axiosInstance.get('reviews/' + props.review + '/', {
            params: filter
        })
            .then(function (response: any) {
                if (response.data && props.setReview) {
                    props.setReview(response.data);
                }
            })
            .catch(function (error: any) {
                if (error.response && error.response.status && error.response.status === 500) {
                    window.location.pathname = countryUrl({ loc: props.loc, inf: props.inf }) + "404";
                } else {
                    axiosLocalErrorHandling({ error: error, function: "getReview" });
                }
            })
    }
}


// Multiple reviews backend.
export const getReviewsBackend = (props: any) => {
    axiosInstanceJWT.get('backend/reviews/')
        .then(function (response: any) {
            // console.log(response)
            if (response.data) {
                if (response.data && props.setReviews) {
                    props.setReviews(response.data);
                }
            }
        })
        .catch(function (error: any) {
            axiosLocalErrorHandling({ error: error, function: "getReviewsBackend" });
        })
}


// Vehicles for review backend.
export const getReviewVehicles = (props: any) => {
    axiosInstanceJWT.get('backend/reviews/create/vehicles/')
        .then(function (response: any) {
            // console.log(response)
            if (response.data && props.setReviewVehicles) {
                props.setReviewVehicles(response.data);
            }
        })
        .catch(function (error: any) {
            axiosLocalErrorHandling({ error: error, function: "getReviewVehicles" });
        })
}


// Single review backend.
export const getReviewBackend = (props: any) => {
    if (props.reviewId) {
        axiosInstanceJWT.get('backend/reviews/' + props.reviewId + '/')
            .then(function (response: any) {
                // console.log(response)
                if (response.data && props.setReview) {
                    props.setReview(response.data);
                }
            })
            .catch(function (error: any) {
                if (error.response && error.response.status && error.response.status === 500) {
                    window.location.pathname = countryUrl({ loc: props.loc, inf: props.inf }) + "404";
                } else {
                    axiosLocalErrorHandling({ error: error, function: "getReviewBackend" });
                }
            })
    }
}