import { LazyLoadImage } from 'react-lazy-load-image-component';

import React from 'react';


function Stripe(props: any) {
    return (
        <div className={"Stripe" + (props.className ? ' ' + props.className : '')}>
            {props.gallery.map((image: any, index: number) =>
                <React.Fragment key={index}>
                    <LazyLoadImage
                        effect="opacity"
                        srcSet={
                            (image.img_smaller ? image.img_smaller + " 640w, " : '') + (image.img_small ? image.img_small + " 990w, " : '') + (image.img_medium ? image.img_medium + " 1380w, " : '') + (image.img_large ? image.img_large + " 1920w" : '')}
                        alt={props.alt} />
                </React.Fragment>
            )}
        </div>
    );
}

export default Stripe;