import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import React, { useState, useEffect } from 'react';

import { // Components.
    Backend
} from '../../components/components';

import { // Functions.
    putUser
} from '../../functions/functions';


function SettingsUser(props: any) {
    const setNotification = props.setNotification;
    const [windowPathname] = useState<string>(props.windowPathname);

    // Api data.
    const [userEmail, setUserEmail] = useState<string>(props.user ? props.user.email : '');
    const [userFirstname, setUserFirstname] = useState<string>(
        props.user && props.user.data && props.user.data.firstname ? props.user.data.firstname : '');
    const [userLastname, setUserLastname] = useState<string>(
        props.user && props.user.data && props.user.data.lastname ? props.user.data.lastname : '');
    const [userProfilePicture, setUserProfilePicture] = useState<string>(
        props.user && props.user.data && props.user.data.profile_picture ? props.user.data.profile_picture : '');

    useEffect(() => {
        if (props.user) {
            setUserEmail(props.user.email);
            if (props.user.data && props.user.data.firstname) { setUserFirstname(props.user.data.firstname) }
            if (props.user.data && props.user.data.lastname) { setUserLastname(props.user.data.lastname) }
            if (props.user.data && props.user.data.profile_picture) { setUserProfilePicture(props.user.data.profile_picture) }
        }
    }, [props.user]);

    // Auto save.
    const [autoSaveTimer, setAutoSaveTimer] = useState<any>(undefined);

    function autoSave() {
        setAutoSaveTimer(setTimeout(function () {
            putUser({ setUser: props.setUser, userEmail: userEmail, userFirstname: userFirstname, userLastname: userLastname, user: props.user, setNotification: setNotification, setUserProfilePicture: setUserProfilePicture });
        }, 3000));
    }

    function clearAutoSave() {
        setAutoSaveTimer(clearTimeout(autoSaveTimer));
    }


    return (
        <React.StrictMode>
            <Helmet>
                <title>
                    Benutzereinstellungen | electricmagazin.com
                </title>
                <meta
                    name="description"
                    content={"Benutzereinstellungen."} />
            </Helmet>

            <Backend
                windowPathname={windowPathname} setNotification={props.setNotification}
                influencer={props.influencer} refreshtoken={props.refreshtoken}>
                <div className="flex flex-wrap mb-2">
                    <a href="/dashboard/settings"><p className="mr-0-5">Einstellungen</p></a>
                    <p className="mr-0-5">{'>'}</p>
                    <h5 className="c-prime">Benutzereinstellungen</h5>
                </div>

                <div>
                    <div className="mb-2">
                        <h3>Benutzereinstellungen</h3>
                        <p className="op-50">Bearbeiten Sie Ihre persönlichen Daten.</p>
                    </div>

                    <div>
                        <input
                            id="userDataId" type="hidden"
                            value={props.user && props.user.data ? props.user.data.id : 0} />

                        {/* Profile picture. */}
                        <div className="mb-1-5">
                            <div className="flex mb-0-5">
                                <h5 className="mr-0-5">Profilbild</h5>
                                {!userProfilePicture ?
                                    <button className="btn-info">Optional</button>
                                    : null}
                            </div>
                            <div className="flex">
                                <div className="circle bg-dark h-3em w-3em mr-0-5">
                                    {userProfilePicture ?
                                        <LazyLoadImage
                                            effect="opacity"
                                            className="w-100 h-100"
                                            src={userProfilePicture}
                                            alt={userFirstname + ' ' + userLastname + ' ' + 'Profilbild'} />
                                        : null}
                                </div>
                                <input id="userProfilePicture" className="file hide-sm" type="file" name="userProfilePicture" aria-label="File browser example" onChange={() => {
                                    clearAutoSave();
                                    autoSave();
                                    setNotification("Änderungen werden gespeichert...");
                                }} />
                            </div>
                            <div className="hide display-sm">
                                <input id="userProfilePicture" className="file mt-0-5" type="file" name="userProfilePicture" aria-label="File browser example" onChange={() => {
                                    clearAutoSave();
                                    autoSave();
                                    setNotification("Änderungen werden gespeichert...");
                                }} />
                            </div>
                        </div>

                        {/* User email address. */}
                        <div className="mb-1-5">
                            <div className="flex mb-0-5">
                                <h5 className="mr-0-5">E-Mail Adresse*</h5>
                                <button className="btn-info btn-info-placeholder" id="userEmailNotify"></button>
                            </div>
                            <input
                                className="w-100" type="email"
                                id="userEmail" name="userEmail" placeholder="E-Mail Adresse*"
                                value={userEmail}
                                onChange={(e) => {
                                    setUserEmail(e.target.value);
                                    clearAutoSave();
                                    autoSave();
                                    setNotification("Änderungen werden gespeichert...");
                                }} />
                        </div>

                        {/* User firstname. */}
                        <div className="mb-1-5">
                            <div className="flex mb-0-5">
                                <h5 className="mr-0-5">Vorname*</h5>
                                <button className="btn-info btn-info-placeholder" id="userFirstnameNotify"></button>
                            </div>
                            <input
                                className="w-100" type="text"
                                id="userFirstname" name="userFirstname" placeholder="Vorname"
                                value={userFirstname}
                                onChange={(e) => {
                                    setUserFirstname(e.target.value);
                                    clearAutoSave();
                                    autoSave();
                                    setNotification("Änderungen werden gespeichert...");
                                }} />
                        </div>

                        {/* User lastname. */}
                        <div className="mb-1-5">
                            <div className="flex mb-0-5">
                                <h5 className="mr-0-5">Nachname*</h5>
                                <button className="btn-info btn-info-placeholder" id="userLastnameNotify"></button>
                            </div>
                            <input
                                className="w-100" type="text"
                                id="userLastname" name="userLastname" placeholder="Nachname"
                                value={userLastname}
                                onChange={(e) => {
                                    setUserLastname(e.target.value);
                                    clearAutoSave();
                                    autoSave();
                                    setNotification("Änderungen werden gespeichert...");
                                }} />
                        </div>
                    </div>
                </div>


                <div className="mb-5 hide-lg"></div>
                <div className="hide display-lg"><div className="mb-3"></div></div>


                <div>
                    <div className="mb-2">
                        <h3>Weitere Einstellungen</h3>
                    </div>

                    <ul>
                        <li className="mb-0-5">
                            <div className="flex">
                                <a href="/dashboard/settings/user/change-password">
                                    <p className="mr-0-5">Passwort ändern</p>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>


                <div className="mb-5 hide-lg"></div>
                <div className="hide display-lg"><div className="mb-3"></div></div>
            </Backend>
        </React.StrictMode >
    );
}

export default SettingsUser;