import React, { useState } from 'react';

import { // Functions.
    countryUrl
} from './../../functions/functions';


function Nav(props: any) {
    // Limit amount on mobile!
    const [limitAmount, setLimitAmount] = useState<number>(props.limit);

    return (
        props.children ?
            <nav className={'Nav Nav-' + props.type + ' Nav-' + props.columns + ' ' + props.className}>
                {props.header ? <h4 className="mb-2 nowrap">{props.header}</h4> : null}

                <ul className={props.classNameList}>
                    {props.children}
                </ul>
            </nav>
            : props.onClick || props.href ?
                <React.StrictMode>
                    <div className={props.limit ? "hide-med" : ""}>
                        <nav className={'Nav Nav-' + props.type + ' Nav-' + props.columns + ' ' + props.className}>
                            {props.header ? <h2 className="mb-1-5">{props.header}</h2> : null}

                            <ul className={props.classNameList}>
                                {props.items ? props.items.map((item: any, index: number) =>
                                    <React.Fragment key={index}>
                                        <li className="mb-1">
                                            {props.href ?
                                                <a
                                                    href={countryUrl({ loc: props.loc, inf: props.inf }) + props.href + item.slug}>
                                                    <div className="op-50">
                                                        {item.logo ?
                                                            <img
                                                                src={item.logo}
                                                                alt={item.slug + " Logo"} />
                                                            : null}
                                                        {item.name}
                                                    </div>
                                                </a>
                                                : <a onClick={() => props.onClick(item)}>
                                                    <div className="op-50">
                                                        {item.logo ?
                                                            <img
                                                                src={item.logo}
                                                                alt={item.slug + " Logo"} />
                                                            : null}
                                                        {item.name}
                                                    </div>
                                                </a>}
                                        </li>
                                    </React.Fragment>
                                ) : null}

                                {!props.items ? <li><div className="op-25 capitalize">...</div></li> : null}
                                {!props.items ? <li><div className="op-25 capitalize">...</div></li> : null}
                                {!props.items ? <li><div className="op-25 capitalize">...</div></li> : null}
                            </ul>
                        </nav>
                    </div>

                    <div className={props.limit ? "hide display-med" : "hide"}>
                        <nav className={'Nav Nav-' + props.type + ' Nav-' + props.columns + ' ' + props.className}>
                            {props.header ? <h2 className="mb-1-5">{props.header}</h2> : null}

                            <ul className={props.classNameList}>
                                {props.items ? props.items.slice(0, limitAmount).map((item: any, index: number) =>
                                    <React.Fragment key={index}>
                                        <li className="mb-1">
                                            {props.href ?
                                                <a href={countryUrl({ loc: props.loc, inf: props.inf }) + props.href + item.slug}>
                                                    <div className="op-50">
                                                        {item.logo ?
                                                            <img
                                                                src={item.logo}
                                                                alt={item.slug + " Logo"} />
                                                            : null}
                                                        {item.name}
                                                    </div>
                                                </a>
                                                : <a onClick={() => props.onClick(item)}>
                                                    <div className="op-50">
                                                        {item.logo ?
                                                            <img
                                                                src={item.logo}
                                                                alt={item.slug + " Logo"} />
                                                            : null}
                                                        {item.name}
                                                    </div>
                                                </a>}
                                        </li>
                                    </React.Fragment>
                                ) : null}

                                {!props.items ? <li><div className="op-25 capitalize">...</div></li> : null}
                                {!props.items ? <li><div className="op-25 capitalize">...</div></li> : null}
                                {!props.items ? <li><div className="op-25 capitalize">...</div></li> : null}
                            </ul>
                        </nav>

                        {props.limit && props.items && props.limit < props.items.length && limitAmount !== props.items.length ?
                            <div className="flex flex-jc mb-5 mt-2">
                                <button
                                    className="btn-sm op-50 btn-outline"
                                    onClick={() => setLimitAmount(props.items.length)}>Alle anzeigen</button>
                            </div>
                            : null}
                    </div>
                </React.StrictMode>
                : null
    );
}

export default Nav;