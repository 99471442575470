import { axiosInstanceJWT, axiosLocalErrorHandling } from '../axios';

import { // Functions.
    countryUrl,
} from '../../functions';


export const getUser = (props: any) => {
    axiosInstanceJWT.get('backend/user/current/')
        .then(function (response: any) {
            if (response && response.data) {
                if (props.setUser) {
                    props.setUser(response.data);
                }
                if (props.setInfluencer) {
                    props.setInfluencer(response.data.influencer)
                }

                // Redirect from dashboard if is not influencer or business.
                if (props.windowPathname && props.windowPathname.includes('dashboard')) {
                    if (!response.data.influencer && !response.data.business) {
                        window.location.pathname = countryUrl({ loc: props.loc, inf: props.inf });
                    }
                }
            }
        })
        .catch(function (error: any) {
            axiosLocalErrorHandling({ error: error, function: "getUser" });
        })
}