import { axiosInstance, axiosLocalErrorHandling } from '../axios';


export const postVehiclePopularity = (props: any) => {
    if (props.meta_data && props.type) {
        axiosInstance.post('backend/vehicles/popularity/', {
            'meta_data': props.meta_data,
            'type': props.type,
            'country': props.country,
            'influencer': props.influencer 
            }, { 
                headers: {
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                }
            })
        .catch(function (error: any) {
            axiosLocalErrorHandling({ error: error, function: "postVehiclePopularity" });
        })
    }
}


export const postReviewPopularity = (props: any) => {
    if (props.meta_data && props.type) {
        axiosInstance.post('backend/reviews/popularity/', {
            'meta_data': props.meta_data,
            'type': props.type,
            'country': props.country,
            'influencer': props.influencer
            }, { 
                headers: {
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                }
            })
        .catch(function (error: any) {
            axiosLocalErrorHandling({ error: error, function: "postReviewPopularity" });
        })
    }
}