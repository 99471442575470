import { Helmet } from 'react-helmet';

import React, { useState } from 'react';

import { // Components.
    Backend
} from '../../components/components';

import { // Functions.
    putUserPassword
} from '../../functions/functions';


function SettingsUserPassword(props: any) {
    const [windowPathname] = useState<string>(props.windowPathname);

    // Api data.
    const [userCurrentPassword, setUserCurrentPassword] = useState<string>('');
    const [userNewPassword, setUserNewPassword] = useState<string>('');
    const [userNewPasswordVerify, setUserNewPasswordVerify] = useState<string>('');


    return (
        <React.StrictMode>
            <Helmet>
                <title>
                    Einstellungen Benutzerpasswort ändern | electricmagazin.com
                </title>
                <meta
                    name="description"
                    content={"Passwort ändern."} />
            </Helmet>

            <Backend 
                windowPathname={windowPathname} setNotification={props.setNotification}
                influencer={props.influencer} refreshtoken={props.refreshtoken}>
                    <div className="flex flex-wrap mb-2">
                        <a href="/dashboard/settings"><p className="mr-0-5">Einstellungen</p></a>
                        <p className="mr-0-5">{'>'}</p>
                        <a href="/dashboard/settings/user"><p className="mr-0-5">Benutzereinstellungen</p></a>
                        <p className="mr-0-5">{'>'}</p>
                        <h5 className="c-prime">Passwort ändern</h5>
                    </div>

                    <div className="mb-5">
                        <div className="mb-2">
                            <h3>Passwort ändern</h3>
                            <p className="op-50">Geben Sie ihr aktuelles Passwort ein und wählen Sie anschließen ein neues Passwort.</p>
                        </div>

                        <div>
                            {/* Current password. */}
                            <div className="mb-1-5">
                                <div className="flex mb-0-5">
                                    <h5 className="mr-0-5">Aktuelles Passwort*</h5>
                                    <button className="btn-info btn-info-placeholder" id="userCurrentPasswordNotify"></button>
                                </div>
                                <input 
                                    className="w-100" type="password"
                                    id="userCurrentPassword" name="userCurrentPassword" placeholder="Akutelles Passwort" 
                                    value={userCurrentPassword} 
                                    onChange={(e) => {
                                        setUserCurrentPassword(e.target.value);
                                    }} />
                            </div>

                            {/* New password. */}
                            <div className="mb-1-5">
                                <div className="flex mb-0-5">
                                    <h5 className="mr-0-5">Neues Passwort*</h5>
                                    <button className="btn-info btn-info-placeholder" id="userNewPasswordNotify"></button>
                                </div>
                                <input 
                                    className="w-100" type="password"
                                    id="userNewPassword" name="userNewPassword" placeholder="Neues Passwort" 
                                    value={userNewPassword} 
                                    onChange={(e) => {
                                        setUserNewPassword(e.target.value);
                                    }} />
                            </div>

                            {/* Verify new password. */}
                            <div className="mb-1-5">
                                <div className="flex mb-0-5">
                                    <h5 className="mr-0-5">Neues Passwort bestätigen*</h5>
                                    <button className="btn-info btn-info-placeholder" id="userNewPasswordVerifyNotify"></button>
                                </div>
                                <input 
                                    className="w-100" type="password"
                                    id="userNewPasswordVerify" name="userNewPasswordVerify" placeholder="Neues Passwort bestätigen" 
                                    value={userNewPasswordVerify} 
                                    onChange={(e) => {
                                        setUserNewPasswordVerify(e.target.value);
                                    }} />
                            </div>

                            <div className="flex flex-jc">
                                <button 
                                    onClick={() => putUserPassword({ user: props.user, setNotification: props.setNotification })}>
                                        Passwort ändern
                                </button>
                            </div>
                        </div>
                    </div>
            </Backend>
        </React.StrictMode >
    );
}

export default SettingsUserPassword;