import React, { useState, useEffect } from 'react';

import { // Components.
    Wrapper,
} from '../components';


function Popup(props: any) {
    // Popup logic.
    const [popupActive, setPopupActive] = useState<boolean>(false);

    useEffect(() => {
        if (popupActive || props.popupActive) { 
            document.body.classList.add("no-scroll");
        }
    }, [popupActive, props.popupActive]);


    return (
        <div className={"flex flex-jc" + (props.className ? ' ' + props.className : '')}>
            {props.trigger ?
                <div className="a"><button
                    className="btn-grey btn-sm"
                    onClick={() => {
                        if (props.popupActive && props.setPopupActive) {
                            props.setPopupActive(!props.popupActive);
                        } else {
                            setPopupActive(!popupActive);
                        }
                    }}>
                    {props.trigger}
                </button></div>
                : null}

            {popupActive || props.popupActive ?
                <div className={"Popup " + props.className}>
                    <Wrapper>
                        <div className="Popup-inner-wrapper">
                            <div className="Popup-inner">
                                <div className="Popup-close a">
                                    <button
                                        className="btn-onclick"
                                        onClick={() => {
                                            document.body.classList.remove("no-scroll");
                                            if (props.popupActive && props.setPopupActive) {
                                                props.setPopupActive(!props.popupActive);
                                            } else {
                                                setPopupActive(!popupActive);
                                            }
                                        }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.255 141.255"><path d="M132.869,142.033,73.312,82.477,13.746,142.043a6.48,6.48,0,1,1-9.164-9.164L64.148,73.313,4.582,13.747a6.48,6.48,0,0,1,9.165-9.165L73.313,64.148,132.889,4.571a6.48,6.48,0,0,1,9.143,9.185L82.477,73.312l59.578,59.578a6.48,6.48,0,0,1-9.185,9.142Z" transform="translate(-2.685 -2.686)" fill="currentColor" /></svg>
                                    </button>
                                </div>

                                {props.children}
                            </div>
                        </div>
                    </Wrapper>
                </div>
                : null}
        </div>
    );
}

export default Popup;