import React, { useState, useEffect } from 'react';

import Scroller from './../Scroller';

import {
    scrollToId
} from './../../../functions/scroll-to';
import { countryUrl } from '../../../functions/href';


function ScrollerBodyStyles(props: any) {
    const bodyStyles = props.bodyStyles;
    const urlParams = props.urlParams;
    const setUrlParams = props.setUrlParams;
    const [selectedBodyStyles, setSelectedBodyStyles] = useState<string>('');

    useEffect(() => {
        if (props.urlParams) {
            if (props.urlParams.has('karosserieformen')) {
                setSelectedBodyStyles(props.urlParams.get('karosserieformen'));
            } else {
                setSelectedBodyStyles('');
            }
        }
    }, [props.urlParams]);


    return (
        <Scroller className={"Scroller-fullwith Scroller-bodystyles py-2" + (props.className ? " " + props.className : "")} mouseEvents={true}>
            <div></div>
            {bodyStyles ?
                bodyStyles.filter((bodyStyle: any) => selectedBodyStyles.includes(bodyStyle.slug)).map((bodyStyle: any, index: number) =>
                    <React.Fragment key={index}>
                        <div className={"a Scroller-bodystyles-elem" + (urlParams.has('karosserieformen') && selectedBodyStyles.includes(bodyStyle.slug) ? ' Scroller-bodystyles-elem-active' : '')}>
                            <button className="btn-onclick" onClick={() => {
                                scrollToId('feed');
                                if (urlParams.has('karosserieformen') && selectedBodyStyles.includes(bodyStyle.slug)) {
                                    if (selectedBodyStyles.split(',').length - 1 === 1) {
                                        urlParams.delete('karosserieformen');
                                        setUrlParams(new URLSearchParams(urlParams.toString()));
                                    } else {
                                        urlParams.set('karosserieformen', String(selectedBodyStyles.replace(bodyStyle.slug + ',', '')));
                                        setUrlParams(new URLSearchParams(urlParams.toString()));
                                    }
                                } else {
                                    urlParams.set('karosserieformen', String((urlParams.has('karosserieformen') ? selectedBodyStyles : '') + bodyStyle.slug + ','));
                                    setUrlParams(new URLSearchParams(urlParams.toString()));
                                }
                            }}>
                                <div className="mb-0-25">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.401 194.401"><path d="M97.2,194.4A97.2,97.2,0,0,1,28.468,28.468,97.2,97.2,0,0,1,165.932,165.932,96.6,96.6,0,0,1,97.2,194.4ZM55.676,96.8a6.73,6.73,0,0,0-4.759,11.493L74.7,132.067a6.9,6.9,0,0,0,.887.778c.132.156.28.319.435.474a6.728,6.728,0,0,0,9.51,0l59.455-59.455a6.765,6.765,0,0,0,0-9.51,6.72,6.72,0,0,0-9.517,0L80.715,119.1,60.42,98.8A6.678,6.678,0,0,0,55.676,96.8Z" transform="translate(0 0)" fill="currentColor" /></svg>
                                    <img
                                        src={bodyStyle.thumbnail}
                                        alt={bodyStyle.slug + " Seitenansicht Elektroauto Icon"} />
                                </div>
                                <p className="capitalize center">{bodyStyle.name}</p>
                            </button>
                        </div>
                    </React.Fragment>)
                : null}

            {bodyStyles && bodyStyles.filter((bodyStyle: any) => selectedBodyStyles.includes(bodyStyle.slug)).length > 0 && bodyStyles.filter((bodyStyle: any) => !selectedBodyStyles.includes(bodyStyle.slug)).length > 0 ?
                <div className="c-prime mr-3">
                    <svg className="h-4em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 100.696"><path d="M2405.564,657.022v96.7" transform="translate(-2403.564 -655.022)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="3" /></svg>
                </div>
                : null}

            {bodyStyles ? bodyStyles.filter((bodyStyle: any) => !selectedBodyStyles.includes(bodyStyle.slug)).map((bodyStyle: any, index: number) =>
                <React.Fragment key={index}>
                    {props.urlParams ?
                        <div className="a Scroller-bodystyles-elem">
                            <button className="btn-onclick" onClick={() => {
                                scrollToId('feed');
                                if (urlParams.has('karosserieformen') && selectedBodyStyles.includes(bodyStyle.slug)) {
                                    if (selectedBodyStyles.split(',').length - 1 === 1) {
                                        urlParams.delete('karosserieformen');
                                        setUrlParams(new URLSearchParams(urlParams.toString()));
                                    } else {
                                        urlParams.set('karosserieformen', String(selectedBodyStyles.replace(bodyStyle.slug + ',', '')));
                                        setUrlParams(new URLSearchParams(urlParams.toString()));
                                    }
                                } else {
                                    urlParams.set('karosserieformen', String((urlParams.has('karosserieformen') ? selectedBodyStyles : '') + bodyStyle.slug + ','));
                                    setUrlParams(new URLSearchParams(urlParams.toString()));
                                }
                            }}>
                                <div className="mb-0-25">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.401 194.401"><path d="M97.2,194.4A97.2,97.2,0,0,1,28.468,28.468,97.2,97.2,0,0,1,165.932,165.932,96.6,96.6,0,0,1,97.2,194.4ZM55.676,96.8a6.73,6.73,0,0,0-4.759,11.493L74.7,132.067a6.9,6.9,0,0,0,.887.778c.132.156.28.319.435.474a6.728,6.728,0,0,0,9.51,0l59.455-59.455a6.765,6.765,0,0,0,0-9.51,6.72,6.72,0,0,0-9.517,0L80.715,119.1,60.42,98.8A6.678,6.678,0,0,0,55.676,96.8Z" transform="translate(0 0)" fill="currentColor" /></svg>
                                    <img
                                        src={bodyStyle.thumbnail}
                                        alt={bodyStyle.slug + " Seitenansicht Elektroauto Icon"} />
                                </div>
                                <p className="capitalize center">{bodyStyle.name}</p>
                            </button>
                        </div>
                        : <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "pkw?karosserieformen=" + bodyStyle.slug}>
                            <div className="Scroller-bodystyles-elem">
                                <button className="btn-onclick">
                                    <div className="mb-0-25">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.401 194.401"><path d="M97.2,194.4A97.2,97.2,0,0,1,28.468,28.468,97.2,97.2,0,0,1,165.932,165.932,96.6,96.6,0,0,1,97.2,194.4ZM55.676,96.8a6.73,6.73,0,0,0-4.759,11.493L74.7,132.067a6.9,6.9,0,0,0,.887.778c.132.156.28.319.435.474a6.728,6.728,0,0,0,9.51,0l59.455-59.455a6.765,6.765,0,0,0,0-9.51,6.72,6.72,0,0,0-9.517,0L80.715,119.1,60.42,98.8A6.678,6.678,0,0,0,55.676,96.8Z" transform="translate(0 0)" fill="currentColor" /></svg>
                                        <img
                                            src={bodyStyle.thumbnail}
                                            alt={bodyStyle.slug + " Seitenansicht Elektroauto Icon"} />
                                    </div>
                                    <p className="capitalize center">{bodyStyle.name}</p>
                                </button>
                            </div>
                        </a>}
                </React.Fragment>
            ) : null}


            {!bodyStyles ?
                <div className="flex">
                    <div className="Scroller-bodystyles-elem Scroller-bodystyles-elem-active op-25">
                        <button className="btn-onclick">
                            <div className="mb-0-25 w-5em">
                                <svg className="spinner" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path></svg>
                            </div>
                            <p className="capitalize center">...</p>
                        </button>
                    </div>
                    <div className="Scroller-bodystyles-elem Scroller-bodystyles-elem-active op-25">
                        <button className="btn-onclick">
                            <div className="mb-0-25 w-5em">
                                <svg className="spinner" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path></svg>
                            </div>
                            <p className="capitalize center">...</p>
                        </button>
                    </div>
                    <div className="Scroller-bodystyles-elem Scroller-bodystyles-elem-active op-25">
                        <button className="btn-onclick">
                            <div className="mb-0-25 w-5em">
                                <svg className="spinner" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path></svg>
                            </div>
                            <p className="capitalize center">...</p>
                        </button>
                    </div>
                </div>
                : null}
            <div>.</div>
        </Scroller>
    );
}


export default ScrollerBodyStyles;