import React, { useState, useEffect } from 'react';

import { // Components.
    Footer,
    Nav,
    Wrapper
} from './../../components/components';

import { // Functions.
    countryUrl
} from './../../functions/functions';


function Mobile(props: any) {
    const [vehicleMenu, setVehicleMenu] = useState<boolean>(false);

    return (
        <div className="Mobile" id="Mobile">
            <Wrapper>
                <div className="Mobile-header mb-2">
                    <div className={"flex w-100 " + (vehicleMenu ? "flex-jc-sb" : "flex-jc-e")}>
                        {vehicleMenu ?
                            <button
                                className="btn-onclick"
                                onClick={() => setVehicleMenu(false)}>
                                <svg className="h-1-2em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z"></path></svg>
                            </button>
                            : null}
                        <button
                            className="btn-onclick"
                            onClick={() => {
                                setVehicleMenu(false);
                                const Mobile = document.getElementById('Mobile');
                                if (Mobile) {
                                    document.body.classList.remove('no-scroll');
                                    Mobile.classList.remove('display');
                                }
                            }}>
                            <svg className="h-1-2em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
                        </button>
                    </div>
                </div>

                {vehicleMenu ?
                    <div>
                        <div className="w-100 flex h-3em op-50">
                            <h4><span>Elektroautos</span></h4>
                        </div>
                        <hr className="op-25 bg-txt hr-thin" />
                        <Nav className="mb-2">
                            <li>
                                <div className="w-100">
                                    <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "pkw"} className="w-100 flex h-3em">
                                        <button className="btn-onclick">
                                            Alle Elektroautos
                                        </button>
                                    </a>
                                    <hr className="op-25 bg-txt hr-thin" />
                                </div>
                            </li>
                            {props.allBodyStyles ?
                                props.allBodyStyles.map((bodyStyle: any, index: number) =>
                                    <React.Fragment key={index}>
                                        <li>
                                            <div className="w-100">
                                                <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "pkw?karosserieformen=" + bodyStyle.slug} className="w-100 flex h-3em">
                                                    <button className="btn-onclick">
                                                        {bodyStyle.name}
                                                    </button>
                                                </a>
                                                <hr className="op-25 bg-txt hr-thin" />
                                            </div>
                                        </li>
                                    </React.Fragment>)
                                : null}
                        </Nav>
                        <div className="w-100 flex h-3em op-50">
                            <h4><span>Elektroauto Marken</span></h4>
                        </div>
                        <hr className="op-25 bg-txt hr-thin" />
                        <Nav className="mb-2">
                            {props.allManufacturers ?
                                props.allManufacturers.map((manufacturer: any, index: number) =>
                                    <React.Fragment key={index}>
                                        <li>
                                            <div className="w-100">
                                                <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "pkw?hersteller=" + manufacturer.slug} className="w-100 flex h-3em">
                                                    <button className="btn-onclick">
                                                        <img
                                                            src={manufacturer.logo}
                                                            alt={manufacturer.name + " Logo Elektroauto Hersteller"} />
                                                        {manufacturer.name}
                                                    </button>
                                                </a>
                                                <hr className="op-25 bg-txt hr-thin" />
                                            </div>
                                        </li>
                                    </React.Fragment>)
                                : null}
                        </Nav>
                    </div>
                    : <div>
                        <Nav className="mb-2">
                            <li>
                                <div className="flex flex-jc w-100">
                                    <button
                                        className="btn-onclick h4 uppercase"
                                        onClick={() => setVehicleMenu(true)}>
                                        Elektroautos
                                    </button>
                                </div>
                                {/*<a href={countryUrl({ loc: props.loc, inf: props.inf }) + "pkw"} className="w-100 flex flex-jc h-3em">
                                    <h4 className="uppercase">Elektroautos</h4>
                                </a>*/}
                            </li>
                            {props.allReviewsCount ?
                                <li>
                                    <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "bewertungen"} className="w-100 flex flex-jc h-3em">
                                        <h4 className="uppercase">Bewertungen</h4>
                                    </a>
                                </li>
                                : null}
                            {props.allTestimonialsCount ?
                                <li>
                                    <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "erfahrungsberichte"} className="w-100 flex flex-jc h-3em">
                                        <h4 className="uppercase">Erfahrungsberichte</h4>
                                    </a>
                                </li>
                                : null}
                        </Nav>

                        <Nav>
                            <div className="flex flex-jc">
                                <a href={countryUrl({ loc: props.loc, inf: props.inf }) + 'vergleichen'}>
                                    <button className="btn btn-sm btn-outline uppercase">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M0 168v-16c0-13.255 10.745-24 24-24h360V80c0-21.367 25.899-32.042 40.971-16.971l80 80c9.372 9.373 9.372 24.569 0 33.941l-80 80C409.956 271.982 384 261.456 384 240v-48H24c-13.255 0-24-10.745-24-24zm488 152H128v-48c0-21.314-25.862-32.08-40.971-16.971l-80 80c-9.372 9.373-9.372 24.569 0 33.941l80 80C102.057 463.997 128 453.437 128 432v-48h360c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24z"></path></svg>
                                        Autos vergleichen
                                    </button>
                                </a>
                            </div>
                        </Nav>
                    </div>}
            </Wrapper>

            <div className="h-50"></div>

            <Footer
                localhost={props.localhost} urlParams={props.urlParams}
                allReviewsCount={props.allReviewsCount} allTestimonialsCount={props.allTestimonialsCount}
                loc={props.loc} country={props.country} countries={props.countries} inf={props.inf} user={props.user} influencer={props.influencer} />
        </div>
    );
}
export default Mobile;