import { LazyLoadImage } from 'react-lazy-load-image-component';

import React, { useState, useEffect } from 'react';


function Carousel(props: any) {
    const images = props.images;
    const alt = props.alt;
    // const srcSetBackPath = props.srcSetBackPath;
    // const localhost = props.localhost;

    const [currentSlide, setCurrentSlide] = useState<number>(0);
    // const currentSlide = props.currentSlide;
    // const setCurrentSlide = props.setCurrentSlide;
    const [currentSlideLoading, setCurrentSlideLoading] = useState<boolean>(true);

    useEffect(() => {
        setCurrentSlideLoading(true);
        if ((Object.keys(images).length - 1) < currentSlide) {
            setCurrentSlide(0);
        }
    }, [images]);

    // 360° drag logic
    const [scrollerActive, setScrollerActive] = useState<boolean>(false);
    const [startClientX, setStartClientX] = useState<number>(0);

    function onMouseMove(e: any) {
        if (scrollerActive) {
            if ((e.target.offsetWidth / 10) < startClientX - e.clientX) {
                images && currentSlide + 1 === images.length ? setCurrentSlide(0) : setCurrentSlide(currentSlide + 1);
                setStartClientX(e.clientX);
            } else if ((e.target.offsetWidth / 10) < e.clientX - startClientX) {
                images && currentSlide === 0 ? setCurrentSlide(images.length - 1) : setCurrentSlide(currentSlide - 1)
                setStartClientX(e.clientX);
            }
        }
    }

    return (
        <div className="Carousel">
            <div className="Carousel-inner"
                onMouseDown={(e: any) => {
                    const childern = e.target.childNodes
                    if (childern) {
                        childern.forEach((child: any) => {
                            child.style.pointerEvents = "none";
                        });
                    }
                    setStartClientX(e.clientX);
                    setScrollerActive(true);
                }}
                onMouseUp={(e: any) => {
                    const childern = e.target.childNodes
                    if (childern) {
                        childern.forEach((child: any) => {
                            child.style.pointerEvents = "auto";
                        });
                    }
                    setScrollerActive(false)
                }}
                onMouseMove={(e: any) => onMouseMove(e)}>
                {images ?
                    images.map((image: any, index: any) =>
                        <React.Fragment key={index}>
                            <div className={"Carousel-slide " + (currentSlide !== index ? "Carousel-slide-hidden" : "")}>

                                <div className="hide-lg">
                                    <LazyLoadImage
                                        effect="opacity"
                                        height="125%"
                                        className={'h-100' + (props.imgClassName ? ' ' + props.imgClassName : '')}
                                        draggable="false"
                                        srcSet={
                                            (image.img_smaller ? image.img_smaller + " 640w, " : '') + (image.img_small ? image.img_small + " 990w, " : '') + (image.img_medium ? image.img_medium + " 1380w, " : '') + (image.img_large ? image.img_large + " 1920w" : '')}
                                        alt={alt ? alt : ''}
                                        onLoad={() => setTimeout(function () { setCurrentSlideLoading(false); }, 500)} />
                                </div>
                                <div className="hide display-lg">
                                    <LazyLoadImage
                                        effect="opacity"
                                        height="100%"
                                        className={'h-100' + (props.imgClassName ? ' ' + props.imgClassName : '')}
                                        draggable="false"
                                        srcSet={
                                            (image.img_smaller ? image.img_smaller + " 640w, " : '') + (image.img_small ? image.img_small + " 990w, " : '') + (image.img_medium ? image.img_medium + " 1380w, " : '') + (image.img_large ? image.img_large + " 1920w" : '')}
                                        alt={alt ? alt : ''}
                                        onLoad={() => setTimeout(function () { setCurrentSlideLoading(false); }, 500)} />
                                </div>

                            </div>
                            {currentSlideLoading ?
                                <div className="absolute">
                                    <svg className="h-2em spinner c-base" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path></svg>
                                </div>
                                : null}
                        </React.Fragment>
                    )
                    : null}

                <button
                    className="Carousel-prev"
                    onClick={() => (images && currentSlide === 0 ?
                        setCurrentSlide(images.length - 1)
                        : setCurrentSlide(currentSlide - 1))}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.007 204.058"><path d="M5.66,208.579A7.5,7.5,0,0,1,4.92,198l77.9-89.609L4.92,18.781A7.5,7.5,0,0,1,16.241,8.941L98.249,103.28a8.028,8.028,0,0,1,0,10.221L16.241,207.84a7.5,7.5,0,0,1-10.581.74Z" transform="translate(100.088 210.42) rotate(180)" fill="currentColor" /></svg>
                </button>
                <button
                    className="Carousel-next"
                    onClick={() => (images && currentSlide + 1 === images.length ?
                        setCurrentSlide(0)
                        : setCurrentSlide(currentSlide + 1))}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.007 204.058"><path d="M5.66,208.579A7.5,7.5,0,0,1,4.92,198l77.9-89.609L4.92,18.781A7.5,7.5,0,0,1,16.241,8.941L98.249,103.28a8.028,8.028,0,0,1,0,10.221L16.241,207.84a7.5,7.5,0,0,1-10.581.74Z" transform="translate(-3.081 -6.361)" fill="currentColor" /></svg>
                </button>
            </div>
        </div>
    );
}

export default Carousel;