
import React from 'react';

import { // Components.
    Wrapper
} from '../../components';

import { // Functions.
    countryUrl,
    setCookie
} from '../../../functions/functions';


function Cookies(props: any) {
    return (
        <React.StrictMode>
            <div className={"Cookies " + (!props.cookieNotice ? "" : "hidden")}>
                <Wrapper>
                    <div>
                        <h2 className="mb-1">Wir nutzen Cookies</h2>
                        <p className="mb-2">
                            <span className="hide-lg">Wir nutzen Cookies und andere Technologien, damit unsere Seiten zuverlässig und sicher laufen, wir deren Performance beobachten können und um Ihnen relevante und personalisierte Inhalte zu präsentieren.
                                <br /><br /></span>
                            Wenn Sie auf <u>Akzeptieren</u> klicken, sind Sie damit einverstanden und erlauben uns, diese Daten an Dritte weiterzugeben. Weitere Details finden Sie in unseren <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "datenschutzrichtlinien"}><u>Datenschutzrichtlinien</u></a>.
                        </p>
                        <div className="flex">
                            <button onClick={() => {
                                setCookie({ name: 'cookieNotice', value: 'all' });
                                props.setCookieNotice(true);
                            }}>Akzeptieren</button>
                        </div>
                    </div>
                </Wrapper>
            </div>
        </React.StrictMode >
    );
}

export default Cookies;