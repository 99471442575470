import { // Components.
    Nav,
    Select,
    Wrapper
} from '../../components/components';

import { // Functions.
    countryUrl,
} from '../../functions/functions';


function Footer(props: any) {
    // Country content.
    const countries = props.countries;
    const country = props.country;

    return (
        <footer className="Footer">
            <Wrapper>
                <div className="flex flex-ai-s mb-3">
                    <div className="Footer-help mr-5 hide-xlg">
                        <Nav className="" classNameHeader="uppercase mb-2" header="Hilfe">
                            <li className="mb-1">
                                <a href="mailto:hallo@electricmagazin.com">
                                    <span>hallo@electricmagazin.com</span>
                                </a>
                            </li>
                        </Nav>
                    </div>
                    <div className="Footer-offer mr-5">
                        <Nav classNameHeader="uppercase mb-2" header="Unser Angebot">
                            <li className="mb-1">
                                <a
                                    className="capitalize"
                                    href={countryUrl({ loc: props.loc, inf: props.inf }) + "pkw"}>
                                    <span>Elektroautos Entdecken</span>
                                </a>
                            </li>
                            {props.allReviewsCount ?
                                <li className="mb-1">
                                    <a
                                        className="capitalize"
                                        href={countryUrl({ loc: props.loc, inf: props.inf }) + "bewertungen"}>
                                        <span>Elektroauto-Bewertungen</span>
                                    </a>
                                </li>
                                : null}
                            {props.allTestimonialsCount ?
                                <li className="mb-1">
                                    <a
                                        className="capitalize"
                                        href={countryUrl({ loc: props.loc, inf: props.inf }) + "erfahrungsberichte"}>
                                        <span>Erfahrungsberichte</span>
                                    </a>
                                </li>
                                : null}
                            <li className="mb-1">
                                <a
                                    className="capitalize"
                                    href={countryUrl({ loc: props.loc, inf: props.inf }) + "vergleichen"}>
                                    <span>Elektroauto-Vergleich</span>
                                </a>
                            </li>
                            <li className="mb-1">
                                <a
                                    className="capitalize"
                                    href={countryUrl({ loc: props.loc, inf: props.inf }) + "registrieren" + (props.urlParams && props.urlParams.has('token') ? "?token=" + props.urlParams.get('token') : '') + (props.urlParams && props.urlParams.has('email') ? "&email=" + props.urlParams.get('email') : '') + (props.urlParams && props.urlParams.has('ziel') ? "?ziel=" + props.urlParams.get('ziel') : '')}>
                                    <span>Account erstellen</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    className="capitalize"
                                    href={countryUrl({ loc: props.loc, inf: props.inf }) + "anmelden" + (props.urlParams && props.urlParams.has('token') ? "?token=" + props.urlParams.get('token') : '') + (props.urlParams && props.urlParams.has('email') ? "&email=" + props.urlParams.get('email') : '') + (props.urlParams && props.urlParams.has('ziel') ? "?ziel=" + props.urlParams.get('ziel') : '')}>
                                    <span>Anmelden</span>
                                </a>
                            </li>
                        </Nav>
                    </div>
                    <div className="hide-sm">
                        <div className="Footer-help mr-5">
                            <Nav className="" classNameHeader="uppercase mb-2" header="Partner werden">
                                <li className="mb-1">
                                    <a href={props.user && props.user.influencer && props.user.influencer.length ? "/dashboard/testimonials" : props.user ? countryUrl({ loc: props.loc, inf: props.inf }) + "account/erfahrungsberichte" : countryUrl({ loc: props.loc, inf: props.inf }) + "anmelden?ziel=erfahrungsbericht"}>
                                        <span>Erfahrungsbericht erstellen</span>
                                    </a>
                                </li>
                                <li className="mb-1">
                                    <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "influencer"}>
                                        <span>Influencer Programm</span>
                                    </a>
                                </li>
                                <li className="mb-1">
                                    <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "händler"}>
                                        <span>Für Händler</span>
                                    </a>
                                </li>
                            </Nav>
                        </div>
                    </div>
                    <div className="flex flex-jc-e w-100 Footer-Country">
                        <div className="hide-xlg">
                            {countries ?
                                <Nav classNameHeader="uppercase mb-2" header="Land">
                                    <li>
                                        <Select
                                            className="w-12em Select-base"
                                            classNameDropdown="bg-dark"
                                            name="countries"
                                            placeholder="Land auswählen"
                                            options={countries}
                                            selected={country && country.name && country.emoji ? country.emoji + ' ' + country.name : undefined}
                                            defaultOption={country ? country : undefined}
                                            onChange={(country: any) => window.location.href = '/' + country.country_code}
                                            inf={props.inf} />
                                    </li>
                                </Nav>
                                : null}
                        </div>
                    </div>
                </div>

                <div className="hide display-sm">
                    <div className="Footer-help mb-3">
                        <Nav className="" classNameHeader="uppercase mb-2" header="Partner werden">
                            <li className="mb-1">
                                <a href={props.user ? countryUrl({ loc: props.loc, inf: props.inf }) + "account/erfahrungsberichte" : countryUrl({ loc: props.loc, inf: props.inf }) + "anmelden?ziel=erfahrungsbericht"}>
                                    <span>Erfahrungsbericht erstellen</span>
                                </a>
                            </li>
                            <li className="mb-1">
                                <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "influencer"}>
                                    <span>Influencer Programm</span>
                                </a>
                            </li>
                            <li className="mb-1">
                                <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "händler"}>
                                    <span>Für Händler</span>
                                </a>
                            </li>
                        </Nav>
                    </div>
                </div>

                <div className="hide display-xlg">
                    <div className="mb-3 flex flex-ai-s">
                        <div className="Footer-help">
                            <Nav className="" classNameHeader="uppercase mb-2" header="Hilfe">
                                <li className="mb-1">
                                    <a href="mailto:hallo@electricmagazin.com">
                                        <span className="hide-sm">hallo@electricmagazin.com</span>
                                        <span className="hide display-sm">E-Mail</span>
                                    </a>
                                </li>
                            </Nav>
                        </div>
                        <div className="flex flex-jc-e w-100 Footer-Country">
                            {countries ?
                                <Nav classNameHeader="uppercase mb-2" header="Land">
                                    <li>
                                        <Select
                                            className="w-12em Select-base"
                                            classNameDropdown="bg-dark"
                                            name="countries"
                                            placeholder="Land auswählen"
                                            options={countries}
                                            selected={country && country.name && country.emoji ? country.emoji + ' ' + country.name : undefined}
                                            defaultOption={country ? country : undefined}
                                            onChange={(country: any) => window.location.href = '/' + country.country_code}
                                            inf={props.inf} />
                                    </li>
                                </Nav>
                                : null}
                        </div>
                    </div>
                </div>

                <div className="mb-3">
                    <Nav className="mr-5 Footer-social-media w-100" type="horizontal">
                        <li className="Facebook mr-1-5">
                            <a href="https://www.facebook.com/electricmagazin" target="_blank" rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.257 50"><path d="M28.128,3a25.113,25.113,0,0,0-3.756,49.946V34.789H18.156V28.183h6.217v-4.4c0-7.277,3.545-10.472,9.593-10.472a34.929,34.929,0,0,1,5.154.313V19.4H34.994c-2.568,0-3.464,2.434-3.464,5.178v3.611h7.525l-1.021,6.605h-6.5V53a25.115,25.115,0,0,0-3.4-50Z" transform="translate(-3 -3)" fill="currentColor" /></svg>
                            </a>
                        </li>
                        <li className="Instagram mr-1-5">
                            <a href="https://instagram.com/electricmagazin" target="_blank" rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><path d="M245.281,226.141A19.141,19.141,0,1,1,226.141,207,19.14,19.14,0,0,1,245.281,226.141Zm0,0" transform="translate(-126.141 -126.141)" fill="currentColor" /><path d="M227.324,148.7a19.015,19.015,0,0,0-10.9-10.9,31.77,31.77,0,0,0-10.661-1.976c-6.056-.276-7.872-.336-23.2-.336s-17.149.058-23.2.334A31.79,31.79,0,0,0,148.7,137.8a19.024,19.024,0,0,0-10.9,10.9,31.782,31.782,0,0,0-1.976,10.663c-.276,6.055-.336,7.87-.336,23.2s.06,17.148.336,23.2A31.77,31.77,0,0,0,137.8,216.43a19.015,19.015,0,0,0,10.9,10.9,31.731,31.731,0,0,0,10.663,1.978c6.055.276,7.869.334,23.2.334s17.151-.058,23.2-.334a31.731,31.731,0,0,0,10.663-1.978,19.015,19.015,0,0,0,10.9-10.9,31.8,31.8,0,0,0,1.978-10.661c.276-6.056.334-7.872.334-23.2s-.058-17.149-.334-23.2A31.731,31.731,0,0,0,227.324,148.7Zm-44.763,63.351a29.486,29.486,0,1,1,29.486-29.486A29.486,29.486,0,0,1,182.561,212.049ZM213.213,158.8a6.891,6.891,0,1,1,6.891-6.891A6.891,6.891,0,0,1,213.213,158.8Zm0,0" transform="translate(-82.561 -82.563)" fill="currentColor" /><path d="M100,0A100,100,0,1,0,200,100,100.011,100.011,0,0,0,100,0Zm57.076,123.674a42.129,42.129,0,0,1-2.669,13.939,29.361,29.361,0,0,1-16.794,16.794,42.158,42.158,0,0,1-13.937,2.669c-6.123.279-8.08.346-23.674.346s-17.551-.067-23.676-.346a42.158,42.158,0,0,1-13.937-2.669,29.361,29.361,0,0,1-16.794-16.794,42.119,42.119,0,0,1-2.669-13.937c-.282-6.125-.348-8.081-.348-23.676s.066-17.551.346-23.674a42.133,42.133,0,0,1,2.667-13.939,29.381,29.381,0,0,1,16.8-16.794,42.168,42.168,0,0,1,13.939-2.669c6.123-.279,8.08-.346,23.674-.346s17.551.067,23.674.348a42.177,42.177,0,0,1,13.939,2.666,29.369,29.369,0,0,1,16.794,16.8,42.125,42.125,0,0,1,2.67,13.939c.279,6.123.345,8.08.345,23.674S157.356,117.551,157.076,123.674Zm0,0" fill="currentColor" /></svg>
                            </a>
                        </li>
                        <li className="Pinterest mr-1-5">
                            <a href="https://pin.it/1TeRyRt" target="_blank" rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><path d="M200,108A100.012,100.012,0,0,1,70.4,203.524c4.073-6.653,10.161-17.54,12.419-26.21,1.21-4.677,6.21-23.79,6.21-23.79,3.266,6.21,12.782,11.492,22.9,11.492,30.161,0,51.9-27.742,51.9-62.218,0-33.024-26.976-57.742-61.653-57.742-43.145,0-66.089,28.952-66.089,60.524,0,14.677,7.823,32.944,20.282,38.75,1.9.887,2.9.484,3.347-1.331.323-1.371,2.016-8.185,2.782-11.331a2.994,2.994,0,0,0-.685-2.863c-4.073-5.04-7.379-14.234-7.379-22.823C54.435,83.927,71.129,62.6,99.6,62.6c24.556,0,41.774,16.734,41.774,40.685,0,27.056-13.669,45.806-31.452,45.806-9.8,0-17.177-8.1-14.8-18.065,2.823-11.9,8.266-24.718,8.266-33.306,0-7.661-4.113-14.073-12.661-14.073-10.04,0-18.1,10.363-18.1,24.274A36.032,36.032,0,0,0,75.6,122.758s-9.879,41.855-11.694,49.677c-2.016,8.629-1.21,20.806-.363,28.71A100.01,100.01,0,1,1,200,108Z" transform="translate(0 -8)" fill="currentColor" /></svg>
                            </a>
                        </li>
                        <li className="TikTok mr-1-5">
                            <a href="https://vm.tiktok.com/ZMdEM1APr/" target="_blank" rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><path d="M100,0A100,100,0,1,0,200,100,100.011,100.011,0,0,0,100,0Zm50.168,76.513v13.54a47.249,47.249,0,0,1-28.912-9.825l.1,41.677a34.911,34.911,0,0,1-10.473,24.844,35.64,35.64,0,0,1-19.909,9.957,36.7,36.7,0,0,1-5.377.4,35.759,35.759,0,0,1-21.918-7.41,36.359,36.359,0,0,1-3.369-2.942,35.04,35.04,0,0,1-1.582-48.318A35.756,35.756,0,0,1,85.592,86.414a36.7,36.7,0,0,1,5.377.4v18.933a16.857,16.857,0,1,0,11.595,16.016l.022-27.909V42.9h18.647a28.863,28.863,0,0,0,28.881,28.595s.058,0,.054,0v5.024Z" fill="currentColor" /></svg>
                            </a>
                        </li>
                        <li className="Linkedin">
                            <a href="https://www.linkedin.com/company/electricmagazin" target="_blank" rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><path d="M100,0A100,100,0,1,0,200,100,100.011,100.011,0,0,0,100,0ZM70.941,151.172H46.587V77.9H70.941ZM58.765,67.9h-.159c-8.173,0-13.458-5.626-13.458-12.657,0-7.19,5.447-12.66,13.779-12.66s13.458,5.47,13.617,12.66C72.543,62.27,67.258,67.9,58.765,67.9Zm99.994,83.276H134.407v-39.2c0-9.851-3.526-16.57-12.338-16.57-6.728,0-10.735,4.532-12.5,8.907a16.678,16.678,0,0,0-.8,5.943v40.918H84.419s.319-66.4,0-73.271h24.353V88.275c3.236-4.993,9.027-12.094,21.948-12.094,16.023,0,28.038,10.472,28.038,32.977Zm0,0" fill="currentColor" /></svg>
                            </a>
                        </li>
                    </Nav>
                </div>

                <div className="flex flex-ai-e">
                    <div className="w-100 op-50 Footer-copyinfo hide-med">
                        <h6 className="mb-1">©{(new Date().getFullYear())} {props.influencer ? props.influencer.name + ' x Electricmagazin' : 'ElectricMagazin'}</h6>
                        <h6>
                            *All Preise inkl. {country && country.tax ? country.tax + '%' : ''} Umsatzsteur & ohne Förderungen!
                        </h6>
                    </div>
                    <div className="flex flex-jc-e w-100 Footer-legal">
                        <Nav type="horizontal">
                            <li className="mr-2">
                                <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "impressum"}>
                                    <h6>Impressum</h6>
                                </a>
                            </li>
                            <li className="mr-2">
                                <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "datenschutzrichtlinien"}>
                                    <h6>Datenschutzrichtlinien</h6>
                                </a>
                            </li>
                            <li>
                                <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "nutzungsbedingungen"}>
                                    <h6>Nutzungsbedingungen</h6>
                                </a>
                            </li>
                        </Nav>
                    </div>
                </div>
            </Wrapper>
        </footer >
    );
}

export default Footer;