import { LazyLoadComponent } from 'react-lazy-load-image-component';

import React, { useState, useEffect } from 'react';

import { // Components
    Wrapper
} from '../components';

import { // Functions.
    countryUrl
} from "../../functions/functions";


function CoverNew(props: any) {
    const [searchParam, setSearchParam] = useState<string>('');

    return (
        <div className={"CoverNew" + (props.className ? " " + props.className : "")}>
            <Wrapper>
                <div className="CoverNew-search">
                    <div className="CoverNew-search-elem">
                        <input
                            type="text"
                            placeholder="Wonach suchen Sie?"
                            value={searchParam}
                            onChange={(e) => setSearchParam(e.target.value)} />
                        <a href="/">
                            <div className="CoverNew-search-elem-submit">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.5 25.5"><path d="M15.853,16.56a9.506,9.506,0,1,1,.707-.707L24,23.293,23.293,24l-7.44-7.44ZM9.5,1A8.5,8.5,0,1,1,1,9.5,8.5,8.5,0,0,1,9.5,1Z" transform="translate(0.75 0.75)" fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" fill-rule="evenodd" /></svg>
                            </div>
                        </a>
                    </div>
                </div>

                <div className="CoverNew-txt">
                    <div className="CoverNew-headers">
                        <h1 className="mb-0-5">
                            Das <a className="c-base strong" href={countryUrl({ loc: props.loc, inf: props.inf }) + "pkw"}>Elektroauto</a> Kaufs- & Vergleichsportal!
                        </h1>
                        <p className="mb-2">
                            Finden und Bestellen von Ihrem 🛋 aus!
                        </p>
                    </div>

                    <div className="CoverNew-btns mb-5">
                        <div>
                            <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "vergleichen"}>
                                <button className="uppercase btn-base">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M0 168v-16c0-13.255 10.745-24 24-24h360V80c0-21.367 25.899-32.042 40.971-16.971l80 80c9.372 9.373 9.372 24.569 0 33.941l-80 80C409.956 271.982 384 261.456 384 240v-48H24c-13.255 0-24-10.745-24-24zm488 152H128v-48c0-21.314-25.862-32.08-40.971-16.971l-80 80c-9.372 9.373-9.372 24.569 0 33.941l80 80C102.057 463.997 128 453.437 128 432v-48h360c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24z"></path></svg>
                                    Jetzt vergleichen
                                </button>
                            </a>
                        </div>
                        <div>
                            <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "pkw"}>
                                <button className="btn-outline btn-outline-base">
                                    Elektroautos entdecken
                                </button>
                            </a>
                        </div>
                    </div>
                </div>

                {/*<div className="CoverNew-img">
                    <LazyLoadComponent>
                        <picture>
                            <source
                                srcSet={(props.localhost ? '' : '/static') + '/media/covers/front-large.png'}
                                media="(min-width: 990px)" />
                            <source
                                srcSet={(props.localhost ? '' : '/static') + '/media/covers/front-large.png'}
                                media="(min-width: 640px)" />
                            <img
                                className="w-100 h-100"
                                srcSet={(props.localhost ? '' : '/static') + '/media/covers/front-large.png'}
                                alt="Elektroauto Polestar 2 in Weiß." />
                        </picture>
                    </LazyLoadComponent>
                </div>*/}
            </Wrapper>

            <div className="CoverNew-img">
                <div className="CoverNew-img-left">.</div>
                <div className="CoverNew-img-right">
                    <LazyLoadComponent>
                        <picture className="w-100">
                            <source
                                srcSet={(props.localhost ? '' : '/static') + '/media/covers/front-large.png'}
                                media="(min-width: 990px)" />
                            <source
                                srcSet={(props.localhost ? '' : '/static') + '/media/covers/front-large.png'}
                                media="(min-width: 640px)" />
                            <img
                                className="w-100 h-100"
                                srcSet={(props.localhost ? '' : '/static') + '/media/covers/front-large.png'}
                                alt="Elektroauto Polestar 2 in Weiß." />
                        </picture>
                    </LazyLoadComponent>
                </div>
            </div>
        </div>
    );
}

export default CoverNew;