import { axiosInstanceJWT, axiosLocalErrorHandling } from '../axios';


export const deleteReview = (props: any) => {
    if (props.review) {
        axiosInstanceJWT.delete('backend/reviews/' + props.review.id + '/')
            .then(function (response: any) {
                if (props.setNotification) { props.setNotification("Bewertung erfolgreich gelöscht!"); }
                window.location.pathname = '/dashboard/reviews';
            })
            .catch(function (error: any) {
                if (props.setNotification) { props.setNotification("Ups, bitte versuche es nocheinmal!"); }
                axiosLocalErrorHandling({ error: error, function: "deleteReview" });
            })
    }
}