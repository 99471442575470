import React from 'react';

function Rating(props: any) {
    return (
        <div className={"Rating" + (props.className ? " " + props.className : "")}>
            <React.StrictMode>
                <input 
                    id={props.name ? props.name + '1' : ''} name={props.name ? props.name : ''} type="radio" 
                    value={1} defaultChecked={props.selected && parseInt(props.selected) === 1 ? true : false}
                    onChange={props.onChange} disabled={props.disabled ? true : false} />
                <input 
                    id={props.name ? props.name + '2' : ''} name={props.name ? props.name : ''} type="radio" 
                    value={2} defaultChecked={props.selected && parseInt(props.selected) === 2 ? true : false}
                    onChange={props.onChange} disabled={props.disabled ? true : false} />
                <input 
                    id={props.name ? props.name + '3' : ''} name={props.name ? props.name : ''} type="radio" 
                    value={3} defaultChecked={props.selected && parseInt(props.selected) === 3 ? true : false}
                    onChange={props.onChange} disabled={props.disabled ? true : false} />
                <input 
                    id={props.name ? props.name + '4' : ''} name={props.name ? props.name : ''} type="radio" 
                    value={4} defaultChecked={props.selected && parseInt(props.selected) === 4 ? true : false}
                    onChange={props.onChange} disabled={props.disabled ? true : false} />
                <input 
                    id={props.name ? props.name + '5' : ''} name={props.name ? props.name : ''} type="radio" 
                    value={5} defaultChecked={props.selected && parseInt(props.selected) === 5 ? true : false}
                    onChange={props.onChange} disabled={props.disabled ? true : false} />
                <label htmlFor={props.name ? props.name + '1' : ''}>
                    {props.type && props.type === "block" ? 
                        <div className={"Rating-block" + (props.selected && parseInt(props.selected) > 0 ? " Rating-block-active" : "")}></div>
                        : <svg className={"Rating-star" + (props.selected && parseInt(props.selected) > 0 ? " Rating-star-active" : "")} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 208.279 200"><path d="M86.9,14.552,65.117,58.669,16.433,65.76A19.192,19.192,0,0,0,.954,78.841L.935,78.9A19.226,19.226,0,0,0,5.809,98.584s35.249,34.3,35.249,34.3l-8.352,48.461a19.051,19.051,0,0,0,4.2,15.6,6.463,6.463,0,0,0,.45.486,19.493,19.493,0,0,0,14.2,6.427h.106A18.908,18.908,0,0,0,60.63,201.6l43.515-22.871,43.534,22.888a19.224,19.224,0,0,0,8.883,2.231h.119a19.534,19.534,0,0,0,14.693-6.885l.023-.027a18.985,18.985,0,0,0,4.157-15.574l-8.295-48.452,35.215-34.323a19.248,19.248,0,0,0,4.873-19.691v0a19.259,19.259,0,0,0-15.506-13.1l-48.691-7.109-21.78-44.1A19.174,19.174,0,0,0,104.1,3.864,18.955,18.955,0,0,0,86.9,14.552ZM192.708,83.629v0a3.845,3.845,0,0,1-.973,3.934l-38.112,37.145a7.7,7.7,0,0,0-2.214,6.808l8.978,52.436.019.1a3.6,3.6,0,0,1-.772,2.955,4.138,4.138,0,0,1-3.043,1.444,3.839,3.839,0,0,1-1.729-.448l-47.14-24.784a7.691,7.691,0,0,0-7.158,0L53.339,188.051a3.518,3.518,0,0,1-1.607.427,4.118,4.118,0,0,1-3.049-1.452l-.016.01a3.689,3.689,0,0,1-.809-3.026L56.907,131.5a7.694,7.694,0,0,0-2.216-6.819L16.537,87.557a3.82,3.82,0,0,1-.965-3.923l.018-.056a3.789,3.789,0,0,1,3.06-2.594L71.333,73.31A7.688,7.688,0,0,0,77.122,69.1l23.57-47.741.046-.094a3.547,3.547,0,0,1,3.245-2.02h.161a3.775,3.775,0,0,1,3.418,2.114l23.581,47.748a7.692,7.692,0,0,0,5.786,4.205l52.679,7.692a3.852,3.852,0,0,1,3.1,2.622Zm-95.414-21.5a7.693,7.693,0,1,1,13.8-6.792l18.217,36.923a7.693,7.693,0,1,1-13.8,6.792L97.294,62.128Z" transform="translate(0 -3.863)" fill="currentColor" fill-rule="evenodd"/></svg>}
                </label>
                <label htmlFor={props.name ? props.name + '2' : ''}>
                    {props.type && props.type === "block" ? 
                        <div className={"Rating-block" + (props.selected && parseInt(props.selected) > 1 ? " Rating-block-active" : "")}></div>
                        : <svg className={"Rating-star" + (props.selected && parseInt(props.selected) > 1 ? " Rating-star-active" : "")} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 208.279 200"><path d="M86.9,14.552,65.117,58.669,16.433,65.76A19.192,19.192,0,0,0,.954,78.841L.935,78.9A19.226,19.226,0,0,0,5.809,98.584s35.249,34.3,35.249,34.3l-8.352,48.461a19.051,19.051,0,0,0,4.2,15.6,6.463,6.463,0,0,0,.45.486,19.493,19.493,0,0,0,14.2,6.427h.106A18.908,18.908,0,0,0,60.63,201.6l43.515-22.871,43.534,22.888a19.224,19.224,0,0,0,8.883,2.231h.119a19.534,19.534,0,0,0,14.693-6.885l.023-.027a18.985,18.985,0,0,0,4.157-15.574l-8.295-48.452,35.215-34.323a19.248,19.248,0,0,0,4.873-19.691v0a19.259,19.259,0,0,0-15.506-13.1l-48.691-7.109-21.78-44.1A19.174,19.174,0,0,0,104.1,3.864,18.955,18.955,0,0,0,86.9,14.552ZM192.708,83.629v0a3.845,3.845,0,0,1-.973,3.934l-38.112,37.145a7.7,7.7,0,0,0-2.214,6.808l8.978,52.436.019.1a3.6,3.6,0,0,1-.772,2.955,4.138,4.138,0,0,1-3.043,1.444,3.839,3.839,0,0,1-1.729-.448l-47.14-24.784a7.691,7.691,0,0,0-7.158,0L53.339,188.051a3.518,3.518,0,0,1-1.607.427,4.118,4.118,0,0,1-3.049-1.452l-.016.01a3.689,3.689,0,0,1-.809-3.026L56.907,131.5a7.694,7.694,0,0,0-2.216-6.819L16.537,87.557a3.82,3.82,0,0,1-.965-3.923l.018-.056a3.789,3.789,0,0,1,3.06-2.594L71.333,73.31A7.688,7.688,0,0,0,77.122,69.1l23.57-47.741.046-.094a3.547,3.547,0,0,1,3.245-2.02h.161a3.775,3.775,0,0,1,3.418,2.114l23.581,47.748a7.692,7.692,0,0,0,5.786,4.205l52.679,7.692a3.852,3.852,0,0,1,3.1,2.622Zm-95.414-21.5a7.693,7.693,0,1,1,13.8-6.792l18.217,36.923a7.693,7.693,0,1,1-13.8,6.792L97.294,62.128Z" transform="translate(0 -3.863)" fill="currentColor" fill-rule="evenodd"/></svg>}
                </label>
                <label htmlFor={props.name ? props.name + '3' : ''}>
                    {props.type && props.type === "block" ? 
                        <div className={"Rating-block" + (props.selected && parseInt(props.selected) > 2 ? " Rating-block-active" : "")}></div>
                        : <svg className={"Rating-star" + (props.selected && parseInt(props.selected) > 2 ? " Rating-star-active" : "")} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 208.279 200"><path d="M86.9,14.552,65.117,58.669,16.433,65.76A19.192,19.192,0,0,0,.954,78.841L.935,78.9A19.226,19.226,0,0,0,5.809,98.584s35.249,34.3,35.249,34.3l-8.352,48.461a19.051,19.051,0,0,0,4.2,15.6,6.463,6.463,0,0,0,.45.486,19.493,19.493,0,0,0,14.2,6.427h.106A18.908,18.908,0,0,0,60.63,201.6l43.515-22.871,43.534,22.888a19.224,19.224,0,0,0,8.883,2.231h.119a19.534,19.534,0,0,0,14.693-6.885l.023-.027a18.985,18.985,0,0,0,4.157-15.574l-8.295-48.452,35.215-34.323a19.248,19.248,0,0,0,4.873-19.691v0a19.259,19.259,0,0,0-15.506-13.1l-48.691-7.109-21.78-44.1A19.174,19.174,0,0,0,104.1,3.864,18.955,18.955,0,0,0,86.9,14.552ZM192.708,83.629v0a3.845,3.845,0,0,1-.973,3.934l-38.112,37.145a7.7,7.7,0,0,0-2.214,6.808l8.978,52.436.019.1a3.6,3.6,0,0,1-.772,2.955,4.138,4.138,0,0,1-3.043,1.444,3.839,3.839,0,0,1-1.729-.448l-47.14-24.784a7.691,7.691,0,0,0-7.158,0L53.339,188.051a3.518,3.518,0,0,1-1.607.427,4.118,4.118,0,0,1-3.049-1.452l-.016.01a3.689,3.689,0,0,1-.809-3.026L56.907,131.5a7.694,7.694,0,0,0-2.216-6.819L16.537,87.557a3.82,3.82,0,0,1-.965-3.923l.018-.056a3.789,3.789,0,0,1,3.06-2.594L71.333,73.31A7.688,7.688,0,0,0,77.122,69.1l23.57-47.741.046-.094a3.547,3.547,0,0,1,3.245-2.02h.161a3.775,3.775,0,0,1,3.418,2.114l23.581,47.748a7.692,7.692,0,0,0,5.786,4.205l52.679,7.692a3.852,3.852,0,0,1,3.1,2.622Zm-95.414-21.5a7.693,7.693,0,1,1,13.8-6.792l18.217,36.923a7.693,7.693,0,1,1-13.8,6.792L97.294,62.128Z" transform="translate(0 -3.863)" fill="currentColor" fill-rule="evenodd"/></svg>}
                </label>
                <label htmlFor={props.name ? props.name + '4' : ''}>
                    {props.type && props.type === "block" ? 
                        <div className={"Rating-block" + (props.selected && parseInt(props.selected) > 3 ? " Rating-block-active" : "")}></div>
                        : <svg className={"Rating-star" + (props.selected && parseInt(props.selected) > 3 ? " Rating-star-active" : "")} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 208.279 200"><path d="M86.9,14.552,65.117,58.669,16.433,65.76A19.192,19.192,0,0,0,.954,78.841L.935,78.9A19.226,19.226,0,0,0,5.809,98.584s35.249,34.3,35.249,34.3l-8.352,48.461a19.051,19.051,0,0,0,4.2,15.6,6.463,6.463,0,0,0,.45.486,19.493,19.493,0,0,0,14.2,6.427h.106A18.908,18.908,0,0,0,60.63,201.6l43.515-22.871,43.534,22.888a19.224,19.224,0,0,0,8.883,2.231h.119a19.534,19.534,0,0,0,14.693-6.885l.023-.027a18.985,18.985,0,0,0,4.157-15.574l-8.295-48.452,35.215-34.323a19.248,19.248,0,0,0,4.873-19.691v0a19.259,19.259,0,0,0-15.506-13.1l-48.691-7.109-21.78-44.1A19.174,19.174,0,0,0,104.1,3.864,18.955,18.955,0,0,0,86.9,14.552ZM192.708,83.629v0a3.845,3.845,0,0,1-.973,3.934l-38.112,37.145a7.7,7.7,0,0,0-2.214,6.808l8.978,52.436.019.1a3.6,3.6,0,0,1-.772,2.955,4.138,4.138,0,0,1-3.043,1.444,3.839,3.839,0,0,1-1.729-.448l-47.14-24.784a7.691,7.691,0,0,0-7.158,0L53.339,188.051a3.518,3.518,0,0,1-1.607.427,4.118,4.118,0,0,1-3.049-1.452l-.016.01a3.689,3.689,0,0,1-.809-3.026L56.907,131.5a7.694,7.694,0,0,0-2.216-6.819L16.537,87.557a3.82,3.82,0,0,1-.965-3.923l.018-.056a3.789,3.789,0,0,1,3.06-2.594L71.333,73.31A7.688,7.688,0,0,0,77.122,69.1l23.57-47.741.046-.094a3.547,3.547,0,0,1,3.245-2.02h.161a3.775,3.775,0,0,1,3.418,2.114l23.581,47.748a7.692,7.692,0,0,0,5.786,4.205l52.679,7.692a3.852,3.852,0,0,1,3.1,2.622Zm-95.414-21.5a7.693,7.693,0,1,1,13.8-6.792l18.217,36.923a7.693,7.693,0,1,1-13.8,6.792L97.294,62.128Z" transform="translate(0 -3.863)" fill="currentColor" fill-rule="evenodd"/></svg>}
                </label>
                <label htmlFor={props.name ? props.name + '5' : ''}>
                    {props.type && props.type === "block" ? 
                        <div className={"Rating-block" + (props.selected && parseInt(props.selected) > 4 ? " Rating-block-active" : "")}></div>
                        : <svg className={"Rating-star" + (props.selected && parseInt(props.selected) > 4 ? " Rating-star-active" : "")} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 208.279 200"><path d="M86.9,14.552,65.117,58.669,16.433,65.76A19.192,19.192,0,0,0,.954,78.841L.935,78.9A19.226,19.226,0,0,0,5.809,98.584s35.249,34.3,35.249,34.3l-8.352,48.461a19.051,19.051,0,0,0,4.2,15.6,6.463,6.463,0,0,0,.45.486,19.493,19.493,0,0,0,14.2,6.427h.106A18.908,18.908,0,0,0,60.63,201.6l43.515-22.871,43.534,22.888a19.224,19.224,0,0,0,8.883,2.231h.119a19.534,19.534,0,0,0,14.693-6.885l.023-.027a18.985,18.985,0,0,0,4.157-15.574l-8.295-48.452,35.215-34.323a19.248,19.248,0,0,0,4.873-19.691v0a19.259,19.259,0,0,0-15.506-13.1l-48.691-7.109-21.78-44.1A19.174,19.174,0,0,0,104.1,3.864,18.955,18.955,0,0,0,86.9,14.552ZM192.708,83.629v0a3.845,3.845,0,0,1-.973,3.934l-38.112,37.145a7.7,7.7,0,0,0-2.214,6.808l8.978,52.436.019.1a3.6,3.6,0,0,1-.772,2.955,4.138,4.138,0,0,1-3.043,1.444,3.839,3.839,0,0,1-1.729-.448l-47.14-24.784a7.691,7.691,0,0,0-7.158,0L53.339,188.051a3.518,3.518,0,0,1-1.607.427,4.118,4.118,0,0,1-3.049-1.452l-.016.01a3.689,3.689,0,0,1-.809-3.026L56.907,131.5a7.694,7.694,0,0,0-2.216-6.819L16.537,87.557a3.82,3.82,0,0,1-.965-3.923l.018-.056a3.789,3.789,0,0,1,3.06-2.594L71.333,73.31A7.688,7.688,0,0,0,77.122,69.1l23.57-47.741.046-.094a3.547,3.547,0,0,1,3.245-2.02h.161a3.775,3.775,0,0,1,3.418,2.114l23.581,47.748a7.692,7.692,0,0,0,5.786,4.205l52.679,7.692a3.852,3.852,0,0,1,3.1,2.622Zm-95.414-21.5a7.693,7.693,0,1,1,13.8-6.792l18.217,36.923a7.693,7.693,0,1,1-13.8,6.792L97.294,62.128Z" transform="translate(0 -3.863)" fill="currentColor" fill-rule="evenodd"/></svg>}
                </label>
            </React.StrictMode>
        </div>
        );
}

export default Rating;