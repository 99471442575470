import { axiosInstanceJWT, axiosLocalErrorHandling } from '../axios';


export const putReview = (props: any) => {
    let reviewData: any = {}
    var errorCount: number = 0

    // Meta data.
    let reviewMetaDataData: any = {}
    const reviewMetaDataIdInput: any = document.getElementById('reviewMetaDataId');
    const reviewMetaDataId = (reviewMetaDataIdInput && reviewMetaDataIdInput.value ? reviewMetaDataIdInput.value : '');
    if (reviewMetaDataId) {
        reviewMetaDataData.id = reviewMetaDataId;
    } else {
        reviewMetaDataData.id = 0;
    }
    const reviewStatusInput: any = document.getElementById('reviewMetaDataStatus');
    const reviewStatusId = (reviewStatusInput && reviewStatusInput.checked ? reviewStatusInput.checked : '');
    if (reviewStatusId) {
        reviewMetaDataData.status = 2;
    } else {
        reviewMetaDataData.status = 1;
    }
    if (Object.keys(reviewMetaDataData).length > 0) {
        reviewData.meta_data = reviewMetaDataData;
    }


    // Review vehicles.
    let reviewVehiclesData: any = []
    const reviewVehicleNew: any = document.querySelector('input[name="reviewVehicleNew"]:checked');
    if (reviewVehicleNew) {
        const reviewVehicleId = reviewVehicleNew.value ? reviewVehicleNew.value : undefined;
        if (reviewVehicleId && reviewVehiclesData.indexOf(reviewVehicleId) === -1) {
            reviewVehiclesData.push({ id: reviewVehicleId });
        }
    }
    const reviewVehicles: any = document.getElementsByName("reviewVehicle");
    if (reviewVehicles.length) {
        for (let i = 0; i < reviewVehicles.length; i++) {
            const reviewVehicleId = reviewVehicles[i] && reviewVehicles[i].value ? reviewVehicles[i].value : undefined;
            if (reviewVehicleId && reviewVehiclesData.indexOf(reviewVehicleId) === -1) {
                reviewVehiclesData.push({ id: reviewVehicleId });
            }
        }
    }
    if (reviewVehiclesData.length > 0) {
        reviewData.cars = reviewVehiclesData;
    }


    // Review pros.
    let reviewProsData: any = []
    const reviewPros: any = document.getElementsByName("reviewPro");
    if (reviewPros.length) {
        for (let i = 0; i < reviewPros.length; i++) {
            const reviewProId = reviewPros[i] && reviewPros[i].getAttribute("data-id") ? reviewPros[i].getAttribute("data-id") : undefined;
            const reviewProText = reviewPros[i] && reviewPros[i].value ? reviewPros[i].value : undefined;
            const reviewProOrder = reviewPros[i] && reviewPros[i].getAttribute("data-order") ? reviewPros[i].getAttribute("data-order") : undefined;
            if (reviewProText) {
                reviewProsData.push({ id: reviewProId, text: reviewProText, order: reviewProOrder });
            }
        }
    }
    if (reviewProsData.length > 0) {
        reviewData.pros = reviewProsData;
    }

    // Review cons.
    let reviewConsData: any = []
    const reviewCons: any = document.getElementsByName("reviewCon");
    if (reviewCons.length) {
        for (let i = 0; i < reviewCons.length; i++) {
            const reviewConId = reviewCons[i] && reviewCons[i].getAttribute("data-id") ? reviewCons[i].getAttribute("data-id") : undefined;
            const reviewConText = reviewCons[i] && reviewCons[i].value ? reviewCons[i].value : undefined;
            const reviewConOrder = reviewCons[i] && reviewCons[i].getAttribute("data-order") ? reviewCons[i].getAttribute("data-order") : undefined;
            if (reviewConText) {
                reviewConsData.push({ id: reviewConId, text: reviewConText, order: reviewConOrder });
            }
        }
    }
    if (reviewConsData.length > 0) {
        reviewData.cons = reviewConsData;
    }

    // Ratings.
    let reviewRatingsData: any = {}
    const reviewRatingsIdInput: any = document.getElementById('reviewRatingsId');
    if (reviewRatingsIdInput) {
        const reviewRatingsId = (reviewRatingsIdInput && reviewRatingsIdInput.value ? reviewRatingsIdInput.value : '');
        reviewRatingsData.id = reviewRatingsId;
    }

    // Rating Driving.
    const reviewRatingDrivingInput: any = document.querySelector('input[name="reviewRatingDriveing"]:checked');
    const reviewRatingDriving = (reviewRatingDrivingInput && reviewRatingDrivingInput.value ? reviewRatingDrivingInput.value : '');
    if (reviewRatingDriving) {
        reviewRatingsData.driving = reviewRatingDriving;
    }
    // Rating Comfort.
    const reviewRatingComfortInput: any = document.querySelector('input[name="reviewRatingComfort"]:checked');
    const reviewRatingComfort = (reviewRatingComfortInput && reviewRatingComfortInput.value ? reviewRatingComfortInput.value : '');
    if (reviewRatingComfort) {
        reviewRatingsData.comfort = reviewRatingComfort;
    }
    // Rating Quality.
    const reviewRatingQualityInput: any = document.querySelector('input[name="reviewRatingQuality"]:checked');
    const reviewRatingQuality = (reviewRatingQualityInput && reviewRatingQualityInput.value ? reviewRatingQualityInput.value : '');
    if (reviewRatingQuality) {
        reviewRatingsData.quality = reviewRatingQuality;
    }
    // Rating Price.
    const reviewRatingPriceInput: any = document.querySelector('input[name="reviewRatingPrice"]:checked');
    const reviewRatingPrice = (reviewRatingPriceInput && reviewRatingPriceInput.value ? reviewRatingPriceInput.value : '');
    if (reviewRatingPrice) {
        reviewRatingsData.price = reviewRatingPrice;
    }
    // Rating Overall.
    const reviewRatingOverallInput: any = document.querySelector('input[name="reviewRatingOverall"]:checked');
    const reviewRatingOverall = (reviewRatingOverallInput && reviewRatingOverallInput.value ? reviewRatingOverallInput.value : '');
    if (reviewRatingOverall) {
        reviewRatingsData.overall = reviewRatingOverall;
    }
    if (Object.keys(reviewRatingsData).length > 0) {
        reviewData.ratings = reviewRatingsData;
    }

    // Youtube.
    const reviewYoutubeUrlInput: any = document.getElementById('reviewYoutubeUrl');
    if (reviewYoutubeUrlInput) {
        const reviewYoutubeUrl = (reviewYoutubeUrlInput && reviewYoutubeUrlInput.value ? reviewYoutubeUrlInput.value : '');
        const reviewYoutubeUrlInfo: any = document.getElementById('reviewYoutubeUrlInfo');
        if (reviewYoutubeUrl && !reviewYoutubeUrl.startsWith('https://youtu.be/')) {
            errorCount = errorCount + 1;
            reviewYoutubeUrlInfo.classList.remove("btn-info-placeholder");
            reviewYoutubeUrlInfo.classList.add("btn-info-second");
            reviewYoutubeUrlInfo.innerHTML = "Falscher Link! Muss mit https://youtu.be/ beginnen!"
        } else {
            reviewYoutubeUrlInfo.classList.add("btn-info-placeholder");
            reviewYoutubeUrlInfo.classList.remove("btn-info-second");
            reviewYoutubeUrlInfo.innerHTML = "optional"
        }
        reviewData.youtube = reviewYoutubeUrl;
    }

    // Overview
    let reviewOverviewData: any = []
    const reviewOverviewBuildingBlockInput: any = document.getElementsByName("overviewBuildingBlock");
    if (reviewOverviewBuildingBlockInput.length) {
        for (let i = 0; i < reviewOverviewBuildingBlockInput.length; i++) {
            const reviewOverviewBuildingBlockText = (reviewOverviewBuildingBlockInput[i] && reviewOverviewBuildingBlockInput[i].value ? reviewOverviewBuildingBlockInput[i].value : '');
            const reviewOverviewBuildingBlockId = (reviewOverviewBuildingBlockInput[i] && reviewOverviewBuildingBlockInput[i].getAttribute("data-id") ? reviewOverviewBuildingBlockInput[i].getAttribute("data-id") : undefined)
            const reviewOverviewBuildingBlockType = (reviewOverviewBuildingBlockInput[i] && reviewOverviewBuildingBlockInput[i].getAttribute("data-type") ? reviewOverviewBuildingBlockInput[i].getAttribute("data-type") : undefined);
            const reviewOverviewBuildingBlockOrder = (reviewOverviewBuildingBlockInput[i] && reviewOverviewBuildingBlockInput[i].getAttribute("data-order") ? reviewOverviewBuildingBlockInput[i].getAttribute("data-order") : undefined);
            reviewOverviewData.push({ id: reviewOverviewBuildingBlockId, type: reviewOverviewBuildingBlockType, text: reviewOverviewBuildingBlockText, order: reviewOverviewBuildingBlockOrder });
        }
    }
    if (reviewOverviewData.length > 0) {
        reviewData.overview = reviewOverviewData;
    }

    // Driving
    let reviewDrivingData: any = []
    const reviewDrivingBuildingBlockInput: any = document.getElementsByName("drivingBuildingBlock");
    if (reviewDrivingBuildingBlockInput.length) {
        for (let i = 0; i < reviewDrivingBuildingBlockInput.length; i++) {
            const reviewDrivingBuildingBlockText = (reviewDrivingBuildingBlockInput[i] && reviewDrivingBuildingBlockInput[i].value ? reviewDrivingBuildingBlockInput[i].value : '');
            const reviewDrivingBuildingBlockId = (reviewDrivingBuildingBlockInput[i] && reviewDrivingBuildingBlockInput[i].getAttribute("data-id") ? reviewDrivingBuildingBlockInput[i].getAttribute("data-id") : undefined)
            const reviewDrivingBuildingBlockType = (reviewDrivingBuildingBlockInput[i] && reviewDrivingBuildingBlockInput[i].getAttribute("data-type") ? reviewDrivingBuildingBlockInput[i].getAttribute("data-type") : undefined);
            const reviewDrivingBuildingBlockOrder = (reviewDrivingBuildingBlockInput[i] && reviewDrivingBuildingBlockInput[i].getAttribute("data-order") ? reviewDrivingBuildingBlockInput[i].getAttribute("data-order") : undefined);
            reviewDrivingData.push({ id: reviewDrivingBuildingBlockId, type: reviewDrivingBuildingBlockType, text: reviewDrivingBuildingBlockText, order: reviewDrivingBuildingBlockOrder });
        }
    }
    if (reviewDrivingData.length > 0) {
        reviewData.driving = reviewDrivingData;
    }

    // Design
    let reviewDesignData: any = []
    const reviewDesignBuildingBlockInput: any = document.getElementsByName("designBuildingBlock");
    if (reviewDesignBuildingBlockInput.length) {
        for (let i = 0; i < reviewDesignBuildingBlockInput.length; i++) {
            const reviewDesignBuildingBlockText = (reviewDesignBuildingBlockInput[i] && reviewDesignBuildingBlockInput[i].value ? reviewDesignBuildingBlockInput[i].value : '');
            const reviewDesignBuildingBlockId = (reviewDesignBuildingBlockInput[i] && reviewDesignBuildingBlockInput[i].getAttribute("data-id") ? reviewDesignBuildingBlockInput[i].getAttribute("data-id") : undefined)
            const reviewDesignBuildingBlockType = (reviewDesignBuildingBlockInput[i] && reviewDesignBuildingBlockInput[i].getAttribute("data-type") ? reviewDesignBuildingBlockInput[i].getAttribute("data-type") : undefined);
            const reviewDesignBuildingBlockOrder = (reviewDesignBuildingBlockInput[i] && reviewDesignBuildingBlockInput[i].getAttribute("data-order") ? reviewDesignBuildingBlockInput[i].getAttribute("data-order") : undefined);
            reviewDesignData.push({ id: reviewDesignBuildingBlockId, type: reviewDesignBuildingBlockType, text: reviewDesignBuildingBlockText, order: reviewDesignBuildingBlockOrder });
        }
    }
    if (reviewDesignData.length > 0) {
        reviewData.design = reviewDesignData;
    }

    // Equipment
    let reviewEquipmentData: any = []
    const reviewEquipmentBuildingBlockInput: any = document.getElementsByName("equipmentBuildingBlock");
    if (reviewEquipmentBuildingBlockInput.length) {
        for (let i = 0; i < reviewEquipmentBuildingBlockInput.length; i++) {
            const reviewEquipmentBuildingBlockText = (reviewEquipmentBuildingBlockInput[i] && reviewEquipmentBuildingBlockInput[i].value ? reviewEquipmentBuildingBlockInput[i].value : '');
            const reviewEquipmentBuildingBlockId = (reviewEquipmentBuildingBlockInput[i] && reviewEquipmentBuildingBlockInput[i].getAttribute("data-id") ? reviewEquipmentBuildingBlockInput[i].getAttribute("data-id") : undefined)
            const reviewEquipmentBuildingBlockType = (reviewEquipmentBuildingBlockInput[i] && reviewEquipmentBuildingBlockInput[i].getAttribute("data-type") ? reviewEquipmentBuildingBlockInput[i].getAttribute("data-type") : undefined);
            const reviewEquipmentBuildingBlockOrder = (reviewEquipmentBuildingBlockInput[i] && reviewEquipmentBuildingBlockInput[i].getAttribute("data-order") ? reviewEquipmentBuildingBlockInput[i].getAttribute("data-order") : undefined);
            reviewEquipmentData.push({ id: reviewEquipmentBuildingBlockId, type: reviewEquipmentBuildingBlockType, text: reviewEquipmentBuildingBlockText, order: reviewEquipmentBuildingBlockOrder });
        }
    }
    if (reviewEquipmentData.length > 0) {
        reviewData.equipment = reviewEquipmentData;
    }

    // Verdict
    let reviewVerdictData: any = []
    const reviewVerdictBuildingBlockInput: any = document.getElementsByName("verdictBuildingBlock");
    if (reviewVerdictBuildingBlockInput.length) {
        for (let i = 0; i < reviewVerdictBuildingBlockInput.length; i++) {
            const reviewVerdictBuildingBlockText = (reviewVerdictBuildingBlockInput[i] && reviewVerdictBuildingBlockInput[i].value ? reviewVerdictBuildingBlockInput[i].value : '');
            const reviewVerdictBuildingBlockId = (reviewVerdictBuildingBlockInput[i] && reviewVerdictBuildingBlockInput[i].getAttribute("data-id") ? reviewVerdictBuildingBlockInput[i].getAttribute("data-id") : undefined)
            const reviewVerdictBuildingBlockType = (reviewVerdictBuildingBlockInput[i] && reviewVerdictBuildingBlockInput[i].getAttribute("data-type") ? reviewVerdictBuildingBlockInput[i].getAttribute("data-type") : undefined);
            const reviewVerdictBuildingBlockOrder = (reviewVerdictBuildingBlockInput[i] && reviewVerdictBuildingBlockInput[i].getAttribute("data-order") ? reviewVerdictBuildingBlockInput[i].getAttribute("data-order") : undefined);
            reviewVerdictData.push({ id: reviewVerdictBuildingBlockId, type: reviewVerdictBuildingBlockType, text: reviewVerdictBuildingBlockText, order: reviewVerdictBuildingBlockOrder });
        }
    }
    if (reviewVerdictData.length > 0) {
        reviewData.verdict = reviewVerdictData;
    }

    // Suggestion.
    let reviewSuggestionData: any = {}
    const reviewSuggestionIdInput: any = document.getElementById('reviewSuggestionId');
    const reviewSuggestionId = (reviewSuggestionIdInput && reviewSuggestionIdInput.value ? reviewSuggestionIdInput.value : '');
    if (reviewSuggestionId) { reviewSuggestionData.id = reviewSuggestionId; }
    // Suggestion Platform.
    const reviewSuggestionPlatformInput: any = document.querySelector('input[name="reviewSuggestionPlatform"]:checked');
    const reviewSuggestionPlatform = (reviewSuggestionPlatformInput && reviewSuggestionPlatformInput.value ? reviewSuggestionPlatformInput.value : '');
    reviewSuggestionData.platform = reviewSuggestionPlatform;
    // Suggestion Justification.
    if (reviewSuggestionId) {
        const reviewSuggestionJustificationInput: any = document.getElementById('reviewSuggestionJustification');
        const reviewSuggestionJustification = (reviewSuggestionJustificationInput && reviewSuggestionJustificationInput.value ? reviewSuggestionJustificationInput.value : '');
        reviewSuggestionData.justification = reviewSuggestionJustification;
    }
    if (reviewSuggestionId || reviewSuggestionPlatform) {
        reviewData.suggestion = reviewSuggestionData;
    }


    if (errorCount === 0 && Object.keys(reviewData).length > 0) {
        // Update existing review.
        if (props.review) {
            axiosInstanceJWT.put('backend/reviews/' + props.review.id + '/',
                reviewData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        accept: 'application/json',
                    }
                })
                .then(function (response: any) {
                    props.setNotification("Änderungen gespeichert!");
                    if (response && response.data && props.setReview) {
                        props.setReview(response.data);
                    }
                })
                .catch(function (error: any) {
                    props.setNotification("Ups, bitte versuche es nocheinmal!");
                    axiosLocalErrorHandling({ error: error, function: "putReview 1" });
                })

            // Create new review.
        } else {
            axiosInstanceJWT.post('backend/reviews/create/',
                reviewData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        accept: 'application/json',
                    }
                })
                .then(function (response: any) {
                    props.setNotification("Review erstellt!");
                    if (response && response.data) {
                        // props.setReview(response.data);
                        if (response.data.id) {
                            window.location.pathname = '/dashboard/reviews/' + response.data.id;
                        }
                    }
                })
                .catch(function (error: any) {
                    props.setNotification("Ups, bitte versuche es nocheinmal!");
                    axiosLocalErrorHandling({ error: error, function: "putReview 2" });
                })
        }
    }
}