export const compareButton = () => {
    if (window.location.pathname.length === 0) {
        /* const triggerShowComparisionButton: any = document.getElementById('triggerShowComparisionButton');

        if (triggerShowComparisionButton) {
            document.addEventListener('scroll', () => {
                const triggerHeight: number = triggerShowComparisionButton.getBoundingClientRect().top + triggerShowComparisionButton.getBoundingClientRect().height / 2;

                if (triggerHeight < 70) {
                    return true;
                } else {
                    return false;
                }
            })
        } */
        return true;

    } else if (window.location.pathname.includes('/vergleichen')) {
        return false;
        
    } else {
        return true;
    }
}