import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import React, { useState, useEffect } from 'react';

import { // Components.
    Notify
} from './components/components';

import { // Pages.
    Reviews, ReviewsCreateEdit,
    Settings, SettingsInfluencer, SettingsInfluencerBranding, SettingsUser, SettingsUserPassword,
    Testimonials, TestimonialsCreateEdit,
    NoMatch
} from './pages/pages';

import { // Functions.
    getInfluencer, getUser
} from "./functions/functions";


function AppBackend() {
    // Site wide vars.
    const [windowPathname] = useState<string>(window.location.pathname.toLowerCase());
    const [localhost] = useState<boolean>(window.location.hostname.includes("localhost") ? true : false);

    // Api data.
    const [user, setUser] = useState<any>(undefined);
    const [influencer, setInfluencer] = useState<any>(undefined);
    const [influencerFetched, setInfluencerFetched] = useState<boolean>(false);

    useEffect(() => {
        getUser({ setUser: setUser, windowPathname: windowPathname });
        getInfluencer({ setInfluencer: setInfluencer, });
        setTimeout(function () {
            setInfluencerFetched(true);
        }, 500);
    }, [windowPathname]);

    // Notifications.
    const [notification, setNotification] = useState<any>('');


    // Redirect if not influencer or business
    useEffect(() => {
        if (!localStorage.getItem('refresh_token')) {
            window.location.pathname = "/anmelden";
        } else if (user) {
            if (!user.influencer && !user.business) {
                window.location.pathname = "/account";
            }
        }
    }, [user]);


    return (
        <React.StrictMode>
            <Router>
                <Switch>
                    <Route // Reviews.
                        path={["/dashboard/reviews", "/dashboard/reviews/",]} exact
                        render={() => <Reviews
                            setNotification={setNotification}
                            windowPathname={windowPathname} localhost={localhost}
                            influencer={influencer} />} />

                    <Route // Reviews create and edit.
                        path={["/dashboard/reviews/:review", "/dashboard/reviews/:review/",]} exact
                        render={() => <ReviewsCreateEdit
                            setNotification={setNotification}
                            windowPathname={windowPathname} localhost={localhost}
                            user={user} influencer={influencer} />} />

                    <Route // Testimonials.
                        path={["/dashboard/testimonials", "/dashboard/testimonials/",]} exact
                        render={() => <Testimonials
                            setNotification={setNotification}
                            windowPathname={windowPathname} localhost={localhost}
                            influencer={influencer} />} />

                    <Route // Testimonials create and edit.
                        path={["/dashboard/testimonials/:testimonial", "/dashboard/testimonials/:testimonial/",]} exact
                        render={() => <TestimonialsCreateEdit
                            setNotification={setNotification}
                            windowPathname={windowPathname} localhost={localhost}
                            user={user} influencer={influencer} />} />

                    <Route // Settings.
                        path={["/dashboard", "/dashboard/", "/dashboard/settings", "/dashboard/settings/",]} exact
                        render={() => <Settings
                            setNotification={setNotification}
                            windowPathname={windowPathname} localhost={localhost}
                            user={user} influencer={influencer} setInfluencer={setInfluencer} influencerFetched={influencerFetched} />} />

                    <Route // Settings influencer.
                        path={["/dashboard/settings/influencer", "/dashboard/settings/influencer/",]} exact
                        render={() => <SettingsInfluencer
                            setNotification={setNotification}
                            windowPathname={windowPathname} localhost={localhost}
                            influencer={influencer} setInfluencer={setInfluencer} />} />

                    <Route // Settings user.
                        path={["/dashboard/settings/user", "/dashboard/settings/user/",]} exact
                        render={() => <SettingsUser
                            setNotification={setNotification}
                            windowPathname={windowPathname} localhost={localhost}
                            user={user} setUser={setUser} influencer={influencer} />} />

                    <Route // Settings user password.
                        path={["/dashboard/settings/user/change-password", "/dashboard/settings/user/change-password/",]} exact
                        render={() => <SettingsUserPassword
                            setNotification={setNotification}
                            windowPathname={windowPathname} localhost={localhost}
                            user={user} setUser={setUser} influencer={influencer} />} />

                    <Route // No match.
                        component={NoMatch}
                        path="*" />
                </Switch>

                <Notify notification={notification} setNotification={setNotification} />
            </Router>
        </React.StrictMode>
    );
}

export default AppBackend;