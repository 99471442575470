import { LazyLoadImage } from 'react-lazy-load-image-component';

import React, { useState, useEffect } from 'react';

import { // Components.
    Mobile,
    Nav, Dropdown,
    Wrapper
} from '../components';

import { // Functions.
    compareButton,
    countryUrl,
    postVehiclePopularity
} from '../../functions/functions';


function Header(props: any) {
    // Compare button.
    const [showCompareButton, setShowCompareButton] = useState<any>(false);

    useEffect(() => {
        setShowCompareButton(compareButton());
    }, [showCompareButton]);


    return (
        <React.StrictMode>
            <header className="Header" id="top">
                <div>
                    <Wrapper>
                        <div className="flex">
                            <Nav type="horizontal" className="hide display-xlg Header-nav-mobile">
                                <li>
                                    <a onClick={() => {
                                        const Mobile = document.getElementById('Mobile');
                                        if (Mobile) {
                                            document.body.classList.add('no-scroll');
                                            Mobile.classList.add('display');
                                        }
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.4 137.224"><path d="M11.435,165.812h171.53a11.436,11.436,0,0,0,0-22.871H11.435a11.436,11.436,0,0,0,0,22.871Zm0-57.177h171.53a11.435,11.435,0,0,0,0-22.87H11.435a11.435,11.435,0,1,0,0,22.87Zm0-57.176h171.53a11.436,11.436,0,0,0,0-22.871H11.435a11.436,11.436,0,0,0,0,22.871Z" transform="translate(0 -28.588)" fill="currentColor" fill-rule="evenodd" /></svg>
                                    </a>
                                </li>
                            </Nav>
                            <Nav type="horizontal" className="hide-xlg Header-nav-offer">
                                <li className="triggerBrandsDropdown">
                                    <Dropdown
                                        objects={props.allManufacturers} subObjects={props.allVehicles}
                                        loc={props.loc} country={props.country} inf={props.inf} influencer={props.influencer}
                                        localhost={props.localhost} />
                                </li>
                                {props.allReviewsCount ?
                                    <li>
                                        <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "bewertungen"}>
                                            <strong>Bewertungen</strong>
                                        </a>
                                    </li>
                                    : null}
                                {props.allTestimonialsCount ?
                                    <li>
                                        <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "erfahrungsberichte"}>
                                            <strong>Erfahrungsberichte</strong>
                                        </a>
                                    </li>
                                    : null}
                            </Nav>
                            <Nav type="horizontal" className="Header-nav-logo">
                                <li>
                                    <a href={countryUrl({ loc: props.loc, inf: props.inf })} className="flex">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 319.427 500"><g transform="translate(-35.104 0)"><path d="M325.526,7.21c1.669-1.541,5.2-4.56,8.583-5.934a14.781,14.781,0,0,1,14.324,1.237c4.642,3.189,6.862,8.282,5.862,14.5-.784,4.869-4.835,11.047-4.835,11.047L248.168,170.939,280.6,179.5a24.931,24.931,0,0,1,7.171,2.85,14.646,14.646,0,0,1,6.389,8.616,15.526,15.526,0,0,1-.926,9.851,30.747,30.747,0,0,1-4.511,6.89c-.129.141-.262.28-.4.414l-55.8,55.73,26.667,7.85-.208-.057a21.922,21.922,0,0,1,7.981,4.205,14.165,14.165,0,0,1,4.743,10.111,17.193,17.193,0,0,1-2.222,8.58A37.241,37.241,0,0,1,264.672,301c-.131.139-.267.275-.406.406L63.605,491.114l.836-.874s-7.276,7.441-11.682,8.927c-5.764,1.942-10.473.324-13.863-3.146a13.422,13.422,0,0,1-3.614-11.746c.707-4.769,6.073-12.78,6.073-12.78l-.612.905L142.391,305.962l-40.028-11.4-1.322-.463a25.681,25.681,0,0,1-7.181-4.637,15.588,15.588,0,0,1-4.586-8.673,16.184,16.184,0,0,1,1.474-9.272,28.175,28.175,0,0,1,4.8-6.728l.3-.291,53.547-50.988-21.312-6.222s-5.419-1.844-8.588-4.967a14.305,14.305,0,0,1-4.45-11.3,17.16,17.16,0,0,1,3.714-9.095,38.161,38.161,0,0,1,5.882-5.728L325.526,7.21Z" transform="translate(0 0)" fill="currentColor" /></g></svg>
                                        {props.influencer && props.influencer.logo ?
                                            <React.StrictMode>
                                                <p className="mx-0-5">x</p>
                                                <LazyLoadImage
                                                    effect="opacity"
                                                    src={props.influencer.logo}
                                                    alt="Elektroauto Influencer Logo" />
                                            </React.StrictMode>
                                            : null}
                                    </a>
                                </li>
                            </Nav>
                            <Nav type="horizontal-end" className="Header-nav-offer">
                                {showCompareButton ?
                                    <li className="hide-lg">
                                        <a
                                            href={props.vehicle && props.vehicle.href_compare ?
                                                countryUrl({ loc: props.loc, inf: props.inf }) + props.vehicle.href_compare
                                                : props.review && props.review.suggestion && props.review.suggestion.href_compare ?
                                                    countryUrl({ loc: props.loc, inf: props.inf }) + props.review.suggestion.href_compare
                                                    : props.testimonial && props.testimonial.href_compare ?
                                                        countryUrl({ loc: props.loc, inf: props.inf }) + props.testimonial.href_compare
                                                        : (countryUrl({ loc: props.loc, inf: props.inf }) + "vergleichen")}
                                            onMouseDown={(e: any) => {
                                                e.stopPropagation();
                                                if (props.vehicle && props.vehicle.meta_data) {
                                                    postVehiclePopularity({
                                                        meta_data: props.vehicle.meta_data,
                                                        type: 2,
                                                        country: props.country ? props.country.id : undefined,
                                                        influencer: props.influencer ? props.influencer.id : undefined,
                                                    });
                                                } else if (props.review && props.review.cars_meta_data) {
                                                    props.review.cars_meta_data.forEach((meta_data: any, index: number) => {
                                                        postVehiclePopularity({
                                                            meta_data: meta_data,
                                                            type: 2,
                                                            country: props.country ? props.country.id : undefined,
                                                            influencer: props.influencer ? props.influencer.id : undefined,
                                                        });
                                                    });
                                                }
                                            }}>
                                            <strong>
                                                {props.vehicle ?
                                                    'Fahrzeug vergleichen'
                                                    : props.review && props.review.suggestion && props.review.suggestion.href_compare ?
                                                        (props.review.cars ? props.review.cars : 'Fahrzeug') + ' vergleichen'
                                                        : props.testimonial && props.testimonial.href_compare ?
                                                            (props.testimonial.car_name ? props.testimonial.car_name : 'Fahrzeug') + ' vergleichen'
                                                            : 'Elektroautos vergleichen'}
                                            </strong>
                                        </a>
                                    </li>
                                    : null}
                                <li>
                                    <div className="Header-nav-profile">
                                        <div className="Header-nav-profile-trigger">
                                            <svg className="Header-nav-profile-trigger-menu" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.4 137.224"><path d="M11.435,165.812h171.53a11.436,11.436,0,0,0,0-22.871H11.435a11.436,11.436,0,0,0,0,22.871Zm0-57.177h171.53a11.435,11.435,0,0,0,0-22.87H11.435a11.435,11.435,0,1,0,0,22.87Zm0-57.176h171.53a11.436,11.436,0,0,0,0-22.871H11.435a11.436,11.436,0,0,0,0,22.871Z" transform="translate(0 -28.588)" fill="currentColor" fill-rule="evenodd" /></svg>
                                            <div className="Header-nav-profile-trigger-user">
                                                {props.user && props.user.data && props.user.data.profile_picture ?
                                                    <LazyLoadImage
                                                        effect="opacity"
                                                        src={props.user.data.profile_picture}
                                                        alt={(props.user.data.firstname ? props.user.data.firstname : '') + (props.user.data.lastname ? props.user.data.lastname : '') + ' Profilbild'} />
                                                    : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.4 194.4"><path d="M97.2,0h0C43.886.024.024,43.886,0,97.2H0c0,53.323,43.878,97.2,97.2,97.2s97.2-43.878,97.2-97.2S150.522,0,97.2,0Zm58.468,155.17a82.31,82.31,0,0,1-116.956-.009A16.257,16.257,0,0,1,46.8,151.9l.206-.023.2-.047c31.66-7.388,34.244-13.158,36.481-18.1l.083-.2a20.508,20.508,0,0,0-2.011-18.5c-12.732-23.545-16-43.7-8.2-56.86A25.954,25.954,0,0,1,97,46.055l.2.012.2-.012a25.807,25.807,0,0,1,23.2,11.848c7.81,13.026,4.612,33.282-8.038,57.208a20.611,20.611,0,0,0-1.853,18.492l.081.192c2.15,4.715,3.922,10.4,36.367,18.039l.205.048.209.023a16.231,16.231,0,0,1,8.09,3.265Zm9.449-11.379a33.112,33.112,0,0,0-14.78-6.425c-9.07-2.076-16.363-3.886-21.229-6.111-2.275-1.04-3.971-2.015-4.715-3.385a6.3,6.3,0,0,1,.962-5.254l.136-.19.109-.207c15.538-29.33,17.967-54.917,7.714-71.955l-.019-.033a40.867,40.867,0,0,0-36.109-19A40.962,40.962,0,0,0,60.888,50.5l-.019.031C50.605,67.817,53.11,93.33,68.826,122.31l.11.205.137.188a6.227,6.227,0,0,1,1.01,5.158c-.745,1.383-2.449,2.349-4.731,3.389C60.463,133.476,53.137,135.3,44,137.383a33.264,33.264,0,0,0-14.725,6.407,82.379,82.379,0,0,1-14.446-46.6c0-45.187,37.183-82.37,82.369-82.37s82.37,37.183,82.37,82.37a82.376,82.376,0,0,1-14.448,46.6Z" fill="currentColor" fill-rule="evenodd" /></svg>}
                                            </div>
                                        </div>
                                        <div className="Header-nav-profile-dropdown">
                                            {props.user ?
                                                <ul>
                                                    {props.user.influencer && props.user.influencer.length ?
                                                        <React.StrictMode>
                                                            {/*<li>
                                                                <a href="/dashboard/settings">
                                                                    <button className="btn-onclick">
                                                                        <svg className="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M458.4 64.3C400.6 15.7 311.3 23 256 79.3 200.7 23 111.4 15.6 53.6 64.3-21.6 127.6-10.6 230.8 43 285.5l175.4 178.7c10 10.2 23.4 15.9 37.6 15.9 14.3 0 27.6-5.6 37.6-15.8L469 285.6c53.5-54.7 64.7-157.9-10.6-221.3zm-23.6 187.5L259.4 430.5c-2.4 2.4-4.4 2.4-6.8 0L77.2 251.8c-36.5-37.2-43.9-107.6 7.3-150.7 38.9-32.7 98.9-27.8 136.5 10.5l35 35.7 35-35.7c37.8-38.5 97.8-43.2 136.5-10.6 51.1 43.1 43.5 113.9 7.3 150.8z"></path></svg>
                                                                        Wunschliste
                                                                    </button>
                                                                </a>
                                                            </li>*/}
                                                            <li>
                                                                <a href="/dashboard/reviews">
                                                                    <button className="btn-onclick">
                                                                        Fahrzeug-Bewertungen
                                                                    </button>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="/dashboard/testimonials">
                                                                    <button className="btn-onclick">Erfahrungsberichte</button>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="/dashboard/settings">
                                                                    <button className="btn-onclick">Einstellungen</button>
                                                                </a>
                                                            </li>
                                                        </React.StrictMode>
                                                        : <React.StrictMode>
                                                            {/*<li>
                                                                <a href="/dashboard/settings">
                                                                    <button className="btn-onclick">
                                                                        <svg className="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M458.4 64.3C400.6 15.7 311.3 23 256 79.3 200.7 23 111.4 15.6 53.6 64.3-21.6 127.6-10.6 230.8 43 285.5l175.4 178.7c10 10.2 23.4 15.9 37.6 15.9 14.3 0 27.6-5.6 37.6-15.8L469 285.6c53.5-54.7 64.7-157.9-10.6-221.3zm-23.6 187.5L259.4 430.5c-2.4 2.4-4.4 2.4-6.8 0L77.2 251.8c-36.5-37.2-43.9-107.6 7.3-150.7 38.9-32.7 98.9-27.8 136.5 10.5l35 35.7 35-35.7c37.8-38.5 97.8-43.2 136.5-10.6 51.1 43.1 43.5 113.9 7.3 150.8z"></path></svg>
                                                                        Wunschliste
                                                                    </button>
                                                                </a>
                                                            </li>*/}
                                                            <li>
                                                                <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "account/erfahrungsberichte"}>
                                                                    <button className="btn-onclick">Erfahrungsbericht erstellen</button>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "account/einstellungen"}>
                                                                    <button className="btn-onclick">Einstellungen</button>
                                                                </a>
                                                            </li>
                                                        </React.StrictMode>}
                                                </ul>
                                                : <ul>
                                                    <li>
                                                        <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "registrieren" + (props.urlParams && props.urlParams.has('token') ? "?token=" + props.urlParams.get('token') : '') + (props.urlParams && props.urlParams.has('email') ? "&email=" + props.urlParams.get('email') : '') + (props.urlParams && props.urlParams.has('ziel') ? "?ziel=" + props.urlParams.get('ziel') : '')}>
                                                            <button className="btn-onclick">Account erstellen</button>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "anmelden" + (props.urlParams && props.urlParams.has('token') ? "?token=" + props.urlParams.get('token') : '') + (props.urlParams && props.urlParams.has('email') ? "&email=" + props.urlParams.get('email') : '') + (props.urlParams && props.urlParams.has('ziel') ? "?ziel=" + props.urlParams.get('ziel') : '')}>
                                                            <button className="btn-onclick">Anmelden</button>
                                                        </a>
                                                    </li>
                                                </ul>}
                                        </div>
                                    </div>
                                </li>
                            </Nav>
                        </div>
                    </Wrapper>
                </div>
            </header>

            <Mobile
                localhost={props.localhost} urlParams={props.urlParams}
                allManufacturers={props.allManufacturers} allVehicles={props.allVehicles} allVehiclesCount={props.allVehiclesCount} vehicle={props.vehicle} allReviewsCount={props.allReviewsCount} allTestimonialsCount={props.allTestimonialsCount} review={props.review} testimonial={props.testimonial} allBodyStyles={props.allBodyStyles}
                user={props.user} loc={props.loc} country={props.country} countries={props.countries} inf={props.inf} influencer={props.influencer} />
        </React.StrictMode>
    );
}

export default Header;