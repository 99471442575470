import { axiosInstanceJWT, axiosLocalErrorHandling } from '../axios';

import { // Functions.
    countryUrl,
} from '../../functions';


export const putTestimonial = (props: any) => {
    const formData: any = new FormData();  // let formData: any = {}
    var formDataLength: number = 0;
    var errorCount: number = 0;

    // Meta data.
    let testimonialMetaDataData: any = {}
    const testimonialMetaDataIdInput: any = document.getElementById('testimonialMetaDataId');
    const testimonialMetaDataId = (testimonialMetaDataIdInput && testimonialMetaDataIdInput.value ? testimonialMetaDataIdInput.value : '');
    if (testimonialMetaDataId) {
        testimonialMetaDataData.id = testimonialMetaDataId;
    } else {
        testimonialMetaDataData.id = 0;
    }
    const testimonialStatusInput: any = document.getElementById('testimonialMetaDataStatus');
    const testimonialStatusId = (testimonialStatusInput && testimonialStatusInput.checked ? testimonialStatusInput.checked : '');
    if (testimonialStatusId) {
        testimonialMetaDataData.status = 2;
    } else {
        testimonialMetaDataData.status = 1;
    }
    console.log(testimonialMetaDataData)
    if (Object.keys(testimonialMetaDataData).length > 0) {
        formData.append('meta_data_json', JSON.stringify(testimonialMetaDataData));
        formDataLength = formDataLength + 1;
    }


    // Vehicle.
    const testimonialVehicle: any = document.querySelector('input[name="testimonialVehicle"]:checked');
    if (testimonialVehicle) {
        const testimonialVehicleId = testimonialVehicle.value ? testimonialVehicle.value : undefined;
        if (testimonialVehicleId) {
            formData.append('car_json', testimonialVehicleId);
            formDataLength = formDataLength + 1;
        }
    }

    // Plattform.
    const testimonialPlattform: any = document.querySelector('input[name="testimonialPlattform"]:checked');
    if (testimonialPlattform) {
        const testimonialPlattformId = testimonialPlattform.value ? testimonialPlattform.value : undefined;
        if (testimonialPlattformId) {
            formData.append('plattform_json', testimonialPlattformId);
            formDataLength = formDataLength + 1;
        }
    }

    // Review legal document.
    const testimonialLegalDocumentInput: any = document.getElementById('testimonialLegalDocument');
    const testimonialLegalDocument = (testimonialLegalDocumentInput && testimonialLegalDocumentInput.files[0] ? testimonialLegalDocumentInput.files[0] : undefined);
    if (testimonialLegalDocument) {
        formData.append('legal_document', testimonialLegalDocument);
        formDataLength = formDataLength + 1;
    }

    // Distance travelled.
    const testimonialKilometersInput: any = document.getElementById('testimonialKilometers');
    const testimonialKilometers = (testimonialKilometersInput && testimonialKilometersInput.value ? testimonialKilometersInput.value : '');
    if (testimonialKilometersInput) {
        formData.append('kilometers', testimonialKilometers);
        formDataLength = formDataLength + 1;
    }

    // Ownership period.
    const testimonialOwnershipPeriodInput: any = document.getElementById('testimonialOwnershipPeriod');
    const testimonialOwnershipPeriod = (testimonialOwnershipPeriodInput && testimonialOwnershipPeriodInput.value ? testimonialOwnershipPeriodInput.value : '');
    if (testimonialOwnershipPeriodInput) {
        formData.append('ownership_period', testimonialOwnershipPeriod);
        formDataLength = formDataLength + 1;
    }

    // Greeting.
    const testimonialGreetingInput: any = document.getElementById('testimonialGreeting');
    const testimonialGreeting = (testimonialGreetingInput && testimonialGreetingInput.value ? testimonialGreetingInput.value : '');
    if (testimonialGreetingInput) {
        formData.append('greeting', testimonialGreeting);
        formDataLength = formDataLength + 1;
    }

    // E-mobility experiance.
    const testimonialEmobilityExperianceInput: any = document.getElementById('testimonialEmobilityExperiance');
    const testimonialEmobilityExperiance = (testimonialEmobilityExperianceInput && testimonialEmobilityExperianceInput.value ? testimonialEmobilityExperianceInput.value : '');
    if (testimonialEmobilityExperianceInput) {
        formData.append('emobility_experiance', testimonialEmobilityExperiance);
        formDataLength = formDataLength + 1;
    }

    // Plattform justification.
    const testimonialPlattformJustificationInput: any = document.getElementById('testimonialPlattformJustification');
    const testimonialPlattformJustification = (testimonialPlattformJustificationInput && testimonialPlattformJustificationInput.value ? testimonialPlattformJustificationInput.value : '');
    if (testimonialPlattformJustificationInput) {
        formData.append('plattform_justification', testimonialPlattformJustification);
        formDataLength = formDataLength + 1;
    }

    // Review pros.
    let testimonialProsData: any = []
    const testimonialPros: any = document.getElementsByName("testimonialPro");
    if (testimonialPros.length) {
        for (let i = 0; i < testimonialPros.length; i++) {
            const testimonialProId = testimonialPros[i] && testimonialPros[i].getAttribute("data-id") ? testimonialPros[i].getAttribute("data-id") : undefined;
            const testimonialProText = testimonialPros[i] && testimonialPros[i].value ? testimonialPros[i].value : undefined;
            const testimonialProOrder = testimonialPros[i] && testimonialPros[i].getAttribute("data-order") ? testimonialPros[i].getAttribute("data-order") : undefined;
            if (testimonialProText) {
                testimonialProsData.push({ id: testimonialProId, text: testimonialProText, order: testimonialProOrder });
            }
        }
    }
    if (testimonialProsData.length > 0) {
        formData.append('pros_json', JSON.stringify(testimonialProsData));
        formDataLength = formDataLength + 1;
    }

    // Review cons.
    let testimonialConsData: any = []
    const testimonialCons: any = document.getElementsByName("testimonialCon");
    if (testimonialCons.length) {
        for (let i = 0; i < testimonialCons.length; i++) {
            const testimonialConId = testimonialCons[i] && testimonialCons[i].getAttribute("data-id") ? testimonialCons[i].getAttribute("data-id") : undefined;
            const testimonialConText = testimonialCons[i] && testimonialCons[i].value ? testimonialCons[i].value : undefined;
            const testimonialConOrder = testimonialCons[i] && testimonialCons[i].getAttribute("data-order") ? testimonialCons[i].getAttribute("data-order") : undefined;
            if (testimonialConText) {
                testimonialConsData.push({ id: testimonialConId, text: testimonialConText, order: testimonialConOrder });
            }
        }
    }
    if (testimonialConsData.length > 0) {
        formData.append('cons_json', JSON.stringify(testimonialConsData));
        formDataLength = formDataLength + 1;
    }

    // Ratings.
    let testimonialRatingsData: any = {}
    const testimonialRatingsIdInput: any = document.getElementById('testimonialRatingsId');
    if (testimonialRatingsIdInput) {
        const testimonialRatingsId = (testimonialRatingsIdInput && testimonialRatingsIdInput.value ? testimonialRatingsIdInput.value : '');
        testimonialRatingsData.id = testimonialRatingsId;
    }

    // Rating Driving.
    const testimonialRatingDrivingInput: any = document.querySelector('input[name="testimonialRatingDriveing"]:checked');
    const testimonialRatingDriving = (testimonialRatingDrivingInput && testimonialRatingDrivingInput.value ? testimonialRatingDrivingInput.value : '');
    if (testimonialRatingDriving) {
        testimonialRatingsData.driving = testimonialRatingDriving;
    }
    // Rating Comfort.
    const testimonialRatingComfortInput: any = document.querySelector('input[name="testimonialRatingComfort"]:checked');
    const testimonialRatingComfort = (testimonialRatingComfortInput && testimonialRatingComfortInput.value ? testimonialRatingComfortInput.value : '');
    if (testimonialRatingComfort) {
        testimonialRatingsData.comfort = testimonialRatingComfort;
    }
    // Rating Quality.
    const testimonialRatingQualityInput: any = document.querySelector('input[name="testimonialRatingQuality"]:checked');
    const testimonialRatingQuality = (testimonialRatingQualityInput && testimonialRatingQualityInput.value ? testimonialRatingQualityInput.value : '');
    if (testimonialRatingQuality) {
        testimonialRatingsData.quality = testimonialRatingQuality;
    }
    // Rating Price.
    const testimonialRatingPriceInput: any = document.querySelector('input[name="testimonialRatingPrice"]:checked');
    const testimonialRatingPrice = (testimonialRatingPriceInput && testimonialRatingPriceInput.value ? testimonialRatingPriceInput.value : '');
    if (testimonialRatingPrice) {
        testimonialRatingsData.price = testimonialRatingPrice;
    }
    // Rating Overall.
    const testimonialRatingOverallInput: any = document.querySelector('input[name="testimonialRatingOverall"]:checked');
    const testimonialRatingOverall = (testimonialRatingOverallInput && testimonialRatingOverallInput.value ? testimonialRatingOverallInput.value : '');
    if (testimonialRatingOverall) {
        testimonialRatingsData.overall = testimonialRatingOverall;
    }
    if (Object.keys(testimonialRatingsData).length > 0) {
        formData.append('ratings_json', JSON.stringify(testimonialRatingsData));
        formDataLength = formDataLength + 1;
    }

    // Home charging.
    const testimonialHomeChargingExperianceInput: any = document.getElementById('testimonialHomeChargingExperiance');
    const testimonialHomeChargingExperiance = (testimonialHomeChargingExperianceInput && testimonialHomeChargingExperianceInput.value ? testimonialHomeChargingExperianceInput.value : '');
    if (testimonialHomeChargingExperianceInput) {
        formData.append('home_charging_experiance', testimonialHomeChargingExperiance);
        formDataLength = formDataLength + 1;
    }

    // Charging interval times.
    const testimonialHomeChargingIntervalTimesInput: any = document.getElementById('testimonialHomeChargingIntervalTimes');
    const testimonialHomeChargingIntervalTimes = (testimonialHomeChargingIntervalTimesInput && testimonialHomeChargingIntervalTimesInput.value ? testimonialHomeChargingIntervalTimesInput.value : '');
    if (testimonialHomeChargingIntervalTimesInput) {
        formData.append('home_charging_interval_times', testimonialHomeChargingIntervalTimes);
        formDataLength = formDataLength + 1;
    }

    // Charging interval.
    const testimonialHomeChargingIntervalInput: any = document.querySelector('input[name="testimonialHomeChargingInterval"]:checked');
    const testimonialHomeChargingInterval = (testimonialHomeChargingIntervalInput && testimonialHomeChargingIntervalInput.value ? testimonialHomeChargingIntervalInput.value : '');
    if (testimonialHomeChargingIntervalInput) {
        formData.append('home_charging_interval', testimonialHomeChargingInterval);
        formDataLength = formDataLength + 1;
    }

    // City experiance.
    const testimonialCityExperianceInput: any = document.getElementById('testimonialCityExperiance');
    const testimonialCityExperiance = (testimonialCityExperianceInput && testimonialCityExperianceInput.value ? testimonialCityExperianceInput.value : '');
    if (testimonialCityExperianceInput) {
        formData.append('city_experiance', testimonialCityExperiance);
        formDataLength = formDataLength + 1;
    }

    // Long-distance experiance.
    const testimonialLongDistanceExperianceInput: any = document.getElementById('testimonialLongDistanceExperiance');
    const testimonialLongDistanceExperiance = (testimonialLongDistanceExperianceInput && testimonialLongDistanceExperianceInput.value ? testimonialLongDistanceExperianceInput.value : '');
    if (testimonialLongDistanceExperianceInput) {
        formData.append('long_distance_experiance', testimonialLongDistanceExperiance);
        formDataLength = formDataLength + 1;
    }

    // Long-term consumtion.
    const testimonialLongTermConsumtionInput: any = document.getElementById('testimonialLongTermConsumtion');
    const testimonialLongTermConsumtion = (testimonialLongTermConsumtionInput && testimonialLongTermConsumtionInput.value ? testimonialLongTermConsumtionInput.value : '');
    if (testimonialLongTermConsumtionInput) {
        formData.append('long_term_consumtion', testimonialLongTermConsumtion);
        formDataLength = formDataLength + 1;
    }

    // Infotainment experiance.
    const testimonialInfotainmentExperianceInput: any = document.getElementById('testimonialInfotainmentExperiance');
    const testimonialInfotainmentExperiance = (testimonialInfotainmentExperianceInput && testimonialInfotainmentExperianceInput.value ? testimonialInfotainmentExperianceInput.value : '');
    if (testimonialInfotainmentExperianceInput) {
        formData.append('infotainment_experiance', testimonialInfotainmentExperiance);
        formDataLength = formDataLength + 1;
    }

    // Service experiance.
    const testimonialServiceExperianceInput: any = document.getElementById('testimonialServiceExperiance');
    const testimonialServiceExperiance = (testimonialServiceExperianceInput && testimonialServiceExperianceInput.value ? testimonialServiceExperianceInput.value : '');
    if (testimonialServiceExperianceInput) {
        formData.append('service_experiance', testimonialServiceExperiance);
        formDataLength = formDataLength + 1;
    }

    // Summary.
    const testimonialSummaryInput: any = document.getElementById('testimonialSummary');
    const testimonialSummary = (testimonialSummaryInput && testimonialSummaryInput.value ? testimonialSummaryInput.value : '');
    if (testimonialSummaryInput) {
        formData.append('summary', testimonialSummary);
        formDataLength = formDataLength + 1;
    }


    if (errorCount === 0 && formDataLength > 0) {
        // Update existing testimonial.
        if (props.testimonial && props.testimonial.id && props.testimonial.id !== 'undefined') {
            axiosInstanceJWT.put('backend/testimonials/' + props.testimonial.id + '/', formData)
                .then(function (response: any) {
                    props.setNotification("Änderungen gespeichert!");
                    if (response && response.data && props.setTestimonial) {
                        props.setTestimonial(response.data);
                    }
                })
                .catch(function (error: any) {
                    props.setNotification("Ups, bitte versuche es nocheinmal!");
                    axiosLocalErrorHandling({ error: error, function: "putTestimonial 1" });
                })

            // Create new testimonial.
        } else {
            axiosInstanceJWT.post('backend/testimonials/create/', formData)
                .then(function (response: any) {
                    props.setNotification("Erfahrungsbericht erstellt!");
                    if (response && response.data) {
                        if (response.data.id) {
                            if (window.location.pathname.includes('dashboard')) {
                                window.location.pathname = '/dashboard/testimonials/' + response.data.id;
                            } else {
                                window.location.pathname = countryUrl({ loc: props.loc, inf: props.inf }) + "account/erfahrungsberichte/" + response.data.id;
                            }
                        }
                    }
                })
                .catch(function (error: any) {
                    props.setNotification("Ups, bitte versuche es nocheinmal!");
                    axiosLocalErrorHandling({ error: error, function: "putTestimonial 2" });
                })
        }
    }
}