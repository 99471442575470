import { axiosInstanceJWT, axiosLocalErrorHandling } from '../axios';

import { // Functions.
    countryUrl,
} from '../../functions';


export const putUser = (props: any) => {
    const formData = new FormData();
    var formDataLength = 0;

    const userEmailInput: any = document.getElementById('userEmail');
    const userEmailNotify: any = document.getElementById('userEmailNotify');
    const dataEmail = (userEmailInput && userEmailInput.value ? userEmailInput.value : '');
    if (dataEmail && dataEmail.length > 0 && new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i).test(dataEmail)) {
        formData.append('email', dataEmail);
        formDataLength = formDataLength + 1;
        userEmailNotify.classList.remove("btn-info-second");
        userEmailNotify.classList.add("btn-info-placeholder");
        userEmailNotify.innerHTML = ""
    } else if (props.userEmail && dataEmail.length > 0 && !new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i).test(dataEmail)) {
        userEmailNotify.classList.remove("btn-info-placeholder");
        userEmailNotify.classList.add("btn-info-second");
        userEmailNotify.innerHTML = "Geben Sie eine korrekte E-Mail Adresse ein!"
    } else if (props.userEmail && dataEmail.length === 0) {
        userEmailNotify.classList.remove("btn-info-placeholder");
        userEmailNotify.classList.add("btn-info-second");
        userEmailNotify.innerHTML = "Ihre E-Mail Adresse kann nicht leer gespeichert werden!"
    }

    let userDataData = new FormData();
    var userDataDataLength = 0;
    // const userDataIdInput: any = document.getElementById('userDataId');
    // const userDataId = (userDataIdInput && userDataIdInput.value ? userDataIdInput.value : '');
    // if (userDataId) {
    //     userDataData.id = userDataId;
    // }

    const userFirstnameInput: any = document.getElementById('userFirstname');
    const userFirstnameNotify: any = document.getElementById('userFirstnameNotify');
    const dataFirstname = (userFirstnameInput && userFirstnameInput.value ? userFirstnameInput.value : '');
    if (dataFirstname && dataFirstname.length > 0) {
        // userDataData.firstname = dataFirstname;
        userDataData.append('firstname', dataFirstname);
        userDataDataLength = userDataDataLength + 1;
        userFirstnameNotify.classList.add("btn-info-placeholder");
        userFirstnameNotify.classList.remove("btn-info-second");
        userFirstnameNotify.innerHTML = "";
    } else if (props.userFirstname && dataFirstname.length === 0) {
        userFirstnameNotify.classList.remove("btn-info-placeholder");
        userFirstnameNotify.classList.add("btn-info-second");
        userFirstnameNotify.innerHTML = "Ihr Vorname kann nicht leer gespeichert werden!"
    }

    const userLastnameInput: any = document.getElementById('userLastname');
    const userLastnameNotify: any = document.getElementById('userLastnameNotify');
    const dataLastname = (userLastnameInput && userLastnameInput.value ? userLastnameInput.value : '');
    if (dataLastname && dataLastname.length > 0) {
        // userDataData.lastname = dataLastname;
        userDataData.append('lastname', dataLastname);
        userDataDataLength = userDataDataLength + 1;
        userLastnameNotify.classList.add("btn-info-placeholder");
        userLastnameNotify.classList.remove("btn-info-second");
        userLastnameNotify.innerHTML = "";
    } else if (props.userLastname && dataLastname.length === 0) {
        userLastnameNotify.classList.remove("btn-info-placeholder");
        userLastnameNotify.classList.add("btn-info-second");
        userLastnameNotify.innerHTML = "Ihr Nachname kann nicht leer gespeichert werden!"
    }

    const userProfilePictureInput: any = document.getElementById('userProfilePicture');
    const dataProfilePicture = (userProfilePictureInput && userProfilePictureInput.files[0] ? userProfilePictureInput.files[0] : undefined);
    if (dataProfilePicture) {
        userDataData.append('profile_picture', dataProfilePicture);
        userDataDataLength = userDataDataLength + 1;
    }

    // if (Object.keys(userDataData).length > 0) {
    //     formData.append('data_json', JSON.stringify(userDataData)); 
    //     formDataLength = formDataLength + 1;
    // }

    if (formDataLength > 0) {
        if (props.user) {
            axiosInstanceJWT.put('backend/user/update/' + props.user.id + '/', formData)
                .then(function (response: any) {
                    props.setNotification("Änderungen gespeichert!");
                    // if (response && response.data && props.setUser) {
                    //     props.setUser(response.data);
                    // }
                })
                .catch(function (error: any) {
                    if (error.response && error.response.data) {
                        // E-Mail already exists.
                        if (error.response.data.email && userEmailNotify) {
                            userEmailNotify.classList.remove("btn-info-placeholder");
                            userEmailNotify.classList.add("btn-info-second");
                            userEmailNotify.innerHTML = error.response.data.email;
                        }
                    } else {
                        props.setNotification("Ups, bitte versuche es nocheinmal!");
                        axiosLocalErrorHandling({ error: error, function: "putUser 1" });
                    }
                })
        }
    }


    if (userDataDataLength > 0) {
        if (props.user && props.user.data) {
            axiosInstanceJWT.put('backend/user/update/data/' + props.user.data.id + '/', userDataData)
                .then(function (response: any) {
                    props.setNotification("Änderungen gespeichert!");
                    if (response.data.profile_picture) { props.setUserProfilePicture(response.data.profile_picture) }
                    // if (response && response.data && props.setUser) {
                    //     props.setUser(response.data);
                    // }
                })
                .catch(function (error: any) {
                    props.setNotification("Ups, bitte versuche es nocheinmal!");
                    axiosLocalErrorHandling({ error: error, function: "putUser 2" });
                })
        }
    }
}


export const putUserPassword = (props: any) => {
    const userCurrentPasswordInput: any = document.getElementById('userCurrentPassword');
    const userCurrentPasswordNotify: any = document.getElementById('userCurrentPasswordNotify');
    const dataCurrentPassword = (userCurrentPasswordInput && userCurrentPasswordInput.value ? userCurrentPasswordInput.value : '');

    const userNewPasswordInput: any = document.getElementById('userNewPassword');
    const userNewPasswordNotify: any = document.getElementById('userNewPasswordNotify');
    const dataNewPassword = (userNewPasswordInput && userNewPasswordInput.value ? userNewPasswordInput.value : '');

    const userNewPasswordVerifyInput: any = document.getElementById('userNewPasswordVerify');
    const userNewPasswordVerifyNotify: any = document.getElementById('userNewPasswordVerifyNotify');
    const dataNewPasswordVerify = (userNewPasswordVerifyInput && userNewPasswordVerifyInput.value ? userNewPasswordVerifyInput.value : '');

    if (dataCurrentPassword.length > 7 && dataNewPassword.length > 7 && dataNewPassword === dataNewPasswordVerify) {
        userCurrentPasswordNotify.classList.add("btn-info-placeholder");
        userCurrentPasswordNotify.classList.remove("btn-info-second");
        userCurrentPasswordNotify.innerHTML = "";

        userNewPasswordNotify.classList.add("btn-info-placeholder");
        userNewPasswordNotify.classList.remove("btn-info-second");
        userNewPasswordNotify.innerHTML = "";

        userNewPasswordVerifyNotify.classList.add("btn-info-placeholder");
        userNewPasswordVerifyNotify.classList.remove("btn-info-second");
        userNewPasswordVerifyNotify.innerHTML = "";

        axiosInstanceJWT.put('backend/user/update/password/' + props.user.id + '/', {
            'password': dataNewPassword,
            'password2': dataNewPasswordVerify,
            'old_password': dataCurrentPassword
        }, {
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            }
        })
            .then(function (response: any) {
                props.setNotification("Passwort erfolgreich geändert!");
                if (window.location.pathname.includes('dashboard')) {
                    window.location.pathname = "/dashboard/settings/user";
                } else {
                    window.location.pathname = countryUrl({ loc: props.loc, inf: props.inf }) + "account/einstellungen";
                }
            })
            .catch(function (error: any) {
                if (error.response && error.response.data) {
                    // Password wrong.
                    if (error.response.data.old_password && userCurrentPasswordNotify) {
                        userCurrentPasswordNotify.classList.remove("btn-info-placeholder");
                        userCurrentPasswordNotify.classList.add("btn-info-second");
                        userCurrentPasswordNotify.innerHTML = "Bitte geben Sie Ihr korrektes Passwort ein!"
                    }
                } else {
                    props.setNotification("Ups, bitte versuche es nocheinmal!");
                    axiosLocalErrorHandling({ error: error, function: "putUserPassword" });
                }
            })


    } else {
        if (dataCurrentPassword.length < 8) {
            userCurrentPasswordNotify.classList.remove("btn-info-placeholder");
            userCurrentPasswordNotify.classList.add("btn-info-second");
            userCurrentPasswordNotify.innerHTML = "Ihr Passwort ist nicht korrekt."
        } else {
            userCurrentPasswordNotify.classList.add("btn-info-placeholder");
            userCurrentPasswordNotify.classList.remove("btn-info-second");
            userCurrentPasswordNotify.innerHTML = "";
        }

        if (dataNewPassword.length < 8) {
            userNewPasswordNotify.classList.remove("btn-info-placeholder");
            userNewPasswordNotify.classList.add("btn-info-second");
            userNewPasswordNotify.innerHTML = "Ihr neues Passwort muss mindestens 8 Zeichen haben!"
        } else {
            userNewPasswordNotify.classList.add("btn-info-placeholder");
            userNewPasswordNotify.classList.remove("btn-info-second");
            userNewPasswordNotify.innerHTML = "";
        }

        if (!dataNewPasswordVerify) {
            userNewPasswordVerifyNotify.classList.remove("btn-info-placeholder");
            userNewPasswordVerifyNotify.classList.add("btn-info-second");
            userNewPasswordVerifyNotify.innerHTML = "Bitte bestätigen Sie Ihr neues Passwort!"
        } else {
            userNewPasswordVerifyNotify.classList.add("btn-info-placeholder");
            userNewPasswordVerifyNotify.classList.remove("btn-info-second");
            userNewPasswordVerifyNotify.innerHTML = "";
        }

        if (dataNewPassword && dataNewPasswordVerify && dataNewPassword !== dataNewPasswordVerify) {
            userNewPasswordVerifyNotify.classList.remove("btn-info-placeholder");
            userNewPasswordVerifyNotify.classList.add("btn-info-second");
            userNewPasswordVerifyNotify.innerHTML = "Die beiden Passwörter stimmen nicht überein!"
        } else if (dataNewPassword && dataNewPasswordVerify && dataNewPassword === dataNewPasswordVerify) {
            userNewPasswordVerifyNotify.classList.add("btn-info-placeholder");
            userNewPasswordVerifyNotify.classList.remove("btn-info-second");
            userNewPasswordVerifyNotify.innerHTML = "";
        }
    }
}