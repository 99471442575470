import { useState, useEffect } from 'react';

import { // Functions.
    scrollToId
} from '../../../functions/functions';


function Notify(props: any) {
    const notification = props.notification;
    const setNotification = props.setNotification;
    const [notificationTimer, setNotificationTimer] = useState<any>(undefined);

    function setNotify() {
        if (notification && notification.length > 0) {
            setNotificationTimer(setTimeout(function () {
                setNotification('');
            }, 10000));
        }
    }

    function clearNotify() {
        setNotificationTimer(clearTimeout(notificationTimer));
    }

    useEffect(() => {
        clearNotify();
        setNotify();
    }, [notification]);


    return (
        <div className={"Notify" + (notification && notification.length > 0 ? "" : " Notify-hidden")}>
            <div>
                <a><button onClick={() => setNotification('')}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.255 141.255"><path d="M132.869,142.033,73.312,82.477,13.746,142.043a6.48,6.48,0,1,1-9.164-9.164L64.148,73.313,4.582,13.747a6.48,6.48,0,0,1,9.165-9.165L73.313,64.148,132.889,4.571a6.48,6.48,0,0,1,9.143,9.185L82.477,73.312l59.578,59.578a6.48,6.48,0,0,1-9.185,9.142Z" transform="translate(-2.685 -2.686)" fill="currentColor" /></svg>
                </button></a>
                <a onClick={() => scrollToId('footer')}><p>{notification}</p></a>
            </div>
        </div>
    );
}

export default Notify;