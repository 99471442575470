import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import React, { useState, useEffect } from 'react';

import { // Components.
    TestimonialBanner,
    Wrapper
} from '../components/components';

import { // Functions.
    authUserLogout,
    countryUrl
} from '../functions/functions';


function SettingsFrontend(props: any) {
    return (
        <React.StrictMode>
            <Helmet>
                <title>
                    Einstellungen | electricmagazin.com
                </title>
                <meta
                    name="description"
                    content={"Übersicht aller verfügbaren Einstellungen."} />
            </Helmet>

            <Wrapper>
                <div className="mb-5 hide-lg" id="bewertung"></div>
                <div className="hide display-lg" id="bewertung"><div className="mb-3"></div></div>


                <div className="flex flex-jc">
                    <div className="center">
                        {props.user && props.user.data && props.user.data.profile_picture ?
                            <div className="flex flex-jc mb-0-5">
                                <div className="circle h-3em w-3em bg-base">
                                    <LazyLoadImage
                                        effect="opacity"
                                        className="w-100 h-100"
                                        src={props.user.data.profile_picture}
                                        alt={(props.user && props.user.data && props.user.data.firstname ? props.user.data.firstname : '') + ' ' +
                                            (props.user && props.user.data && props.user.data.lastname ? props.user.data.lastname : '') + ' Profilbild'} />
                                </div>
                            </div>
                            : <div className="flex flex-jc mb-0-5">
                                <div className="circle h-3em w-3em bg-dark"></div>
                            </div>}
                        {props.user && props.user.data && props.user.data.firstname && props.user.data.lastname ?
                            <div className="mb-0-25">
                                <h4>{props.user.data.firstname} {props.user.data.lastname}</h4>
                            </div>
                            : <div className="mb-0-25">
                                <h4 className="c-transparent">...</h4>
                            </div>}
                        {props.user && props.user.email ?
                            <p>{props.user.email}</p>
                            : <p className="c-transparent">...</p>}
                    </div>
                </div>


                <div className="mb-5 hide-lg" id="bewertung"></div>
                <div className="hide display-lg" id="bewertung"><div className="mb-3"></div></div>


                <div className="flex flex-ai-s mb-1">
                    <div className="border border-txt-25 border-radius p-1 w-100">
                        <div className="mb-1">
                            <h4>Persönliche Daten</h4>
                        </div>

                        <p className="op-50 mb-1">
                            Überprüfen und aktualisieren Sie Ihre persönlichen Daten wie Name, Profilbild und E-Mail.
                        </p>

                        <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "account/einstellungen/persönliche-daten"}>
                            <button className="btn-sm btn-grey hide-sm">Persönliche Daten bearbeiten</button>
                            <button className="btn-sm btn-grey hide display-sm">Bearbeiten</button>
                        </a>
                    </div>

                    <div className="border border-txt-25 border-radius p-1 w-100 ml-1 hide-med">
                        <div className="mb-1">
                            <h4>Account Sicherheit</h4>
                        </div>

                        <p className="op-50 mb-1">Hier können Sie Ihr Passwort ändern.</p>

                        <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "account/einstellungen/passwort-ändern"}>
                            <button className="btn-sm btn-grey">Passwort ändern</button>
                        </a>
                    </div>
                </div>

                <div className="hide display-med">
                    <div className="border border-txt-25 border-radius p-1 mb-1">
                        <div className="mb-1">
                            <h4>Account Sicherheit</h4>
                        </div>

                        <p className="op-50 mb-1">Hier können Sie Ihr Passwort ändern.</p>

                        <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "account/einstellungen/passwort-ändern"}>
                            <button className="btn-sm btn-grey">Passwort ändern</button>
                        </a>
                    </div>
                </div>

                <div className="flex flex-ai-s">
                    <div className="border border-txt-25 border-radius p-1 w-100 mr-0-5">
                        <div className="a">
                            <button
                                className="btn-onclick flex"
                                onClick={() => {
                                    authUserLogout({ loc: props.loc, inf: props.inf, setNotification: props.setNotification });
                                }}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"></path></svg>
                                <span className="ml-0-5 strong">Abmelden</span>
                            </button>
                        </div>
                    </div>

                    <div className="w-100 ml-0-5 p-1 c-transparent">.</div>
                </div>


                <div className="mb-5 hide-lg" id="bewertung"></div>
                <div className="hide display-lg" id="bewertung"><div className="mb-3"></div></div>


                <div>
                    <TestimonialBanner localhost={props.localhost} loc={props.loc} inf={props.inf} user={props.user} />
                </div>


                <div className="mb-5 hide-lg" id="bewertung"></div>
                <div className="hide display-lg" id="bewertung"><div className="mb-3"></div></div>
            </Wrapper>
        </React.StrictMode>
    );
}

export default SettingsFrontend;