import { Helmet } from 'react-helmet';

import React, { useState, useEffect } from 'react';

import { // Components.
    Wrapper
} from '../components/components';

import { // Functions.
    countryUrl,
    postUser, authUser
} from '../functions/functions';


function Register(props: any) {
    /* // Already authenticated. PROBLEM WITH LOGOUT
    useEffect(() => {
        if (props.user) {
            if (!props.user.influencer && !props.user.business) {
                window.location.pathname = "/account";
            } else {
                window.location.pathname = "/dashboard/settings";
            }
        }
    }, [props.user]); */

    const [email, setEmail] = useState<string>(props.urlParams && props.urlParams.has('email') ? props.urlParams.get('email') : '');
    const [password, setPassword] = useState<string>('');
    const [password2, setPassword2] = useState<string>('');
    const [honey, setHoney] = useState<string>('');

    // Enter nav and first checks.
    const emailInput = document.getElementById('email');
    const passwordInput = document.getElementById('password');
    const password2Input = document.getElementById('password2');
    
    if (emailInput) {
        emailInput.onkeydown = function (e) {
            if (e.keyCode === 13 && passwordInput) {
                if (email.length > 0 && new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i).test(email)) {
                    emailInput.classList.remove("error");
                    passwordInput.focus();

                } else {
                    emailInput.classList.add("error");
                    props.setNotification("Geben Sie Ihre korrekte E-Mail Adresse ein!");
                }
            }
        };
    }

    if (passwordInput && password2Input) {
        passwordInput.onkeydown = function (e) {
            if (e.keyCode === 13) {
                if (password.length > 7) {
                    passwordInput.classList.remove("error");
                    password2Input.focus();

                } else {
                    passwordInput.classList.add("error");
                    props.setNotification("Ihr Passwort muss mindestens 8 Zeichen haben!");
                }
            }
        };

        password2Input.onkeydown = function (e) {
            if (e.keyCode === 13) {
                if (password === password2) {
                    passwordInput.classList.remove("error");
                    password2Input.classList.remove("error");
                    preliminaryDataCheck();

                } else {
                    passwordInput.classList.add("error");
                    password2Input.classList.add("error");
                    props.setNotification("Die beiden Passwörter stimmen nicht überein!");
                }
            }
        };
    }

    // First checks
    function preliminaryDataCheck() {
        if (emailInput && passwordInput && password2Input) {
            if (password.length > 7 && password === password2 && email.length > 0 && new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i).test(email) && honey.length === 0) {
                password2Input.classList.remove("error");
                passwordInput.classList.remove("error");
                emailInput.classList.remove("error");

                postUser({ 
                    email: email, 
                    password: password, 
                    password2: password2, 
                    token: props.urlParams.get('token'), 
                    target: props.urlParams.get('ziel'),
                    authUser: authUser,
                    loc: props.loc, 
                    inf: props.inf, 
                    setNotification: props.setNotification }); 

            } else {
                if (password.length === 0 || password !== password2) {
                    password2Input.classList.add("error");
                    props.setNotification("Die beiden Passwörter stimmen nicht überein!");
                }
                if (password.length < 8) {
                    passwordInput.classList.add("error");
                    props.setNotification("Ihr Passwort muss mindestens 8 Zeichen haben!");
                }
                if (!new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i).test(email)) {
                    emailInput.classList.add("error");
                    props.setNotification("Geben Sie Ihre korrekte E-Mail Adresse ein!");
                }
            }
        }
    }


    return (
        <React.StrictMode>
            <Helmet>
                <title>
                    {props.urlParams && props.urlParams.has('token') ? 
                        props.urlParams.get('token').startsWith('i') ? 
                            'Influencer Account erstellen'
                            : props.urlParams.get('token').startsWith('b') ? 
                                'Händler Account erstellen'
                                : 'Account erstellen'
                        : 'Account erstellen'} | electricmagazin.com
                </title>
                <meta
                    name="description"
                    content="Jetzt registrieren und alle Funktionen nutzen." />
            </Helmet>

            <div className="h-100vh-header flex">
                <Wrapper>
                    {props.urlParams && props.urlParams.has('token') ? 
                        props.urlParams.get('token').startsWith('i') ? 
                            <h1 className="center mb-0-25">Influencer Account erstellen</h1>
                            : props.urlParams.get('token').startsWith('b') ? 
                                <h1 className="center mb-0-25">Händler Account erstellen</h1>
                                : <h1 className="center mb-1">Account erstellen</h1>
                        : <h1 className="center mb-1">Account erstellen</h1>}

                    {props.urlParams && props.urlParams.has('token') ? 
                        props.urlParams.get('token').startsWith('i') ? 
                            <p className="center mb-2 op-75">
                                Erstellen Sie im ersten Schritt Ihren persönlichen ElectricMagazin Account! Geben Sie dafür Ihre persönliche E-Mail Addresse an und erstellen Sie ein Passwort. Falls Sie bereits einen Account bei ElectricMagazin haben <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "anmelden" + (props.urlParams && props.urlParams.has('token') ? "?token=" + props.urlParams.get('token') : '') + (props.urlParams && props.urlParams.has('email') ? "&email=" + props.urlParams.get('email') : '') + (props.urlParams && props.urlParams.has('ziel') ? "?ziel=" + props.urlParams.get('ziel') : '')}><u>melden Sie sich mit Ihrem Account</u></a> an. Im nächsten Schritt erstellen wir für Sie automatisch einen Influencer Account. Anschließend werden Sie zu Ihrem Influencer-Dashboard weitergeleitet wo Sie Ihren, Influencer Account bearbeiten sowie Reviews und Erfahrungsberichte erstellen können.
                            </p>
                            : props.urlParams.get('token').startsWith('b') ? 
                                <p  className="center mb-2 op-75">
                                    Erstellen Sie im ersten Schritt Ihren persönlichen ElectricMagazin Account! Geben Sie dafür Ihre persönliche E-Mail Addresse an und erstellen Sie ein Passwort. Falls Sie bereits einen Account bei ElectricMagazin haben melden Sie sich mit Ihrem Account <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "anmelden" + (props.urlParams && props.urlParams.has('token') ? "?token=" + props.urlParams.get('token') : '') + (props.urlParams && props.urlParams.has('email') ? "&email=" + props.urlParams.get('email') : '') + (props.urlParams && props.urlParams.has('ziel') ? "?ziel=" + props.urlParams.get('ziel') : '')}><u>melden Sie sich mit Ihrem Account</u></a> an. Im nächsten Schritt erstellen wir für Sie automatisch einen Händler Account. Anschließend werden Sie zu Ihrem Händler-Dashboard weitergeleitet wo Sie Ihren, Händler Account bearbeiten sowie Ihr Angebot erstellen können.
                                </p>
                                : null
                        : null}

                    <div className="flex flex-jc mb-5">
                        <div>
                            <div className="flex flex-jc">
                                <input 
                                    id="email" name="email" type="email" 
                                    placeholder="E-Mail Adresse" value={email} 
                                    onChange={(e: any) => setEmail(e.target.value)} />
                            </div>

                            <br />
                            <div className="flex flex-jc">
                                <input 
                                    id="password" name="password" type="password" 
                                    placeholder="Passwort" value={password} 
                                    onChange={(e: any) => setPassword(e.target.value)} />
                            </div>

                            <br />
                            <div className="flex flex-jc">
                                <input 
                                    id="password2" name="password2" type="password" 
                                    placeholder="Passwort wiederholen" value={password2} 
                                    onChange={(e) => setPassword2(e.target.value)} />
                            </div>

                            <br />
                            <div className="flex flex-jc mb-0-5">
                                <input 
                                    name="honey" type="hidden" 
                                    onChange={(e) => setHoney(e.target.value)} />
                                <button 
                                    className="btn-sm"
                                    onClick={() => preliminaryDataCheck()}>Account erstellen</button>
                            </div>

                            <div className="flex flex-jc">
                                <h6>
                                    <span className="op-50">Sie haben bereits einen Account? </span> 
                                    <a href={countryUrl({ loc: props.loc, inf: props.inf }) + "anmelden" + (props.urlParams && props.urlParams.has('token') ? "?token=" + props.urlParams.get('token') : '') + (props.urlParams && props.urlParams.has('email') ? "&email=" + props.urlParams.get('email') : '') + (props.urlParams && props.urlParams.has('ziel') ? "?ziel=" + props.urlParams.get('ziel') : '')}>
                                        <u className="op-50">Jetzt anmelden</u>
                                    </a>
                                </h6>
                            </div>
                        </div>
                    </div>

                </Wrapper>
            </div>
        </React.StrictMode >
    );
}

export default Register;