import { Helmet } from 'react-helmet';

import React from 'react';

import { // Components.
    Wrapper 
} from '../components/components';

import { // Functions.
    countryUrl 
} from '../functions/functions';


function Imprint(props: any) {
    return (
        <React.StrictMode>
            <Helmet>
                <title>
                    {"Impressum | electricmagazin.com" + (props.loc ? "/" + props.loc : "") + (props.inf ? "/" + props.inf : "")}
                </title>
                <meta
                    name="description"
                    content={"Diese Seite ist " + (props.influencer ? "eine Kooperation zwischen: " + props.influencer.name + " und " : "ein Angebot von: ") +  "Christoph Amort, Embach 101 6320 Angerberg Österreich. Telefon: +43 (0)660 5643282. E-Mail: hallo@electricmagazin.com."} />
            </Helmet>

            <Wrapper>
                <div className="mb-2 c-transparent">.</div>

                <h1 className="mb-1 center">Impressum</h1>

                <div className="flex flex-jc mb-5">
                    <div>
                        {props.influencer ? 
                            <h4 className="mb-1">Diese Seite ist eine Kooperation zwischen:</h4>
                            : <h4 className="mb-1">Electric Magazin ist ein Angebot von:</h4>}

                        <ul>
                            {props.influencer ? 
                                <React.StrictMode>
                                    {props.influencer.name ? 
                                        <li className="mb-0-5">{props.influencer.name} (Marketingpartner)</li>
                                        : null}
                                    {props.influencer.line_1 ? 
                                        <li className="mb-0-5">{props.influencer.line_1}</li>
                                        : null}
                                    {props.influencer.postal_code && props.influencer.city ? 
                                        <li className="mb-0-5">{props.influencer.postal_code + ' ' + props.influencer.city}</li>
                                        : null}
                                    {props.influencer.country ? 
                                        <li className="mb-0-5">{props.influencer.country.name}</li>
                                        : null}
                                    {props.influencer.phone ? 
                                        <li className="mb-0-5">Tel: {props.influencer.phone}</li>
                                        : null}
                                    {props.influencer.email ? 
                                        <li className="mb-0-5">E-Mail: <a className="c-primary" href={"mailto:" + props.influencer.email}><u>{props.influencer.email}</u></a></li>
                                        : null}

                                    <br />
                                    <li className="mb-0-5">und</li>
                                    <br />
                                </React.StrictMode>
                                : null}
                            
                            <li className="mb-0-5">Christoph Amort {props.influencer ? "(Serviceanbieter)" : ""}</li>
                            <li className="mb-0-5">Embach 101</li>
                            <li className="mb-0-5">6320 Angerberg</li>
                            <li className="mb-0-5">Österreich</li>
                            <li className="mb-0-5">Tel: +43 (0)660 5643282</li>
                            <li className="mb-0-5">E-Mail: <a className="c-primary" href="mailto:hallo@electricmagazin.com"><u>hallo@electricmagazin.com</u></a></li>

                            <br />
                            
                            <li className="mb-0-5">
                                <a className="c-primary" href={countryUrl({ loc: props.loc, inf: props.inf }) + "datenschutzrichtlinien"}>
                                    <u>Datenschutzrichtlinien</u>
                                </a>
                            </li>
                            <li className="mb-0-5">
                                <a className="c-primary" href={countryUrl({ loc: props.loc, inf: props.inf }) + "nutzungsbedingungen"}>
                                    <u>Nutzungsbedingungen</u>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </Wrapper>
        </React.StrictMode>
    );
}

export default Imprint;